import React from 'react';
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { IStore } from "@Redux/IStore";
import { StaticImage } from "@Styled";
import { USER_SESSION } from '@Interfaces';
import { SendAnalyticsToFirebase } from '@Services/FirebaseEvents';
import { AnalyticsEventsName } from '@Services/FirebaseEvents';
import { MessagesActions } from '@Actions/Messages';
import { isPaidContentAllowed } from '@HelperFunctions';
import { AccessResponseStatus } from '@Constants';

export const FooterContainer: React.FunctionComponent<{ selected: string, setShowLowBalanceModal: (a: boolean) => void, user: USER_SESSION, setShowNoNameModal: (a: boolean) => void, userHasNoName: boolean, setShowPaywall: (a: boolean) => void; }>
    = ({ selected, user, setShowLowBalanceModal, setShowNoNameModal, userHasNoName, setShowPaywall }) => {

        const messagesState = useSelector((state: IStore) => state.messages);
        const homeState = useSelector((state: IStore) => state.home);
        const { showPaywall, session } = useSelector((state: IStore) => state.persistState);
        // const { userHasLowBalance } = homeState;
        const dispatch = useDispatch();
        const history=useHistory();
        const {accessAllowed, accessBlockReason} = isPaidContentAllowed(user);


        return <div className="d-flex align-items-center justify-content-between w-100 px-4 py-1"
            style={{ height: "50px", minHeight: "50px", maxHeight: "50px", background: "#320664" }}>

            {FooterConfig.map((config, i) => {
                return <Link onClick={(e) => {


                    if(!session._id&&config.name !== 'Home')
                    {e.preventDefault()
                        history.push("/login");
                        return
                    }

                    if (config.name === 'Profile') {
                        // Analytics
                        SendAnalyticsToFirebase(AnalyticsEventsName.PROFILE_VIEW);
                    } else if (config.name === 'Messages') {

                        if (userHasNoName) {
                            setShowNoNameModal(true);
                            return;
                        }

                        if (accessAllowed) {
                            dispatch(MessagesActions.UpdateUnseenMessageInFotter(0));
                        } else if (accessBlockReason === AccessResponseStatus.LOW_BALANCE) {
                            e.preventDefault();
                            setShowLowBalanceModal(true);
                            return;
                        } else if (accessBlockReason === AccessResponseStatus.NO_SUBSCRIPTION){
                            setShowPaywall(true);
                            return;
                        }
                        // Analytics
                        SendAnalyticsToFirebase(AnalyticsEventsName.USE_CHAT);
                    } else if (config.name === 'Home') {
                        // Analytics
                        SendAnalyticsToFirebase(AnalyticsEventsName.TAB_HOME_PRESSED);
                    }
                    else if (config.name === 'LeaderBoard') {
                        SendAnalyticsToFirebase(AnalyticsEventsName.TAB_LEADER_BOARD_PRESSED);
                    }
                }}
                    to={config.routeName}
                    key={i} className={config.name === 'Messages' ? "position-relative" : ""}>

                    {messagesState.unseenMessagesCount !== 0 && config.name === 'Messages' && <div className="position-absolute"
                        style={{
                            right: "-5px", top: "-5px",
                            height: "15px", width: "15px",
                            background: "red",
                            borderRadius: "50px"
                        }}>
                    </div>}
                    <StaticImage
                        src={selected === config.name ? config.imageSelected.src : config.image.src}
                        height={selected === config.name ? config.imageSelected.height : config.image.height}
                        width={selected === config.name ? config.imageSelected.width : config.image.width} />
                </Link>
            })}
        </div>
    }

const FooterConfig = [{
    name: 'Home',
    routeName: '/',
    image: {
        src: '/images/Ico_Home_UnSelected.png',
        height: '22px',
        width: '36px'
    },
    imageSelected: {
        src: '/images/ico_Home@2x.png',
        height: '22px',
        width: '36px'
    }
}, {
    name: 'Messages',
    routeName: '/messages',
    image: {
        src: '/images/ico_Chat@2x.png',
        height: '24px',
        width: '24px'
    },
    imageSelected: {
        src: '/images/Ico_Chat_Selected.png',
        height: '24px',
        width: '24px'
    }
},
{
    name: 'LeaderBoard',
    routeName: '/leaderboard',
    image: {
        src: '/images/leaderboard.png',
        height: '23px',
        width: '23px'
    },
    imageSelected: {
        src: '/images/leaderboard-selected.png',
        height: '23px',
        width: '23px'
    }
},
{
    name: 'Profile',
    routeName: '/profile',
    image: {
        src: '/images/ico_Friends@2x.png',
        height: '26px',
        width: '24px'
    },
    imageSelected: {
        src: '/images/Ico_Friends_white.png',
        height: '23px',
        width: '23px'
    }
}];