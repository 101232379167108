import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";

import { getCookie } from "@Services/Cookie";
import { setCookie } from "@Services/Cookie";
import {
  getMessagingToken,
  InitilizeFirebase,
  RemoveSessionIdListner,
  ObserveAuth,
  SendAnalyticsToFirebase,
  AnalyticsEventsName,
} from "@Services/FirebaseEvents";
import {
  SetDatabaseConnectionListner,
  SetOnlineStatus,
} from "@Services/FirebaseEvents";

import "./Css/main.scss";
import Container from "./Router";
import "./Languages";
import { IStore } from "@Redux/IStore";
import { ConversationActions } from "@Actions/Conversation";
import { AuthenticationActions } from "@Actions/Authentication";
import { PersistActions } from "@Actions/PersistActions";

import { ServiceWorkerPrompt } from "@Components/ServiceWorkerPrompt";
import { TermsConditionsModal } from "@Components/Modals/TermsConditionsModal";
import {
  encryptData,
  getName,
  getNumberInInternational,
} from "@HelperFunctions";
import { BroadcastService } from "@Services/API/Broadcast";
import { ScriptLoader } from "@Services/UI";
import { store } from "@Redux";
import { ActionConsts } from "@Definitions/ActionConsts";
import { HomeActions } from "@Actions/Home";
import { getCheckStatusCallParams } from "@HelperFunctions";
import { GameReconnectingModal } from "@Components/Modals/GameReconnectingModal";
import { AuthenticationModel } from "@Interfaces";
// THIS WILL ACT AS APP SHELL. IT SHOULD BE CACHED WITH SERVICE WORKER AND
// DISPLAYED EVEN OFFLINE

function App() {
  const [showServiceWorkerPrompt, setShowServiceWorketPrompt] =
    useState<any>(undefined);
  const [windowLoaded, setWindowLoaded] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [showTermsConditions, setShowTermsConditions] = useState(false);
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [isFirebaseInitilized, setIsFirebaseInitilized] = useState(false);
  const [fireBaseMessagingStatus, setFireBaseMessagingStatus] = useState(false);
  const [fireBaseMessagingToken, setFireBaseMessagingToken] = useState("");
  const persistState = useSelector((state: IStore) => state.persistState);
  const { gamesListLoaded } = useSelector((state: IStore) => state.home);
  const { configurationUpdated } = useSelector((state: IStore) => state.login);
  const {
    selectedLanguage,
    userSelectedLanguage,
    session,
    sessionID,
    InstallPWA,
    currentWebVersion,
    userLoggedout,
    persistFirebaseConfiguration,
    hitCheckSubscription,
  } = persistState;
  const history = useHistory();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [serviceId, setServiceId] = useState();
  const [showDirectLoginLoader, setShowDirectLoginLoader] = useState(false);
  const [checkSubscriptionStatus, setCheckSubscriptionStatus] = useState(false);
  const [directLoginUserId, setDirectLoginUserId] = useState(Object.create({}));
  const [
    checkSubscriptionStatusResponseCode,
    setCheckSubscriptionStatusResponseCode,
  ] = useState();
  const [isDirectLoginPaymentAdded, setIsDirectLoginPaymentAdded] =
    useState(false);
  const [checkSubscriptionResponseCode, setCheckSubscriptionResponseCode] =
    useState(0);
  const urlParams = new URLSearchParams(window.location.search);
  const subscriptionNo = urlParams.get("subscriptionNo") as string;

  useEffect(() => {
    document.title =
      selectedLanguage === "en"
        ? "Gago – Play Games & Talk Live"
        : "قاقو - ألعاب أونلاين و وتحدث معهم";
  }, [selectedLanguage]);

  useEffect(() => {
    //this function loads config from cloud function
    checkWebVersion();
    if (subscriptionNo)
      (async () => await dispatch(AuthenticationActions.LogoutUser()))();
    getAuthToken();
    initFirebase();
    i18n.changeLanguage(selectedLanguage);
    // disable/enable language style sheet
    enableRTLStyles(selectedLanguage);
    if (
      /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent)
    ) {
      setIsMobileDevice(true);
    } else {
      setIsMobileDevice(false);
    }
    // Add G tag dynamically for production and staging
    const script = ScriptLoader(
      () => {
        const gtagScript = `window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());

      gtag("config", ${
        process.env.REACT_APP_IS_PRODUCTION_API === "true"
          ? "'G-PZEDHL5536'"
          : "'G-HRKWQ8TPMF'"
      });`;
        var s = document.createElement("script");
        s.type = "text/javascript";
        s.innerHTML = gtagScript;
        var head = document.getElementsByTagName("head")[0];
        head.appendChild(s);
      },
      () => {
        console.log("An Error Occured while loading script");
      }
    );
    script.require([
      `https://www.googletagmanager.com/gtag/js?id=${
        process.env.REACT_APP_IS_PRODUCTION_API === "true"
          ? "G-PZEDHL5536"
          : "G-HRKWQ8TPMF"
      }`,
    ]);
    if (process.env.REACT_APP_IS_PRODUCTION_API === "true") {
      const prodScript = ScriptLoader(
        () => {
          const gtagScript = `window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());

      gtag("config", "UA-182116356-2");`;
          var s = document.createElement("script");
          s.type = "text/javascript";
          s.innerHTML = gtagScript;
          var head = document.getElementsByTagName("head")[0];
          head.appendChild(s);
        },
        () => {
          console.log("An Error Occured while loading script");
        }
      );
      prodScript.require([
        `https://www.googletagmanager.com/gtag/js?id=UA-182116356-2`,
      ]);
    }
    checkUtmParams();
  }, []);

  useEffect(() => {
    (async () => {
      if (session._id)
        dispatch(PersistActions.GetGameHistory({ userId: session._id }));
    })();
  }, [session._id]);

  useEffect(() => {
    if (!checkSubscriptionStatus) {
      checkUserSubscription();
    }
    if (!session._id) {
      setCheckSubscriptionStatus(false);
    }
  }, [session._id]);

  useEffect(() => {
    (async () => {
      if (!showDirectLoginLoader && subscriptionNo) {
        setShowDirectLoginLoader(true);
      }
      if (serviceId && subscriptionNo && directLoginUserId._id) {
        addDirectLoginPaymentMethod(subscriptionNo, directLoginUserId._id);
        let subscriptionNumber = subscriptionNo;
        SendAnalyticsToFirebase(AnalyticsEventsName.DIRECT_LOGIN);
        (window as any).DIRECT_LOGIN = true;
        const tokenCookie = getCookie("api-token");
        if (tokenCookie != null) {
          let loginParams: any = {};
          //if (fireBaseMessagingToken) {
          loginParams = {
            //fcm_key: fireBaseMessagingToken,
            fbId: "",
            isWeb: true,
            _id: directLoginUserId._id,
            name: getName(directLoginUserId),
            configVersion: persistFirebaseConfiguration?.v,
          };

          // serviceId && Object.assign(loginParams, { serviceId: serviceId });
          const userResponse: any = await dispatch(
            AuthenticationActions.UpdateUser(loginParams)
          );
          if (userResponse.success) {
            if (!userResponse.response.name) {
              dispatch(PersistActions.SetUserHasNoName(true));
            }
            // window.location.pathname = "/";
            window.history.replaceState({}, "", "/"); //= "/";
          }
        }
        setShowDirectLoginLoader(false);
      }
    })();
  }, [serviceId, directLoginUserId]);

  useEffect(() => {
    if (userLoggedout || session.configUpdated) {
      !subscriptionNo && getAuthToken();
    }
  }, [userLoggedout, session.configUpdated]);

  useEffect(() => {
    if (configurationUpdated) {
      getFireBaseConfig();
    }
  }, [configurationUpdated]);

  useEffect(() => {
    if (windowLoaded) {
      window.removeEventListener("load", () => {
        setWindowLoaded(true);
      });
    }
  }, [windowLoaded]);

  useEffect(() => {
    if (subscriptionNo) {
      if (
        session._id &&
        session.phone === getNumberInInternational(subscriptionNo) &&
        isFirebaseInitilized
      ) {
        setFirebaseConnectionListener();
      }
    } else {
      if (session._id && isFirebaseInitilized) {
        setFirebaseConnectionListener();
      }
    }
  }, [session._id, isFirebaseInitilized]);

  // useEffect(() => {
  //   console.log("window.location.pathname", window.location.pathname);
  //   window.history.pushState(null, "", window.location.pathname);
  //   window.addEventListener("popstate", onBackButtonEvent);

  //   //logic for showing popup warning on page refresh
  //   // window.onbeforeunload = function () {
  //   //   return "Data will be lost if you leave the page, are you sure?";
  //   // };
  //   return () => {
  //     window.removeEventListener("popstate", onBackButtonEvent);
  //   };

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // const onBackButtonEvent = (e: any) => {
  //   console.log("onBackButtonEvent", window.location.pathname,window.history,window.location.href);
  //   e.preventDefault();
  //   if (window.location.pathname == "/messages")
  //   {

  //     if (!isBackButtonClicked) {
  //       if (window.confirm("Do you want to go to Test Listing")) {
  //         setBackbuttonPress(true);

  //         window.history.replaceState({}, "", "/"); //= "/";
  //         window.history.go(0)
  //         // window.history.pushState(null, "", '/');
  //       } else {
  //         window.history.pushState(null, "", window.location.pathname);
  //         setBackbuttonPress(false);
  //       }
  //     }
  //   }
  // };

  useEffect(() => {
    if (window.matchMedia("(display-mode: standalone)").matches) {
      console.log("This is running as standalone.");
    } else {
      // if (
      //   windowLoaded &&
      //   InstallPWA === undefined &&
      //   gamesListLoaded
      // ) {
      //   setShowServiceWorketPrompt(true);
      // } else {
      //   setShowServiceWorketPrompt(false);
      // }
    }
  }, [gamesListLoaded, windowLoaded]);
  useEffect(() => {
    if (checkSubscriptionResponseCode === 110 && !session.onTrial) {
      dispatch(HomeActions.SetUserHasLowBalance(true));
    } else dispatch(HomeActions.SetUserHasLowBalance(false));
  }, [session._id, session.purchaseStatus]);

  const checkUtmParams = () => {
    try {
      let compaign_id = urlParams.get("utm_source") as string;
      let tracking_code = urlParams.get("utm_content") as string;
      let compaign_name = urlParams.get("utm_campaign") as string;
      let medium = urlParams.get("utm_medium") as string;
      let loginSequence = urlParams.get("ls") as string;
      let paymentMethods = urlParams.get("pm") as string;
      let paymentMethodOnLogin=urlParams.get("pmol") as string;
      let canSkipPawall=urlParams.get("cs") as string;
      // let subscriptionNo = urlParams.get("subscriptionNo");
      if (loginSequence)
        dispatch(PersistActions.saveLoginParams(loginSequence));
      if (paymentMethods)
        (window as any).compaignPaymentMethods = paymentMethods;
      if (paymentMethodOnLogin){
        const serviceId = parseInt(
          paymentMethodOnLogin.substring(
            paymentMethodOnLogin.indexOf("-") + 1))
        dispatch(AuthenticationActions.SetPaymentMethodOnLogin(serviceId));
      }
      (window as any).compaignPaymentMethodOnLogin = paymentMethodOnLogin;
      if(canSkipPawall){
       // (window as any).canSkipPawall = canSkipPawall;
        dispatch(
          AuthenticationActions.SetPaywallSkipStatus(canSkipPawall === 'true')
        );
      }
      if (compaign_id != null && compaign_id != "") {
        dispatch(
          AuthenticationActions.setUtmParams({
            compaign_id: compaign_id,
            tracking_code: tracking_code,
            compaign_name: compaign_name,
            medium: medium,
          })
        );
      }
      dispatch(
        AuthenticationActions.postURLParams(
          session && session._id
            ? {
                msisdn: subscriptionNo,
                url: window.location.href,
                compaign: compaign_name,
                medium: medium,
                source: compaign_id,
                userId: session._id,
              }
            : {
                msisdn: subscriptionNo,
                url: window.location.href,
                compaign: compaign_name,
                medium: medium,
                source: compaign_id,
              }
        )
      );
    } catch (error) {}
  };

  const checkWebVersion = () => {
    try {
      const latestWebVersion = process.env.REACT_APP_WEB_VERSION;
      // console.log("Latest Version From Config", latestWebVersion);
      // console.log("Latest Version From State", currentWebVersion);
      if (latestWebVersion && currentWebVersion != latestWebVersion) {
        dispatch(AuthenticationActions.setWebVersion(latestWebVersion));
        //   donot reload state if it is new user + old user who doesnot have key
        if (currentWebVersion !== "") {
          setTimeout(() => {
            // console.log("Latest Version From State", "Reloading called");
            window.location.href = window.location.href;
          }, 1000);
        }
      }
    } catch (error) {
      console.log("Latest Version Error", error);
    }
  };

  const fireAddToHomeScreenImpression = (event: any) => {
    event
      .prompt()
      .then(function (success: any) {
        event.userChoice.then((choiceResult: any) => {
          window.removeEventListener(
            "beforeinstallprompt",
            fireAddToHomeScreenImpression
          );
          if (choiceResult.outcome === "accepted") {
            // setCookie("install-pwa", "1");
            dispatch(PersistActions.SetInstallPWAModal(true));
            setShowServiceWorketPrompt(false);
          } else {
            console.log("User dismissed the install prompt");
          }
        });
      })
      .catch((fail: any) => {
        window.removeEventListener(
          "beforeinstallprompt",
          fireAddToHomeScreenImpression
        );
      });
  };

  window.addEventListener("load", () => {
    setWindowLoaded(true);
    setShowLoader(false);
  });

  window.addEventListener("beforeinstallprompt", fireAddToHomeScreenImpression);

  const unload = async () => {
    if ((window as any).BANNER_CLICKED) {
      SendAnalyticsToFirebase(AnalyticsEventsName.BANNER_SUCCESS_TO_REDIRECT);
      (window as any).BANNER_CLICKED = false;
    }
    const storedData = localStorage.getItem("persist:root");
    if (!storedData) {
      (window as any).isLocalStorageEmpty = true;
    }
    // When user is routing/closing the Web
    if ((window as any).isFirebaseInitilized) {
      (window as any).isFirebaseInitilized = false;
      if (session && session._id) {
        await SetOnlineStatus(
          session && session._id ? session._id : "",
          false,
          false,
          updateBlockCallback,
          sessionID
        );
        RemoveSessionIdListner(session._id);
      }
    }
    BroadcastService().initInstance().close();
    // debugger;
    return;
  };
  window.addEventListener("beforeunload", unload, false);
  // window.onbeforeunload = unload;
  const redirect_event = (e: any) => {
    console.log(e);
    if ((window as any).BANNER_FAILED) {
      SendAnalyticsToFirebase(AnalyticsEventsName.BANNER_FAIL_TO_REDIRECT);
      (window as any).BANNER_FAILED = false;
    }
    //alert(e.eventName);
  };
  window.addEventListener("redirect_event", redirect_event);
  const user_redirec_close_event = (e: any) => {
    console.log(e);
    if ((window as any).BANNER_FAILED) {
      SendAnalyticsToFirebase(AnalyticsEventsName.USER_CLOSED_REDIRECT_BANNER);
      (window as any).BANNER_FAILED = false;
    }
    //alert(e.eventName);
  };
  window.addEventListener("user_redirec_close_event", user_redirec_close_event);
  const onServiceWorkerRegisterSuccess = (closePrompt?: boolean) => {
    if (closePrompt) setShowServiceWorketPrompt(false);
  };

  const getFireBaseConfig = async () => {
    const firebaseConfig: any = await dispatch(
      AuthenticationActions.SetFirebaseConfig({
        // firebaseId: firebaseUid,
        isWeb: true,
        appVersion: "4.2",
        userId: session?._id,
        configVersion: persistFirebaseConfiguration?.v + "",
      })
    );
    setCookie("config", "1");
    dispatch(AuthenticationActions.setForceFirebaseConfigFlag(false));
    if (firebaseConfig.success && firebaseConfig.response)
      setCookie("api-token", encryptData(firebaseConfig.response.userToken));

    return firebaseConfig;
  };

  const fetchSubUIConfigurations = (firebaseConfig: any) => {
    let subUiConfigUrl = null;
    firebaseConfig?.regions?.forEach((element: any) => {
      if (
        firebaseConfig?.country?.country_code.toLowerCase() ===
          element?.countryIso.toLowerCase() &&
        element?.subUiConfig
      ) {
        subUiConfigUrl = element.subUiConfig;
      }
    });
    if (subUiConfigUrl) {
      dispatch(
        AuthenticationActions.getVerificationScreenConfiguration(subUiConfigUrl)
      );
    }
  };

  const getAuthToken = async (firebaseUid?: string) => {
    let firebaseConfig: any;
    if (!subscriptionNo) {
      if (
        !session.configUpdated &&
        getCookie("config") &&
        getCookie("api-token")
        // &&
        // persistFirebaseConfiguration?.regions &&
        // persistFirebaseConfiguration?.regions?.length > 0
      ) {
        firebaseConfig = {
          response: persistFirebaseConfiguration,
          success: true,
        };
      } else {
        firebaseConfig = await getFireBaseConfig();
        if (firebaseConfig.response.v === persistFirebaseConfiguration?.v)
          firebaseConfig.response = {
            ...persistFirebaseConfiguration,
            ...firebaseConfig.response,
          };
      }
    } else {
      firebaseConfig = await getFireBaseConfig();
    }
    if (firebaseConfig.success && firebaseConfig.response) {
      fetchSubUIConfigurations(firebaseConfig.response);
      if (session && session._id) {
        if (window.location.search.indexOf("subscriptionNo") === -1)
          dispatch(
            AuthenticationActions.GetUser(
              session._id,
              firebaseConfig.response.v,
              dispatch
            )
          );
      }
      if (subscriptionNo) {
        let subscriptionNumber = getNumberInInternational(subscriptionNo);

        let user: any = await dispatch(
          AuthenticationActions.UpdateUser({
            fbId: "",
            isWeb: true,
            loginType: {
              id: subscriptionNumber.replace("+", ""),
              type: "phone",
            },
            configVersion: firebaseConfig.v,
          })
        );
        if (user.success) {
          setDirectLoginUserId(user.response);
        }
      }
      // const userGeoLocation: any = await dispatch(
      //   AuthenticationActions.GetGeoLocationFromIp()
      // );
      const userGeoLocation: any = firebaseConfig.response.country;
      dispatch({
        payload: userGeoLocation,
        type: ActionConsts.Settings.UserGeoLocation,
      });
      if (!userSelectedLanguage) {
        if (userGeoLocation.country_code) {
          const countryCode = userGeoLocation.country_code.toLowerCase();
          let userRegion = firebaseConfig?.response?.regions?.find(
            (region: any) => {
              return region.countryIso === countryCode;
            }
          );
          if (userRegion) {
            i18n.changeLanguage(userRegion.defaultLang);
            enableRTLStyles(userRegion.defaultLang);
            dispatch(
              PersistActions.SetLanguage({ language: userRegion.defaultLang })
            );
          }
        }
      }
    } else {
      console.log("Error setting token");
    }
    //dispatch setlogoutuser to false the value of user loged out in persist state;
    dispatch(AuthenticationActions.SetLogoutUser(false));
  };

  const updateBlockCallback = (param: { key: string; val: string[] }) => {
    dispatch(AuthenticationActions.UpdateBlockKeysInSession(param));
  };

  const setFirebaseConnectionListener = () => {
    SetDatabaseConnectionListner(
      session && session._id ? session._id : "",
      updateBlockCallback,
      sessionID
    )
      .then(() => {
        dispatch(ConversationActions.SetUserFirebaseConnectivityStatus(true));
      })
      .catch(() => {
        dispatch(ConversationActions.SetUserFirebaseConnectivityStatus(false));
      });
  };
  const getFirebaseMessagingToken = async () => {
    try {
      let currentToken: string = await getMessagingToken();
      setFireBaseMessagingStatus(true);
      setFireBaseMessagingToken(currentToken);
      if (session._id) {
        let loginParams: any;
        if (subscriptionNo)
          loginParams = {
            phone: subscriptionNo.replace("+", ""),
            fcm_key: currentToken,
            isWeb: true,
            configVersion: persistFirebaseConfiguration?.v,
          };
        else
          loginParams = {
            _id: session._id,
            fcm_key: currentToken,
            isWeb: true,
            configVersion: persistFirebaseConfiguration?.v,
          };

        const userResponse: any = await dispatch(
          AuthenticationActions.UpdateUser(loginParams)
        );
      }
    } catch (ex) {
      setFireBaseMessagingToken("");
      setFireBaseMessagingStatus(true);
      console.log(
        "An error occurred while retrieving token. ",
        "The notification permission was not granted and blocked instead"
      );
    }
  };
  const initFirebase = async () => {
    let firebaseUid = await InitilizeFirebase();
    // if (firebaseUid) {
    setIsFirebaseInitilized(true);
    getFirebaseMessagingToken();

    //}
  };
  const enableRTLStyles = (lang: string) => {
    let styleSheet = document.getElementById("ls");
    if (lang === "ar") {
      if (styleSheet) (styleSheet as any).disabled = false;
    }
  };
  useEffect(() => {
    console.log("Inside hitCheckSubscription");

    checkUserSubscription();
  }, [hitCheckSubscription]);
  const checkUserSubscription = async () => {
    if ((session && session.paymentMethods?.length > 0) || subscriptionNo) {
      let params = getCheckStatusCallParams(session, subscriptionNo);
      if (params === null) {
        return;
      }
      const subscriptionResponse: any = await dispatch(
        AuthenticationActions.CheckSubscriptionStatus(params)
      );

      (window as any).userSubscriptionStatus =
        subscriptionResponse.response_code;
      // 110 Low Balance, 114 Subscription Success

      if (
        subscriptionResponse.success &&
        (subscriptionResponse.response_code === 110 ||
          subscriptionResponse.response_code === 114 ||
          subscriptionResponse.response_code === 115)
      ) {
        setCheckSubscriptionStatusResponseCode(
          subscriptionResponse.response_code
        );
        try {
          setCheckSubscriptionStatus(true);
          dispatch(
            AuthenticationActions.PackageDetail(subscriptionResponse.data)
          );
          //if (subscriptionResponse.response_code === 110 && !session.onTrial) {
          setCheckSubscriptionResponseCode(subscriptionResponse.response_code);
          //  dispatch(HomeActions.SetUserHasLowBalance(true));
          //}
          if (subscriptionNo) {
            (window as any).IS_DIRECT_LOGIN = true;
          }
          setServiceId(subscriptionResponse.serviceId);
        } catch (ex) {
          // console.log("Error in CheckUserSubscription")
          console.log(ex);
        }
      } else {
        // commenting this because no need to logout if there is not active payment method
        // dispatch(AuthenticationActions.LogoutUser());
        setShowDirectLoginLoader(false);
        // window.history.replaceState({}, "", "/login"); //= "/";
      }
    }
  };

  const addDirectLoginPaymentMethod = (number: string, userId: string) => {
    if (isDirectLoginPaymentAdded) return;

    if (session.purchaseStatus && session.purchaseStatus.data) {
      setIsDirectLoginPaymentAdded(true);
      let payloadParams: any = {
        type: session.purchaseStatus.data.paymentType
          ? session.purchaseStatus.data.paymentType
          : "mobile",
        userId: userId,
        data: number,
        subscriptionId: session.purchaseStatus.data.subscriptionId,
        serviceId: session.purchaseStatus.data.serviceId,
      };

      dispatch(AuthenticationActions.addPaymentMethod(payloadParams, dispatch));
    } else {
      setTimeout(() => {
        addDirectLoginPaymentMethod(number, userId);
      }, 2000);
    }
  };

  const showInitialLoader = () => {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          zIndex: 2000,
        }}
      >
        <div
          style={{
            transform: "translateX(-50%)",
          }}
        >
          <img src="/loading_1.gif" style={{ width: "120px" }} />
        </div>
      </div>
    );
  };
  return (
    <div
      id="main"
      className="d-flex justify-content-center h-100"
      // onScroll={() => {
      //   if (
      //     windowLoaded &&
      //     InstallPWA === undefined
      //     && gamesListLoaded
      //   ) {
      //     setShowServiceWorketPrompt(true);
      //   } else {
      //     if (showServiceWorkerPrompt === undefined)
      //       setShowServiceWorketPrompt(false);
      //   }
      // }}
    >
      {/*{isMobileDevice &&*/}
      {/*  windowLoaded &&*/}
      {/*  showServiceWorkerPrompt &&*/}
      {/*  window.navigator &&*/}
      {/*  window.navigator.serviceWorker &&*/}
      {/*  !window.navigator.serviceWorker.controller && (*/}
      {/*    <ServiceWorkerPrompt*/}
      {/*      onSuccess={onServiceWorkerRegisterSuccess}*/}
      {/*      showPrompt={setShowServiceWorketPrompt}*/}
      {/*    />*/}
      {/*  )}*/}

      {showTermsConditions && (
          <TermsConditionsModal
              onRejectClick={() => {
                // setCookie("accpted-terms-conditions", "0");
                dispatch(PersistActions.SetTermsAndConditionModal(false));
                setShowTermsConditions(false);
                window.location.href = "/";
              }}
              onAcceptClick={() => {
                //setCookie("accpted-terms-conditions", "1");
                dispatch(PersistActions.SetTermsAndConditionModal(true));
                setShowTermsConditions(false);
                dispatch(
                    AuthenticationActions.UpdateUser({
                      _id: session._id,
                      tncAccepted: true,
                      isWeb: true,
                    })
                );
              }}
          />
      )}

      {/* {showLoader&&showInitialLoader()} */}

      {/* {selectedLanguage &&  */}
      <Router>
        <Container
          windowLoaded={windowLoaded}
          setShowServiceWorketPrompt={setShowServiceWorketPrompt}
          isFirebaseInitilized={isFirebaseInitilized}
          fireBaseMessagingStatus={fireBaseMessagingStatus}
          fireBaseMessagingToken={fireBaseMessagingToken}
          setShowTermsConditions={setShowTermsConditions}
        />
      </Router>
      {/* } */}
      {showDirectLoginLoader && (
        <GameReconnectingModal text={"DIRECT_LOGIN_LOADING_TEXT"} />
      )}
    </div>
  );
}

export default App;
