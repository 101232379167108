import { PrimaryButton } from "@Styled/PrimaryButton";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import libphonenumber from "google-libphonenumber";
import { ImageModal } from "@Components/Modals/ImageModal";
import { PaymentMethodType } from "@Constants";
const EnterNumberPayment: React.FC<{
  setPaymentStep: (a: number) => void;
  sendPin: (n: string, a: any) => any;
  selectedPaymentType: any;
  loading: boolean;
  setNumber: (a: string) => void;
  number: string;
  selectedLanguage: string;
  countryCode: string;
}> = ({
  setPaymentStep,
  sendPin,
  selectedPaymentType,
  loading,
  setNumber,
  number,
  selectedLanguage,
  countryCode,
}) => {
  const { t } = useTranslation();
  const [openVideo, setOpenVideo] = useState(false);
  const lib = libphonenumber.PhoneNumberUtil.getInstance();
  return (
    <div className="d-flex justify-content-center flex-column">
      <button
        className="d-flex justify-content-start app-back-button"
        style={{ width: "10%" }}
        onClick={() => {
          //setLoginWithMobile(false);
          setPaymentStep(0);
        }}
      >
        <img
          className="mr-2"
          height="12px"
          width="6px"
          src="/images/back.png"
        />
      </button>
      <div className="d-flex justify-content-center">
        {selectedPaymentType.paymentType === "easypaisa" ? (
          <img src="/images/paywall/easypaisa_enternumber.png" />
        ) : (
          <div className="font-20px text-white font-weight-bold">
            {t("PAY_WITH_MOBILE")}
          </div>
        )}
      </div>
      <div
        className="d-flex justify-content-center text-white mt-4 flex-column"
        dangerouslySetInnerHTML={{
          __html: selectedPaymentType.headerHtml,
          //     `<div style="text-align:center;"><span style="font-weight:bold;color:#fff;">Awesome</span><span style="color:#fff;">, You can availed</span> <span style="color:#FFC700;font-weight:bold">&nbsp;40% discount.</span></div>
          // <div style="color:#fff;text-align:center;">Discounted Price is 15 PKR Weekly!</div>`
        }}
      >
        {/* {selectedPaymentType.headerHtml} */}
      </div>
      <div className="d-flex justify-content-center text-white">
        {/* {"Discounted Price is 15 PKR Weekly!"} */}
        {/* {selectedPaymentType.disclaimers.filter(
          (disclaimer: any) => disclaimer.lang === selectedLanguage
        ).length
          ? selectedPaymentType.disclaimers.filter(
              (disclaimer: any) => disclaimer.lang === selectedLanguage
            )[0].disclaimer
          : ""} */}
      </div>
      <div className="d-flex mt-5 flex-column">
        <div className="d-flex text-white titillium-semibold font-18px mb-2">
          {selectedPaymentType.paymentType === PaymentMethodType.EASYPAISA
            ? t("ENTER_EASY_PAISA_NUMBER")
            : t("PLEASE_ENTER_MOBILE")}
        </div>
        <input
          style={{ fontWeight: "bold", height: "2.4em", fontSize: "2em" }}
          type="tel"
          className="telInput ltr px-4"
          name="mobile_number"
          value={number}
          required
          disabled={false}
          placeholder={lib.getCountryCodeForRegion(countryCode) + " xxxxxxx"}
          onChange={(e) => {
            if (
              e.target.value.length <=
              selectedPaymentType.numLimit +
                selectedPaymentType.operatorCode.length +
                1
            )
              setNumber(e.target.value);
            else return;
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
            }
          }}
          onFocus={(e) => {}}
          onBlur={() => {}}
          autoComplete="off"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        />
        <div className="d-flex w-100 justify-content-center">
          <PrimaryButton
            className="font-20px titillium-semibold text-primary position-relative  ltr mt-5 mx-3 get-pin-button py-3"
            backgroundColor={"#FFC700"}
            borderRadius="30px"
            id="primary"
            onClick={async () => {
              if (number) {
                const parsedNumber = lib.parse(number, countryCode);

                let interNationalFormatNumber = lib.format(parsedNumber, 0);
                console.log(interNationalFormatNumber);
                sendPin(interNationalFormatNumber, selectedPaymentType);
              } else sendPin(number, selectedPaymentType);
            }}
          >
            {t("GET_PIN")}
            {loading && (
              <div
                className="position-absolute"
                style={{ left: 0, right: 0, top: 5 }}
              >
                <img src="/continue_loader_1.gif" style={{ width: "50px" }} />
              </div>
            )}
          </PrimaryButton>
        </div>
        <div className="d-flex text-white justify-content-center align-items-center mt-3">
          <span>{t("HAVING_TROUBLE")}</span>
          <span
            style={{ color: "#FFC700", cursor: "pointer" }}
            onClick={() => {
              setOpenVideo(true);
            }}
          >
            &nbsp;&nbsp;{t("WATCH_THIS_VIDEO")}
            <img width="12px" src="/images/paywall/video_play.png" />
          </span>
        </div>
      </div>
      {openVideo && (
        <ImageModal
          src={selectedPaymentType.helpUrl}
          type="video"
          onClose={() => setOpenVideo(false)}
        />
      )}
    </div>
  );
};

export default EnterNumberPayment;
