// Global Imports
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import flatten from "lodash/flatten";
//
// Local Imports
// import { ScrollableList } from '@Components';
import { PrimaryButton, ScrollableList, LazyImage } from "@Styled";
// import { HomeModule } from "./module";
import { HomeActions } from "@Actions/Home";
import { ExclusiveFeaturesActions } from "@Actions/ExclusiveFeatures";
import { PersistActions } from "@Actions/PersistActions";

// import { ScriptLoader } from '@Services';
import { GameItem, GameItemPlaceholder } from "@Components/GameItem";
import { SinglePlayerGameBoard } from "@Components/SinglePlayerGameBoard";
// import { LoadingSpinner } from "@Components/LoadingSpinner";
import { LoadingGIF } from "@Components/LoadingSpinner/LoadingGIF";

import styles from "./style.module.scss";
import { IStore } from "@Redux/IStore";
import { GAME_ITEM, GAME_CATEGORY } from "@Interfaces";
import { SendAnalyticsToFirebase } from "@Services/FirebaseEvents";
import { AnalyticsEventsName } from "@Services/FirebaseEvents";
import { GenericPopupModal } from "@Components/Modals/GenericPopupModal";
import { isPaidContentAllowed } from "@HelperFunctions";
import { AccessResponseStatus } from "@Constants";
import { faGrinBeamSweat } from "@fortawesome/free-solid-svg-icons";
import { GameItemWithoutBackground } from "@Components/GameItem/GameItemWithoutBackground";

//
let loadingOnScroll = false;
export const HomeContainerNewDesign: React.FunctionComponent<{
  onRequestMatchMaking: (a: number, b: boolean) => void;
  setShowLowBalanceModal: (a: boolean) => void;
  setShowNoNameModal: (a: boolean) => void;
  showSubscriptionSuccessfull: boolean;
  setShowSubscriptionSuccessfull: (a: boolean) => void;
  setShowPaywall: (a: boolean) => void;
  setPremiumFeature:(a: boolean)=>void;
  // setExclusiveFeatureTitle:(a: string)=>void
}> = ({ onRequestMatchMaking, setShowLowBalanceModal, setShowNoNameModal, showSubscriptionSuccessfull, setShowSubscriptionSuccessfull, setShowPaywall,setPremiumFeature }) => {
  const [showGameBoard, setShowGameBoard] = useState(false);
  const dispatch = useDispatch();
  const persistState = useSelector((state: IStore) => state.persistState);
  const homeState = useSelector((state: IStore) => state.home);
  // const loginState = useSelector((state: IStore) => state.login);
  // const { firebaseConfig } = loginState;
  const { userHasLowBalance, gamesListLoaded, homePageNo } = homeState;
  let { gamesList, recentlyPlayedGameIds, selectedLanguage, session, userHasNoName, persistFirebaseConfiguration: firebaseConfig = { regions: [] }, showPaywall } = persistState;
  const [loadingGames, setLoadingGames] = useState(false);
  const [gameCategoryList, setGameCategoryList] = useState<GAME_CATEGORY[]>([])
  const [recentlyPlayedGames, setRecentlyPlayedGames] = useState<GAME_ITEM[]>(
    []
  );
  const history = useHistory();
  const [selectedGame, setSelectedGame] = useState<GAME_ITEM | any>({});
  const { t } = useTranslation();
  const state = useSelector((state) => state);

  if ((gamesList == null || gamesList.length == 0) && !loadingGames) {
    setLoadingGames(true);
  }


  useEffect(() => {
    let scrollElement = document.getElementById("mainScroll")
    if (scrollElement) {
      if (!scrollElement.onscroll) {
        scrollElement.addEventListener("scroll", async function (event: any) {
          if (isInView("sfakndfaja5sad5155")) {
            if (!loadingOnScroll) {
              loadingOnScroll = true
              await dispatch(HomeActions.GetAppDesignGamesList(getGameListparams(), recentlyPlayedGameIds));
              loadingOnScroll = false
            }
          }
        }, false)
      }
    }
    return () => {
      scrollElement?.removeEventListener("scroll", () => { });
    }
  }, []);
  const getPageNo = () => {
    let page = localStorage.getItem("ipn");
    if (page)
      return +page
    return 1

  }
  const getGameListparams = () => {
    var configNo = localStorage.getItem("configNo");
    if (!configNo) {
      configNo = "0"
    }
    return { pageNo: getPageNo() + 1, itemCount: 12 , configVersion: parseFloat(configNo)}
  }
  const isInView = (id: string) => {
    let currentUser = document.getElementById(id);
    if (currentUser) {
      const rect = currentUser.getBoundingClientRect();

      let isInViewPort = rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth);
      return isInViewPort;

    }
  }
  useEffect(() => {
    // if (firebaseConfig.regions.length > 0) setLoadingGames(false);

    if (!gamesListLoaded ) {
      (async () => {
        if (!gamesList.length) setLoadingGames(true);
        // await dispatch(HomeActions.GetGamesList({}));
        // console.log("debugger");
        // debugger;
        await dispatch(HomeActions.GetAppDesignGamesList({ pageNo: homePageNo, itemCount: 12 , configVersion: persistState?.persistFirebaseConfiguration?.v}, recentlyPlayedGameIds));
        setLoadingGames(false);
      })();
    }
  }, [firebaseConfig.regions, persistState]);

  useEffect(() => {
    // if (recentlyPlayedGameIds) {
    //   const allGames = flatten(
    //     gamesList.map((category: GAME_CATEGORY) => {
    //       return [...category.games];
    //     })
    //   );
    //   const _recentlyPlayedGames: any = [];
    //   recentlyPlayedGameIds.forEach((playedGameId) => {
    //     const playedGame = allGames.filter((game) => {
    //       return game.gameId === playedGameId;
    //     });
    //     playedGame.length && _recentlyPlayedGames.push(playedGame[0]);
    //   });
    //   // setRecentlyPlayedGames([..._recentlyPlayedGames]);
    //   let allCat = gamesList.filter((category: GAME_CATEGORY) => {
    //     return category.name !== "recentPlayed"
    //   })
    //   allCat[0].games = [..._recentlyPlayedGames, ...allCat[0].games]

    // }
    if (gamesList && recentlyPlayedGameIds) {
      const allGames = flatten(
        gamesList.map((category: GAME_CATEGORY) => {
          return [...category.games];
        })
      );
      const _recentlyPlayedGames: any = [];
      recentlyPlayedGameIds.forEach((playedGameId) => {
        const playedGame = allGames.filter((game: any) => {
          return game.gameId === playedGameId;
        });
        playedGame.length && _recentlyPlayedGames.push(playedGame[0]);
      });
      let allCat = gamesList.filter((category: GAME_CATEGORY) => {
        return category.viewType !== "recentPlayed"
      })
      let games = [..._recentlyPlayedGames];
      _recentlyPlayedGames.forEach((game: any) => {
        let index = allCat[0].games.findIndex((r: any) => r.gameId === game.gameId);
        if (index > -1)
          allCat[0].games.splice(index, 1);
      });
      if (allCat && allCat.length)
        allCat[0].games = [...(games.reverse()), ...allCat[0].games]

      allCat.forEach((cat: GAME_CATEGORY, index: number) => {
        if (index > 0) {
          _recentlyPlayedGames.forEach((rgame: any, gameIndex: number) => {
            let idx = cat.games.findIndex((g: any) => g.gameId === rgame.gameId);
            if (idx > -1) {
              let removed = cat.games.splice(idx, 1)
              cat.games = [...cat.games, ...removed];
            }
          })
        }
      })

      setGameCategoryList(allCat);
    }
  }, [recentlyPlayedGameIds, gamesList]);

  useEffect(() => {
    if (!session._id && (window as any).userSessionTokenChanged)
      history.push("/login");
  }, [session]);

  const cancelMatchMaking = () => {
    setSelectedGame({});
    setShowGameBoard(false);
  };
const onPremiumGameClick=()=>{
  setShowPaywall(true) 
  dispatch(
    ExclusiveFeaturesActions.SetExclusiveFeature(true)
  )
  // setPremiumFeature(true)
  // setExclusiveFeatureTitle("EXCLUSIVE_FEATURE")
  
}
  const playGame = (game: GAME_ITEM) => {
    if (!persistState.session._id) {
      history.push("/login");
      return;
    }

    if (userHasNoName) {
      setShowNoNameModal(true);
      return;
    }

    if(game.isSubRequired === true){
      const {accessAllowed, accessBlockReason} = isPaidContentAllowed(session);
      if (!accessAllowed) {
          accessBlockReason === AccessResponseStatus.LOW_BALANCE ? setShowLowBalanceModal(true) : onPremiumGameClick() ;
          return;
      }
    }
    
    if (game.multiplayer) {
      onRequestMatchMaking(game.gameId, true);

      // Analytics
      SendAnalyticsToFirebase(AnalyticsEventsName.ON_MP_GAME_ITEM_PRESSED);
    } else {
      setSelectedGame(game);
      setShowGameBoard(true);

      // Analytics
      SendAnalyticsToFirebase(AnalyticsEventsName.ON_SP_GAME_ITEM_PRESSED);
    }
  };

  return (
    <div className={"d-flex flex-column pb-3 " + (loadingGames ? "h-100" : "")} id="scroll" >

      {showGameBoard && !selectedGame.multiplayer && (
        <SinglePlayerGameBoard
          show={showGameBoard}
          selectedGame={selectedGame}
          exitGame={() => {
            // Analytics
            SendAnalyticsToFirebase(
              AnalyticsEventsName.ON_SP_GAME_ITEM_PRESSED
            );


            // dispatch(
            //   PersistActions.UpdateRecentlyPlayedGame({
            //     gameId: selectedGame.gameId,
            //   })
            // );
            cancelMatchMaking();
          }}
        />
      )}

      <div className="text-white my-2 titillium-semibold font-16px">
        {/* {t("RECENT_PLAYED")} */}
      </div>
      <div className={styles.overflowX}>
      </div>
      <LoadingGIF showLoading={loadingGames} color="#ffffff" size="4x">
        {gameCategoryList.map((gameCategory: GAME_CATEGORY, i: number) => {
          return (
            <div
              className="d-flex flex-column align-items-start w-100 h-100"
              key={i}
            >
              {gameCategory.viewType !== "inviteAndDiscover" && <>
                <div className="text-white titillium-semibold d-flex align-items-center font-18px mb-2">
                  {
                    gameCategory.icon && (
                      <img src={gameCategory.icon} className={styles.fireCircle} />
                    )
                  }

                  {gameCategory.viewType !== "recentPlayed" && <div className="px-2">
                    {selectedLanguage === "en"
                      ? gameCategory.name
                      : gameCategory.nameAr}
                  </div>}
                </div>
                <div className={(i > 0 ? "mb-3 " : "") + styles.overflowX}>

                  {/* {gameCategory.viewType === "recentPlayed" && <div className="d-flex align-items-center mb-3 mt-3">


                    {recentlyPlayedGameIds && recentlyPlayedGameIds.length > 0 ?
                      (
                        recentlyPlayedGames.map((game: GAME_ITEM, i: number) => {
                          return (
                            <div key={i} onClick={() => { playGame(game); }} className={styles.flexGameItems}
                            //style={{ marginLeft: i > 0 ? "10px" : "0px", flex: "0 0 20%", }}
                            >
                              <LazyImage
                                src={
                                  game.gifUrl && game.gifUrl !== "false"
                                    ? game.gifUrl
                                    : game.thumbnail
                                }
                                alt={game.title}
                                height="100%"
                                borderRadius="15px"
                                width="100%"
                              />
                            </div>
                          );
                        })
                      ) :
                      <div className="text-white mt-2 text-center w-100">
                        No recently played games.
                      </div>
                    }
                  </div>
                  } */}


                  <div className={"d-flex align-items-stretch " + (gameCategory.viewDirection === "vertical" ? " justify-content-center flex-wrap" : " ")}>

                    {!loadingGames ? (

                      gameCategory.games.map((game: GAME_ITEM, i) => {
                        return (
                          <>
                            {
                              gameCategory.viewDirection !== "vertical" && gameCategory.viewType === "highlighted" ?
                                <GameItem
                                  game={game}
                                  onGameClick={playGame}
                                  isGameInvite={false}
                                  loading={selectedGame._id === game._id}
                                  key={i}
                                  selectedLanguage={selectedLanguage}
                                  showGameItemBackground={gameCategory.viewType === "highlighted"}
                                />
                                :
                                <GameItemWithoutBackground
                                game={game}
                                onGameClick={playGame}
                                key={i}
                                selectedLanguage={selectedLanguage}
                                gameCategoryDirectionVertical={gameCategory.viewDirection === "vertical"}
                                />
                            }
                          </>
                        );
                      })
                    ) : (
                      <GameItemPlaceholder key={i} />
                    )}
                  </div>



                </div>
              </>
              }

              {gameCategory.viewType === "inviteAndDiscover" && (
                <div
                  className={
                    "d-flex align-items-center w-100 my-4 " +
                    styles.justifyContentSpaceEvenly
                  }
                  key={i}
                >
                  <Link to="/invite-friends">
                    <PrimaryButton
                      padding="14px 32px"
                      className="font-16px titillium-semibold text-white mr-2"
                      backgroundColor="#FF5115"
                      onClick={() => {
                        // Analytics
                        SendAnalyticsToFirebase(
                          AnalyticsEventsName.INVITE_FRIENDS_PRESSED
                        );
                      }}
                      borderRadius="30px"
                    >
                      {t("INVITE_FRIENDS")}
                    </PrimaryButton>
                  </Link>
                  <Link to="/discover-people">
                    <PrimaryButton
                      padding="14px 32px"
                      className="font-16px titillium-semibold text-white"
                      backgroundColor="#FFA234"
                      onClick={() => {
                        // Analytics
                        SendAnalyticsToFirebase(
                          AnalyticsEventsName.DISCOVER_PEOPLE_PRESSED
                        );
                      }}
                      borderRadius="30px"
                    >
                      {t("DISCOVER_PROPLE")}
                    </PrimaryButton>
                  </Link>
                </div>
              )}
            </div>
          );
        })}
      </LoadingGIF>
      <div id="sfakndfaja5sad5155" className="text-center" >
        {!persistState.appendListLoaded && loadingGames === false && <LoadingGIF size="4x"></LoadingGIF>}
      </div>
    </div>
  );
};






