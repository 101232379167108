import React, { useEffect, useState, useRef, RefObject, useMemo } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactDOM from "react-dom";
import Snackbar from '@material-ui/core/Snackbar';

import { AnimatePopup, ParagraphText } from "@Styled";
import { IMultiPlayerGameBoard } from "./IMultiPlayerGameBoard";
import { PersistActions } from "@Actions/PersistActions";
import { HomeActions } from "@Actions/Home";
import { ConversationActions } from "@Actions/Conversation";

import { MatchMaking } from "@Components/MatchMaking";
// import { LoadingSpinner } from "@Components/LoadingSpinner";
import { WarningModal } from "@Components/Modals/WarningModal";
import { GameResultImage } from "@Components/GameResultImage";
import { USER_SESSION, GAME_ITEM, IStore } from '@Interfaces';
import { getProfileImage, isScriptLoaded, setViewPortSize } from '@HelperFunctions';
import { useTranslation } from 'react-i18next';
import { GameReconnectingModal } from '@Components/Modals/GameReconnectingModal';
import { GameMessageReceivedModal } from '@Components/Modals/gameMessageReceivedModal';
import { addInGameMessagesListner, removeObserverOnInGameMessages, sendMessageToOpponent } from '@Services/FirebaseEvents';
import { ScriptLoader } from '@Services/UI';
import { store } from '@Redux';
import { ActionConsts } from '@Definitions/ActionConsts';
import GamesSvgRadialMenu from '@Components/RadialMenu/GamesSvgRadialMenu';
import { RotateScreenModal } from '@Components/Modals/RotateScreenModal';


const MultiPlayerGameBoardComponent: React.FunctionComponent<IMultiPlayerGameBoard.IProps>
    = ({ gamesList, selectedGameId, exitGame, acceptInviteMatchData, registerMatchMaking, show, user, setAcceptInviteMatchData, acceptGameInviteAgain, showInvitePopup, sendGameInvite, selectedLanguage }) => {
        const [startGame, setStartGame] = useState(false);
        const [isDataPostedToIFrame, setIsDataPostedToIFrame] = useState(false);
        const [iframeWindowLoaded, setIframeWindowLoaded] = useState(false);
        const [showGameResultImage, setShowGameResultImage] = useState(false);
        const [selectedGame, setSelectedGame] = useState<GAME_ITEM>(Object.create({}));
        const [showCancelWarning, setShowCancelWarning] = useState(false);
        const [openSnackBar, setOpenSnackBar] = useState(false);
        const [isMobileDevice, setIsMobileDevice] = useState(false);
        const [snackBarMessage, setSnackBarMessage] = useState('');
        const dispatch = useDispatch();
        const iframeRef: RefObject<HTMLIFrameElement> = useRef(null);
        const [iframeContentString, setIframeContentString] = useState<any>("");
        const [gameResult, setGameResult] = useState<string>("");
        const [playAgainStatus, setPlayAgainStatus] = useState<string>("");
        const [gameResultScores, setGameResultScores] = useState([]);
        const [matchData, setMatchData] = useState<any>({});
        const [reconnecting, setReconnecting] = useState(false);
        const [showDeviceRotate,setShowDeviceRotate] = useState(false);
        const [rotationMessage,setRotationMessage] = useState("");

        const [gameMusicStatus, setGameMusicStatus] = useState(true);
        // const [gameMessages, setGameMessages] = useState<any>([]);
        const { gameMessages } = useSelector((state: IStore) => state.home);
        const { t } = useTranslation();
        useEffect(()=>{
            if(document.getElementById('smartbanner')){
                (window as any).bannerHide();
            }
        },[])
        useEffect(() => {
            setIsMobileDevice(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
            if (acceptInviteMatchData.match) {
                //debugger;
                onMatchFound(acceptInviteMatchData)
            }
        }, [acceptInviteMatchData]);

        useEffect(() => {
            let orientationEventName = (window as any).getOrientationEventName();
            console.log("event name: ", orientationEventName)
            manageRotation()
            const handleOrientationChange = (event:any) => {
                // console.log("event: ",event)
                // console.log("outer: ", window.outerWidth)
                // console.log("inner: ", window.innerWidth)
                manageRotation()
            };
            window.addEventListener(orientationEventName, handleOrientationChange)
      
            return () => {
                window.removeEventListener(orientationEventName, handleOrientationChange)
            };
      
        },[startGame, iframeWindowLoaded])

        const manageRotation = () => {
            const _selectedGame = gamesList.filter((game) => {
                return game.gameId === selectedGameId
            });
            if (_selectedGame.length < 1) {
                return
            }
            const game = _selectedGame[0]
            // // for head ball and madmatch 
            // if (game.gameId != 24 && game.gameId != 15) {
            //     return
            // }
            setRotationMessage(game.orientation === "landscape" ? "ROTATE_DEVICE_LANDSCAPE" : "ROTATE_DEVICE_PORTRAIT")
            let orientationData = !navigator.maxTouchPoints ? 'desktop' : !window.orientation ? 'portrait' : 'landscape'
            if (orientationData === "desktop") {
                return
            }

            if (game.orientation === "landscape") {
                (window as any).openFullScreen("view-port-container")
            }

            if ((!startGame || !iframeWindowLoaded || gameResult != "")) {
                setShowDeviceRotate(false)
                setViewPortSize(true, orientationData)
                return
            }

            if (orientationData != game.orientation) {
                console.log("useeffect orientation: ",orientationData)
                setViewPortSize(true, orientationData)
                setShowDeviceRotate(true)
            } else {
                if (orientationData === "landscape" && game.orientation === "landscape") {
                    setShowDeviceRotate(false)
                    setViewPortSize(true, orientationData)
                } else{
                    setShowDeviceRotate(false)
                    setViewPortSize(false, orientationData)
                }
            }
        }

        const updateFrameDocument = async () => {
            const gameDocumentStr: any = await dispatch(HomeActions.GetGameDocStr({
                game: selectedGame
            }));
            //debugger;
            if (typeof gameDocumentStr === "string") {
                const scriptTagUpdatedDoc = updateScriptTags(gameDocumentStr);

                const docStr = "<!DOCTYPE HTML><html>" + scriptTagUpdatedDoc.documentElement.innerHTML + "</html>";
                setIframeContentString(docStr);
            } else {
                dispatch(HomeActions.CancelMatchMaking({
                    userId: user._id,
                    gameId: selectedGame.gameId
                }));
                exitGame(false);
                setOpenSnackBar(true);
                setSnackBarMessage("Couldn't play this game at this time");
                if (selectedGame.orientation === "landscape") {
                    setViewPortSize(false)
                    // let vpc = document.getElementById("view-port-container")
                    // if (vpc) {
                    //     vpc.style.width = "600px";
                    //     vpc.style.maxWidth = "600px";
                    // }
                }
            }
        }

        const initMatchMaking = async () => {
            const matchMakingResponse: any = await dispatch(HomeActions.RequestMatchMaking({
                userId: user._id,
                gameId: selectedGame.gameId
            }));
            //debugger;
            if (matchMakingResponse.success && matchMakingResponse.response && matchMakingResponse.response.match && matchMakingResponse.response.other_user) {
                onMatchFound(matchMakingResponse.response);
                setAcceptInviteMatchData(matchMakingResponse.response);
            }

            if (matchMakingResponse.status === 'error') {
                console.log("Error match making : ", matchMakingResponse);
                exitGame(false);
            }
        }

        useEffect(() => {
            if (iframeWindowLoaded && matchData.other_user && iframeContentString && !isDataPostedToIFrame) {
                const iframeWindow = (iframeRef.current! as any).contentWindow;
                let isSpecialMatchMaking = selectedGame?.rules?.minMatchCount === 4;
                setIsDataPostedToIFrame(true)
                let playerObject: any = {};
                let players = [{

                    playerId: user._id,
                    playerNumber: 0,
                    name: user.name,
                    avatarUrl: getProfileImage(user),
                    isBot: false,
                    teamId: 0,
                    socketId: "",
                    currentGame: {}
                }, {
                    playerId: matchData.other_user._id,
                    playerNumber: 1,
                    name: matchData.other_user.name,
                    avatarUrl: getProfileImage(matchData.other_user),
                    isBot: matchData.other_user.isBot,
                    teamId: 1,
                    socketId: "",
                    currentGame: {}
                }];
                if (!matchData.other_user.isBot)
                    players.sort((a: any, b: any) => {
                        return a.playerId > b.playerId ? 1 : -1;
                    })

                if (
                    isSpecialMatchMaking &&
                    matchData.randomBots &&
                    matchData.randomBots.length == 2
                ) {
                    let botsArray = [];
                    let bot1 = {
                        playerId: matchData.randomBots[0]._id,
                        playerNumber: 2,
                        name: matchData.randomBots[0].name,
                        avatarUrl: getProfileImage(matchData.randomBots[0]),
                        isBot: matchData.randomBots[0].isBot,
                        teamId: 0,
                        socketId: '',
                        currentGame: {},
                    };

                    let bot2 = {
                        playerId: matchData.randomBots[1]._id,
                        playerNumber: 3,
                        name: matchData.randomBots[1].name,
                        avatarUrl: getProfileImage(matchData.randomBots[1]),
                        isBot: matchData.randomBots[1].isBot,
                        teamId: 1,
                        socketId: '',
                        currentGame: {},
                    };

                    botsArray.push(bot1);
                    botsArray.push(bot2);

                    botsArray.sort((a: any, b: any) => {
                        return a.playerId > b.playerId ? 1 : -1;
                    });
                    players = players.concat(botsArray);
                }



                players.forEach((player: any, index: number) => {
                    player.playerNumber = index + 1;
                    player.teamId = (index % 2) + 1;
                    playerObject[player.playerId] = player;
                })
                const gameData = {
                    rId: matchData.roomId,
                    playerId: user._id,
                    match: {
                        matchId: matchData.match._id,
                        gameId: matchData.match.gameId,
                        activeNumPlayers: 0,
                        expectedNumPlayers: matchData.other_user.isBot ? 1 : 2,
                        maxNumPlayers: selectedGame?.rules?.minMatchCount || 2,
                        players: playerObject,
                        gameState: {},
                        endResult: {}
                    },
                    userLang: selectedLanguage.toUpperCase()
                };
                // let frame: any = document.getElementById("iFrame");
                // frame.contentWindow.language = selectedLanguage.toUpperCase()
                // (window as any).language = selectedLanguage.toUpperCase();
                console.log("gameData",JSON.stringify(gameData))
                const gameInfo = {
                    type: "matchData",
                    data: gameData
                }
                iframeWindow.postMessage(JSON.stringify(gameInfo), window.origin);
                // Add Message Listner
                addInGameMessagesListner(matchData.match._id, user._id, gameMessageReceived);
            }
        }, [matchData, iframeContentString, iframeWindowLoaded]);

        useEffect(() => {
            if (matchData.other_user && (gameResult === 'win' || gameResult === 'lose' || gameResult === 'draw' || gameResult === "matchNotFound")) {
                // setJoinCall(false)
                onMatchResult(gameResult);
                // // setIframeContentString('');
                removeObserverOnInGameMessages(matchData.match._id);
            }
            if ((gameResult === 'win' || gameResult === 'lose' || gameResult === 'draw' || gameResult === "matchNotFound") && selectedGame.orientation === "landscape") {
                setViewPortSize(false)
                // let vpc = document.getElementById("view-port-container")
                // if (vpc) {
                //     vpc.style.width = "600px";
                //     vpc.style.maxWidth = "600px";
                // }
            }
        }, [gameResult, matchData]);

        useEffect(() => {
            // (async () => {
            if (selectedGame.gameId >= 0) {
                //debugger;
                updateFrameDocument();
                //debugger;
                if (registerMatchMaking) {
                    initMatchMaking();
                }
            }
            // })();
        }, [selectedGame.gameId]);

        useEffect(() => {
            if (selectedGameId >= 0) {
                const _selectedGame = gamesList.filter((game) => {
                    return game.gameId === selectedGameId
                });
                if (_selectedGame.length) {
                    setSelectedGame(_selectedGame[0]);
                    if (_selectedGame[0].orientation === "landscape") {
                        setViewPortSize(true)
                        // let vpc = document.getElementById("view-port-container")
                        // if (vpc) {
                        //     let aspect = window.innerWidth / window.innerHeight
                        //     let w = window.innerWidth
                        //     let h = w * aspect//0.562
                        //     // window.innerWidth.toString()
                        //     vpc.style.width = w.toString() + "px";
                        //     // vpc.style.maxWidth = h.toString() + "px";
                        //     vpc.style.height = h.toString() + "px";
                        // }
                    }
                }
                if (_selectedGame && _selectedGame.length && !isScriptLoaded(`https://www.googletagmanager.com/gtag/js?id=${_selectedGame[0].analyticsId}`)) {
                    // Add game analytics script
                    const script = ScriptLoader(() => {
                        const gtagScript = `window.dataLayer = window.dataLayer || [];
                    function analytics_${selectedGameId}() {
                      dataLayer.push(arguments);
                    }
                    analytics_${selectedGameId}("js", new Date());
              
                    analytics_${selectedGameId}("config","${_selectedGame[0].analyticsId}",{user_id:"${user._id}"});`;
                        var s = document.createElement('script');
                        s.type = "text/javascript";
                        s.innerHTML = gtagScript;
                        var head = document.getElementsByTagName('head')[0];
                        head.appendChild(s);
                    }, () => {
                        console.log("An Error Occured while loading script")
                    });
                    script.require([`https://www.googletagmanager.com/gtag/js?id=${_selectedGame[0].analyticsId}`]);
                }
            } else {
                setSelectedGame(Object.create({}));
                setMatchData({});
                setIframeContentString('');
                setIframeWindowLoaded(false);
                setGameResult('');
                setStartGame(false);
                setShowCancelWarning(false);
            }

        }, [selectedGameId]);

        useEffect(() => {
            let timeout: any;
            if (playAgainStatus) {
                timeout = setTimeout(() => {
                    setPlayAgainStatus("")
                    clearTimeout(timeout);
                }, 30000)
            }
            return () => {
                clearTimeout(timeout);
            }
        }, [playAgainStatus])
        useEffect(() => {
            toggleSound();
        }, [gameMusicStatus])

        const onMatchFound = (matchData: any) => {
            //debugger;
            setMatchData(matchData);
            setStartGame(true);
            setGameResult('');
            // if (selectedGame.orientation === "landscape" && isMobileDevice) {
            //     setSnackBarMessage('Please rotate your device to landscape');
            //     setOpenSnackBar(true);
            // }
            // manageRotation()
        }

        const updateScriptTags = (gameDocumentStr: string) => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(gameDocumentStr, "text/html");
            const scripts = doc.getElementsByTagName("script");
            // const gamePort = selectedGame.url.split(":")[2].split("/")[0];
            const gameUrl=selectedGame.url;
            // const gamePort = 8097;
            //debugger;
            for (let i = 0; i < scripts.length; i++) {
                if (scripts[i].src === "https://cdn.socket.io/socket.io-2.3.0.js" ||
                    scripts[i].src === "https://www.googletagmanager.com/gtag/js?id=G-WMR1V3CTJ4"
                    ||
                    scripts[i].src === "https://www.googletagmanager.com/gtag/js?id=G-HRKWQ8TPMF"
                ) {
                    continue;
                }

                if (scripts[i].src) {
                    const pathArr = (scripts[i].attributes as any).src.value.split('/');
                    let pathStr = gameUrl + "/";
                    for (let i = 1; i < pathArr.length; i++) {
                        pathStr += (pathArr[i] + (i < pathArr.length - 1 ? '/' : ''));
                    }
                    scripts[i].src = pathStr;
                } else {
                    console.log("Couldn't load inner html : ", scripts[i].innerHTML);
                }
            }
            const script = doc.createElement("script");
            script.innerHTML = '(function() {' +
                'window.STATIC_PATH =' + `"${gameUrl}` + '";' +
                'window.sender = function(data) {' +
                'window.parent.postMessage(data,window.origin);' +
                '};' +
                '})()';
            doc.head.appendChild(script);
            return doc;
        }

        const onMatchResult = async (message: string) => {
            setShowGameResultImage(true);
            let checkResult = false;
            let interval = setInterval(async () => {
                // // const gameResultScores: any = await dispatch(HomeActions.SaveMatchResult({
                //     userId: user._id,
                //     isDraw: message === 'draw',
                //     isWinner: message === 'win',
                //     matchId: matchData.match._id,
                //     users: [{
                //         userid: user._id,
                //         won: message === 'win',
                //         lost: message === 'lose' ? 1 : 0,
                //         draw: message === 'draw'
                //     }, {
                //         userid: matchData.other_user._id,
                //         won: message === 'draw' ? 0 : message === 'win' ? 0 : 1,
                //         lost: message === 'draw' ? 0 : message === 'lose' ? 0 : 1,
                //         draw: message === 'draw' ? true : false
                //     }]
                // }));
                if (!checkResult) {
                    checkResult = true;
                    try {
                        const gameResultScores: any = await dispatch(HomeActions.GetGameResultFromServer({ matchId: matchData.match._id, userId: user._id, otherUserId: matchData.other_user._id }));
                        if (gameResultScores.success) {
                            if (selectedGame.multiplayer)
                                dispatch(PersistActions.UpdateRecentlyPlayedGame({
                                    gameId: selectedGame.gameId
                                }));

                            setPlayAgainStatus('');
                            // setGameResult(message);
                            setStartGame(false);
                            gameResultScores.success && setGameResultScores(gameResultScores.response.result.users);
                            setShowGameResultImage(false);
                            clearInterval(interval);
                            checkResult = false;
                        } else
                            checkResult = false;
                    }
                    catch (ex) {
                        checkResult = false;
                    }
                }
            }, 2000);
        }

        const onPlayAgain = async () => {
            setPlayAgainStatus(t("WAITING_FOR_OPPONENT"));
            setIframeWindowLoaded(false);
            setIsDataPostedToIFrame(false);
            dispatch(ConversationActions.SetConversationUser({ otherUser: matchData.other_user }));
            sendGameInvite(selectedGame, matchData.other_user);
            if (selectedGame.orientation === "landscape") {
                setViewPortSize(true)
                // let vpc = document.getElementById("view-port-container")
                // if (vpc) {
                //     vpc.style.width = window.innerWidth.toString() + "px";
                //     vpc.style.maxWidth = window.innerWidth.toString() + "px";
                // }
            }
        }

        const onIFrameLoad = () => {
            const iframeWindow = (iframeRef.current! as any).contentWindow;
            iframeWindow.language = selectedLanguage.toUpperCase();
            iframeWindow.gameID = selectedGameId;
            iframeWindow.environment = process.env.REACT_APP_IS_PRODUCTION_API === "true" ? 'production' : 'staging';;
            (window as any).addEventListener('message', async (message: any) => {

                if (message.source !== iframeWindow) {
                    return; // Skip message in this event listener
                }
                if (message.data === 'loading_done') {
                    setIframeWindowLoaded(true);
                    //query for game sound status
                    iframeWindow.postMessage(JSON.stringify({ type: "query", data: { typ: "sound" } }), window.origin);
                } else if (message.data === 'win' || message.data === 'lose' || message.data === 'draw' || message.data === "matchNotFound") {
                    // setTimeout(() => {
                    setGameResult(message.data === "matchNotFound" ? "lose" : message.data);
                    setIframeWindowLoaded(false);
                    setIsDataPostedToIFrame(false);
                    setReconnecting(false);
                    manageRotation()
                    // }, 3000);
                }
                else if (message.data === "showNoConnection") {
                    setReconnecting(true);
                }
                else if (message.data === "hideNoConnection") {
                    setReconnecting(false);
                }
                if (message.date === 'sound_status_mute') {
                    setGameMusicStatus(false);
                }

                if (message.date ===  'sound_status_unMute') {
                    setGameMusicStatus(true);
                }
                else {
                    // console.log('iframe message : ', message);
                }
            });
            // iframeWindow.document.body.onclick = () => {
            //     alert("Click detected inside iframe.");
            // }
        }

        const getJoinButtomPosition = () => {
            // console.log("selectedGame.voipPosition", selectedGame.voipPosition);
            if (selectedGame.voipPosition === "topRight")
                return {
                    top: "5px", right: "25px"
                }
            else if (selectedGame.voipPosition === "bottomRight")
                return {
                    bottom: "10px", right: "25px"
                }
            else if (selectedGame.voipPosition === "topCenter")
                return {
                    top: "5px", left: "50%", transform: "translateX(-50%)",
                }
            else if (selectedGame.voipPosition === "topLeft")
                return {
                    top: "5px", left: "80px"
                }
            else
                return {
                    top: "5px", right: "25px"
                }

        }
        const getGameResultFromServer = async () => {
            const gameResultScores: any = await dispatch(HomeActions.GetGameResultFromServer({ matchId: matchData.matchId, userId: user._id, otherUserId: matchData.other_user._id }));
            setGameResult(gameResultScores.response);
            setIframeWindowLoaded(false);
            setIsDataPostedToIFrame(false);
            dispatch(PersistActions.UpdateRecentlyPlayedGame({
                gameId: selectedGame.gameId
            }));

            setPlayAgainStatus('');
            setStartGame(false);
            gameResultScores.success && setGameResultScores(gameResultScores.response.result.users);
            setShowGameResultImage(false);
        }
        const postLeaveGame = () => {
            const iframeWindow = (iframeRef.current! as any).contentWindow;
            iframeWindow.postMessage(JSON.stringify({ type: "playerLeft", data: null }), window.origin);
        }
        const toggleSound = () => {
            if (iframeRef.current) {
                const iframeWindow = (iframeRef.current! as any).contentWindow;
                iframeWindow.postMessage(JSON.stringify({
                    type: "buttonClicked", data: {
                        type: 'sound',
                        value: gameMusicStatus ? "unMute" : "mute"
                    }
                }), window.origin);
            }
        }
        const sendMessageToOther = (message: any) => {
            message.s = user._id;
            sendMessageToOpponent(matchData.match._id, message)
        }
        const gameMessageReceived = (message: any) => {
            message.profileImage = getProfileImage(matchData.other_user);
            store.dispatch({ type: ActionConsts.Home.SetGameMessages, payload: message });
        }
        const onIFrameLoadError = (e: any) => {
            console.log("onIFrameLoadError : ", e);
        }

        const onPlayMoreGamesClick = (otherUser: USER_SESSION) => {
            if (selectedGame.orientation === "landscape") {
                setViewPortSize(false)
                // let vpc = document.getElementById("view-port-container")
                // if (vpc) {
                //     vpc.style.width = "600px";
                //     vpc.style.maxWidth = "600px";
                // }
            }
            dispatch(ConversationActions.SetConversationUser({ otherUser: otherUser }));
            exitGame(false);
        }

        return show ? ReactDOM.createPortal(<AnimatePopup animateIn={true} zIndex={1}>
            <Snackbar
                autoHideDuration={3000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackBar}
                onClose={() => {
                    setOpenSnackBar(false);
                    setSnackBarMessage('');
                    if (snackBarMessage === "Couldn't play this game at this time")
                        exitGame(true);
                }}
                message={snackBarMessage}
                key="a"
            />
            {showCancelWarning && <WarningModal
                onYesClick={() => {
                    if (startGame && gameResult === '') {
                        postLeaveGame()
                        setTimeout(() => {
                            setGameResult('lose');
                            setIframeWindowLoaded(false);
                            setIsDataPostedToIFrame(false);
                            setShowCancelWarning(false);
                            // setJoinCall(false);
                            // setCallStarted(false)
                        }, 1000);
                    } else {
                        dispatch(HomeActions.CancelMatchMaking({
                            userId: user._id,
                            gameId: selectedGame.gameId
                        }));
                        exitGame(false);
                        if (selectedGame.orientation === "landscape") {
                            setViewPortSize(false)
                            // let vpc = document.getElementById("view-port-container")
                            // if (vpc) {
                            //     vpc.style.width = "600px";
                            //     vpc.style.maxWidth = "600px";
                            // }
                        }
                    }
                }}
                onNoClick={() => { setShowCancelWarning(false) }}
                warningTextElem={<ParagraphText className="text-black text-center font-16px">
                    {t("SP_Game_Quit_Msg")}
                    {/* Are you sure you want to exit ? All your progress will be lost */}
                </ParagraphText>}
                width={selectedGame.orientation === "landscape" && !isMobileDevice ? "40%" : ""}
            />}

            {/* {(gameResult || (startGame && iframeWindowLoaded)) && <div className="position-fixed" style={{ top: "5px", left: "5px", zIndex: 2 }} */}
            {((startGame && iframeWindowLoaded)) && <div className="position-fixed" style={{ top: "5px", left: "5px", zIndex: 2 }}
                onClick={() => {
                    // if (!startGame && gameResult) {
                    if (!startGame) {
                        exitGame(false);
                    } else {
                        setShowCancelWarning(true);
                    }
                }}>
                <div className="d-flex align-items-center justify-content-center" style={{
                    background: "rgb(0,0,0,0.7)",
                    borderRadius: "50%",
                    width: "45px",
                    height: "45px",
                    margin: '12px'
                }}>
                    <img className="mr-1" height="24px" width="12px" src="/images/back.png" />
                </div>
            </div>}
            {!startGame && (registerMatchMaking || gameResult) && <MatchMaking
            setGameMusicStatus={setGameMusicStatus}
                title={selectedGame.title}
                cancelMatchMaking={() => {
                    dispatch(HomeActions.CancelMatchMaking({
                        userId: user._id,
                        gameId: selectedGame.gameId
                    }));
                    exitGame(false);

                    if (selectedGame.orientation === "landscape") {
                        setViewPortSize(false)
                        // let vpc = document.getElementById("view-port-container")
                        // if (vpc) {
                        //     vpc.style.width = "600px";
                        //     vpc.style.maxWidth = "600px";
                        // }
                    }
                }
                }
                otherUser={matchData.other_user ? matchData.other_user : null}
                gameResult={gameResult}
                playAgainStatus={playAgainStatus}
                onPlayAgain={onPlayAgain}
                onPlayMoreGamesClick={onPlayMoreGamesClick}
                gameResultScores={gameResultScores}
                user={user}
                acceptGameInviteAgain={acceptGameInviteAgain}
                selectedGame={selectedGame}
                showInvitePopup={showInvitePopup}
                selectedLanguage={selectedLanguage}
            />}

            {startGame && !iframeWindowLoaded && !showGameResultImage && <div style={{ zIndex: 2 }}
                className="d-flex align-items-center justify-content-cente">
                <img src="/images/game_loader.gif" height="100px" />
            </div>}
            {showGameResultImage && <GameResultImage gameResult={gameResult} />}

            {!gameResult && <iframe scrolling="no" seamless srcDoc={iframeContentString}
                onLoad={() => { onIFrameLoad(); }}
                onError={(e) => { onIFrameLoadError(e); }}
                id="iFrame"
                ref={iframeRef}
                height="100%"
                width="100%"
                loading="eager"
                name={selectedGame.title}
                className="position-absolute"
                style={{ border: "none", zIndex: startGame ? 1 : 0 }}>
            </iframe>}

            {
                matchData.match && matchData.match._id && !gameResult &&
               // <Rotate top right>
                    <GamesSvgRadialMenu
                    gameMusicStatus={gameMusicStatus}
                    setGameMusicStatus={setGameMusicStatus}
                    sendMessageToOpponent={sendMessageToOther}
                    selectedLanguage={selectedLanguage}
                    matchId={matchData.match._id}
                    />
                // </Rotate>
                // <Menu
                //     gameMusicStatus={gameMusicStatus}
                //     setGameMusicStatus={setGameMusicStatus}
                //     sendMessageToOpponent={sendMessageToOther}
                //     selectedLanguage={selectedLanguage}
                //     matchId={matchData.match._id}
                // />
                }
            {/* {!callStarted && <>{matchData.match && matchData.match._id && !gameResult &&
                <div className="position-fixed cursor-pointer" style={{
                    ...getJoinButtomPosition(), zIndex: 2,
                }} onClick={() => {
                    SendAnalyticsToFirebase(AnalyticsEventsName.JOIN_CALL_PRESSED)
                    setJoinCall(true)
                }}>
                    <img width="50px" src="/images/call.png" />
                </div>}
                {matchData.match && matchData.match._id && !gameResult && joinCall &&
                    <div className="position-fixed cursor-pointer" style={{
                        ...getJoinButtomPosition(), zIndex: 2,
                    }} onClick={() => {
                        SendAnalyticsToFirebase(AnalyticsEventsName.JOIN_CALL_PRESSED)
                        setJoinCall(true)
                    }}>
                        <img width="50px" src="/continue_loader_1.gif" />
                    </div>}</>} */}
            {/* {
                joinCall && <div style={{ display: 'contents' }}> <JitsiMeetComponent matchId={matchData.match._id} callStarted={(call: boolean) => {
                    setCallStarted(true)
                }} isMute={isMute} setIsMute={setIsMute} /> </div>
            } */}

            {reconnecting && <GameReconnectingModal text={"RECONNECTING"} />}
            {showDeviceRotate && <RotateScreenModal text={rotationMessage} />}
            <div className="aaaaaaaa">
                {!!gameMessages.length && <GameMessageReceivedModal messages={gameMessages} />}
            </div>
        </AnimatePopup >, document.getElementById('view-port-container')!) : null
    }

    export const MultiPlayerGameBoard = React.memo(MultiPlayerGameBoardComponent)