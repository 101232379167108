// #region Local Imports
import { ActionConsts } from "@Definitions";
// #endregion Local Imports

// #region Interface Imports
import { IAction, IHomePage, GAME_ITEM, GAME_CATEGORY } from "@Interfaces";
// #endregion Interface Imports
import flattenDeep from "lodash/flattenDeep";

const INITIAL_STATE: IHomePage.IStateProps = {
    errors: [],
    userHasLowBalance: false,
    gamesList: new Array<GAME_CATEGORY>(),
    gamesListLoaded: false,
    homePageNo: 1,
    showGameBoard: false,
    gameMessages: []
};

export const HomeReducer = (
    state = INITIAL_STATE,
    action: IAction<{} & IHomePage.Actions.IMapGamesListResponse>
) => {
    switch (action.type) {
        case ActionConsts.Home.SetUserHasLowBalance: {
            return Object.assign({}, state, {
                userHasLowBalance: action.payload
            });
        }
        case ActionConsts.Home.GetGamesListError: {
            return Object.assign({}, state, {
                errors: ['Error getting game list from server']
            });
        }
        case ActionConsts.Home.GameListLoaded: {
            return Object.assign({}, state, {
                gamesListLoaded: action.payload?.gamesListLoaded,
                homePageNo: action.payload?.homePageNo
            });
        }
        case ActionConsts.Home.SetShowGameBoard: {
            return Object.assign({}, state, {
                showGameBoard: action.payload
            });
        }
        case ActionConsts.Home.SetGameMessages: {
            return Object.assign({}, state, {
                gameMessages: [action.payload, ...state.gameMessages]
            });
        }
        case ActionConsts.Home.DeleteGameMessage: {
            return Object.assign({}, state, {
                gameMessages: state.gameMessages.length <= 1 ? [] : state.gameMessages.slice(-1)
            });
        }
        // // case ActionConsts.Home.HomePageNo: {
        // //     return Object.assign({}, state, {
        // //         HomePageNo: action.payload
        // //     });
        // // }
        // case ActionConsts.Home.GetGamesListSuccess: {
        //     const { gamesList } = action.payload!;

        //     const _GAME_LIST = gamesList;
        //     // const _GAME_LIST = [gamesList[1], gamesList[gamesList.length - 1], gamesList[gamesList.length - 2]];
        //     return Object.assign({}, state, {
        //         gamesList: _GAME_LIST.reverse(),
        //         multiplayerGamesList: flattenDeep(_GAME_LIST.map((gameCategory: GAME_CATEGORY) => {
        //             return gameCategory.games.filter((game) => {
        //                 return game.multiplayer
        //             })
        //         }))
        //     });
        //     // return Object.assign({}, state, {
        //     //     gamesList: gamesList.reverse(),
        //     //     multiplayerGamesList: flattenDeep(gamesList.map((gameCategory: GAME_CATEGORY)=> {
        //     //         return gameCategory.games.filter((game) => {
        //     //             return game.multiplayer
        //     //         })
        //     //     }))
        //     // });
        // }
        default:
            return state;
    }
}
