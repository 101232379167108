export const ActionConsts = {
    Home: {
        GetRecentPlayedGamesSuccess: "GetRecentPlayedGamesSuccess",
        GetGamesListSuccess: "GetGamesListSuccess",
        GetGamesListError: "GetGamesListError",
        SetUserHasLowBalance: "SetUserHasLowBalance",
        GameListLoaded: "GameListLoaded",
        GetAppDesignGamesListSuccess: "GetAppDesignGamesListSuccess",
        GetAppDesignGamesListAppend: "GetAppDesignGamesListAppend",
        GetAppDesignGamesListError: "GetAppDesignGamesListError",
        SetShowGameBoard: "SetShowGameBoard",
        SetUserHasNoName: "SetUserHasNoName",
        SetGameMessages: "SetGameMessages",
        DeleteGameMessage: "DeleteGameMessage"
    },
    Authentication: {
        LogoutUserSuccess: "LogoutUserSuccess",
        UserLoginSuccess: "UserLoginSuccess",
        UpdateCheckStatusResponse: "UpdateCheckStatusResponse",
        UserLoginError: "UserLoginError",
        ClearLoginError: "ClearLoginError",
        SetAfterLoginRoute: "SetAfterLoginRoute",
        SetFirebaseConfigSuccess: "SetFirebaseConfigSuccess",
        SetFirebaseConfigError: "SetFirebaseConfigError",
        UpdateBlockKeysInSession: "UpdateBlockKeysInSession",
        GetUserSuccess: "GetUserSuccess",
        GetUserError: "GetUserError",
        SetUserPackageDetails: "SetUserPackageDetails",
        SetWebVersion: "SetWebVersion",
        SetUTMParams: "SetUTMParams",
        SetLogoutUser: "SetLogoutUser",
        ShowPaywall: "ShowPaywall",
        SetPaywallData: "SetPaywallData",
        SetPaywallSkipStatus:"SetPaywallSkipStatus",
        UserHasSkippedPaywall: "UserHasSkippedPaywall",
        SetSingleServiceData: "SetSingleServiceData",
        UpdateFirebaseConfig: "UpdateFirebaseConfig",
        SetVerificationConfigData: "SetVerificationConfigData",
        SetLoginPaymentMethod:"SetLoginPaymentMethod",
        SetInviRedirectionUrl:"SetInviRedirectionUrl"
    },
    Messages: {
        GetFriendsListSuccess: "GetFriendsListSuccess",
        GetFriendsListError: "GetFriendsListError",
        GetStrangerListSuccess: "GetStrangerListSuccess",
        GetStrangerListError: "GetStrangerListError",
        UpdateUnseenMessageInFotter: "UpdateUnseenMessageInFotter"
    },
    Conversation: {
        GetConversationSuccess: "GetConversationSuccess",
        GetConversationError: "GetConversationError",
        GetPlayerVsInfoSuccess: "GetPlayerVsInfoSuccess",
        GetPlayerVsInfoError: "GetPlayerVsInfoError",
        SetConversationSuccess: "SetConversationSuccess",
        UpdateConversationSuccess: "UpdateConversationSuccess",
        SetConversationOtherUser: "SetConversationOtherUser",
        ClearConversation: "ClearConversation",
        UserFirebaseConnectivityStatus: "UserFirebaseConnectivityStatus"
    },
    Invite: {
        ClearSearchUser: "ClearSearchUser",
        GetSearchUsersSuccess: "GetSearchUsersSuccess",
        GetSearchUsersError: "GetSearchUsersError",
        SetFacebookSyncedFriends: "SetFacebookSyncedFriends"
    },
    Settings: {
        SetSelectedLanguage: "SetSelectedLanguage",
        UpdateRecentlyPlayedGame: "UpdateRecentlyPlayedGame",
        UpdateGameHistorySuccess: "UpdateGameHistorySuccess",
        UpdateGameHistoryError: "UpdateGameHistoryError",
        SetTermsAndConditionModal: "SetTermsAndConditionModal",
        SetInstallPWAModal: "SetInstallPWAModal",
        UserGeoLocation: "UserGeoLocation",
        LoginParams: "LoginParams",
        SetCallFunctionalityShowed:"SetCallFunctionalityShowed"
    },
    LeaderBoard: {
        GetLeaderBoardSuccess: "GetLeaderBoardSuccess",
        GetLeaderBoardError: "GetLeaderBoardError",
    },
    ExclusiveFeatures:{
        SetExclusiveFeature:"SetExclusiveFeature"
    }
};
