import React, { useState, useEffect } from 'react';

import styles from './style.module.scss';
import { GAME_ITEM, USER_SESSION } from '@Interfaces';
import { BackgroundImage, PrimaryButton } from '@Styled';
import { theme } from "@Definitions/Styled";
import { SendAnalyticsToFirebase } from "@Services/FirebaseEvents";
import { AnalyticsEventsName } from "@Services/FirebaseEvents";
import { IStore } from "@Redux/IStore";
import { useSelector } from "react-redux";
import { MessageType } from '@Constants';
import { useTranslation } from 'react-i18next';
import { getProfileImage } from "@HelperFunctions";
export const AvatarPlaceholder: React.FunctionComponent<{ index: number }>
    = ({ index, children }) => {

        return <BackgroundImage src={[index ? "/images/orange_frame.png" : "/images/blue_frame.png"]}
            backgroundSize="cover"
            paddingBottom="114.25%">
            {children}
        </BackgroundImage>
    }
const PlayerNamePlaceholder: React.FunctionComponent<{ index: number, user: USER_SESSION | null, gameResultScores: any[] }>
    = ({ index, user, gameResultScores }) => {

        const wonCount = gameResultScores.length > 0 && user && user._id ? gameResultScores.filter((scores) => {
            return scores.userid === user._id
        })[0].won : '';

        return <div style={{
            background: index ? "url('/images/player2placeholder.png')" : "url('/images/player1placeholder.png')",
            height: "40px",
            width: "225px",
            backgroundSize: "cover",
            backgroundPosition: "center",
            top: "-20px"
        }}>
            <div className="text-start text-white titillium-semibold font-18x px-2 overflow-dotted">{user ? user.name : '...'}</div>
            <div className="text-start text-white titillium-semibold font-18x px-2 overflow-dotted">
                {gameResultScores.length > 0 && <img src="/images/win_trophy.png" className="px-2" style={{ width: "30px" }} />}
                <span className="px-2"> {wonCount}</span>
            </div>
        </div>
    }

export const MatchMaking: React.FunctionComponent<{ gameResultScores: any[], onPlayMoreGamesClick: (a: USER_SESSION) => void, playAgainStatus: string, title: string, cancelMatchMaking: () => void, user: USER_SESSION, otherUser: USER_SESSION | null, gameResult: string, onPlayAgain: () => void, acceptGameInviteAgain: (a: boolean) => void, selectedGame: GAME_ITEM, showInvitePopup: (a: any) => void, selectedLanguage?: string,setGameMusicStatus:(a:boolean)=>void }>
    = ({ gameResultScores, onPlayMoreGamesClick, playAgainStatus, title, cancelMatchMaking, user, otherUser, gameResult, onPlayAgain, acceptGameInviteAgain, selectedGame, showInvitePopup, selectedLanguage,setGameMusicStatus }) => {

        const [otherUserProfileImage, setOtherUserProfileImage] = useState("");
        const userProfileImage = getProfileImage(user); 
        const [matchFinished, setMatchFinished] = useState(false);
        const [inviteReceived, setInviteReceived] = useState(false);
        const [showJoinGameButton, setShowJoinGameButton] = useState(false);
        const [isFirst, setIsFirst] = useState(true);
        const [timeElapsed, setTimeElapsed] = useState(0);
        const invite: any = useSelector((state: IStore) => state.conversation);
        const inviteReceive = invite.inviteReceived;
        const { t } = useTranslation();
        useEffect(() => {
            if (gameResult) {
                setMatchFinished(true);

                // Analytics
                SendAnalyticsToFirebase(AnalyticsEventsName.ON_MATCH_END);
            }
        }, [gameResult]);

        useEffect(() => {
            if (otherUser) return

            setTimeout(() => {
                setOtherUserProfileImage("/images/avatar/" + Math.floor(Math.random() * 11) + ".png");
            }, 1000);
        }, [otherUserProfileImage]);

        useEffect(() => {
            if (otherUser) {
                setTimeElapsed(0);
            } else {
                setTimeout(() => {
                    setTimeElapsed(timeElapsed + 1);
                }, 1000);
            }
            if (timeElapsed > 20)
                cancelMatchMaking();
        }, [timeElapsed]);

        useEffect(() => {
            if (!otherUser) {
                setOtherUserProfileImage("/images/avatar/" + Math.floor(Math.random() * 11) + ".png");
            } else {
                setTimeElapsed(0);
                // if (otherUser.isBot) {
                setOtherUserProfileImage(getProfileImage(otherUser));
                
            }
        }, [otherUser]);
        useEffect(() => {
            if (inviteReceive && !isFirst) {
                // setInviteReceived(true);
                if (inviteReceive.type === MessageType.INVITE) {
                    let message = JSON.parse(inviteReceive.message);
                    let gameId = message._gameId;
                    if (gameId === selectedGame.gameId) {
                        setShowJoinGameButton(true)
                    }
                    if (inviteReceive.inviteAction && inviteReceive.inviteAction === "canceled") {
                        setInviteReceived(false);
                        showInvitePopup(inviteReceive);
                        setShowJoinGameButton(false)
                    }
                    if (gameId !== selectedGame.gameId) {
                        showInvitePopup(inviteReceive);
                        setShowJoinGameButton(false)

                    }
                }
            }
            else {
                setInviteReceived(false);
                setShowJoinGameButton(false)
            }
            setIsFirst(false);
        }, [inviteReceive])
        const onMoreGamesClick = () => {
            if (!otherUser) return;

            onPlayMoreGamesClick(otherUser);
        }

        return <div style={{ zIndex: 1 }} className="w-100 h-100 bg-primary d-flex flex-column justify-content-center px-3 py-3 align-items-center">
            <div className={styles.primaryToWhiteGradient + " position-relative"}
                style={{ background: "url('/images/matchmaking_background.png')", backgroundPosition: "center", backgroundSize: "cover" }}>

                {!matchFinished && <div className="d-flex align-items-center justify-content-center">
                    <div className="position-absolute" style={{
                        background: "url('/images/gametitle_banner.png')",
                        height: "40px",
                        width: "225px",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        top: "-20px"
                    }}>
                        <div className="text-center text-black titillium-semibold font-18x">{title}</div>
                    </div>
                </div>}
                {matchFinished && <div className="d-flex align-items-center justify-content-center">
                    <div className="position-absolute" style={{
                        backgroundImage: "url(/images/" + gameResult + "_" + selectedLanguage + ".png)",
                        height: gameResult === "win" ? "110px" : gameResult === "lose" ? "60px" : "60px",
                        width: "225px",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        top: gameResult === "win" ? "-75px" : "-20px"
                    }}>
                    </div>
                </div>}
                <br />
                <br />
                <br />
                <div className="d-flex align-items-center justify-content-center px-3">
                    <div className="position-relative" style={{ width: "30%" }}>

                        <BackgroundImage
                            src={[userProfileImage]}
                            backgroundSize="cover"
                            backgroundPosition="center"
                            paddingBottom="114.25%" />

                        <div className="position-absolute  h-100" style={{ top: "-1px", left: "-1px", width: "102%" }}>
                            <AvatarPlaceholder index={0} />
                        </div>
                    </div>
                    <PlayerNamePlaceholder index={0} user={user} gameResultScores={gameResultScores} />
                </div>
                <div className="d-flex align-items-center justify-content-center">
                    <img style={{ marginLeft: "40%" }} src="/images/vs_image.png" width="38px" height="80px" />
                </div>
                <div className="d-flex align-items-center justify-content-center px-3">
                    <div className="position-relative" style={{ width: "30%" }}>

                        <BackgroundImage
                            src={[otherUserProfileImage]}
                            backgroundSize="cover"
                            backgroundPosition="center"
                            paddingBottom="114.25%" />

                        <div className="position-absolute h-100" style={{ top: "-1px", left: "-1px", width: "102%" }}>
                            <AvatarPlaceholder index={1} />
                        </div>
                    </div>
                    <PlayerNamePlaceholder index={1} user={otherUser ? otherUser : null} gameResultScores={gameResultScores} />
                </div>
            </div>
            <br />
            {!gameResult && <div className="text-center font-16px text-white">
                {t("MATCHING_OPPONENT")+' : ' + timeElapsed}
            </div>}
            <br />
            {!gameResult && <img className="cursor-pointer" src="/images/cancel_matchmaking.png"
                onClick={() => {
                    cancelMatchMaking();

                    // Analytics
                    SendAnalyticsToFirebase(AnalyticsEventsName.ON_MP_WAITING_EXIT);
                }} />}

            {gameResult && <div className="d-flex flex-column" style={{ width: "360px" }}>
                <div className="d-flex align-items-center justify-content-center my-2">
                    <PrimaryButton
                        className="font-18px titillium-semibold text-white w-100 mx-2"
                        padding="8px 10px"
                        backgroundColor={theme.colors.yellow}
                        borderRadius="50px"
                        onClick={() => {
                            if (showJoinGameButton) {
                                setGameMusicStatus(true);
                                acceptGameInviteAgain(selectedGame.orientation === "landscape" ? true : false);

                            } else{
                                onPlayAgain();
                                setGameMusicStatus(true);
                            }
                                

                            // Analytics
                            SendAnalyticsToFirebase(AnalyticsEventsName.ON_REMATCH);
                        }}>
                        {playAgainStatus ? playAgainStatus : (showJoinGameButton ? t("JOIN") : t("PLAY_AGAIN"))}
                    </PrimaryButton>
                    <PrimaryButton
                        className="font-18px titillium-semibold text-black w-100 "
                        padding="8px 10px"
                        backgroundColor={theme.colors.grey600}
                        borderRadius="50px"
                        onClick={() => { onMoreGamesClick(); }}>
                        {t("PLAY_MORE_GAMES")}
                    </PrimaryButton>
                </div>
                <br />
                <div className="d-flex align-items-center justify-content-between my-2">
                    {[1, 2, 3, 4, 5, 6].map((n, i) => {
                        return <img key={i} src={"/images/emoji_" + n + ".png"} height="42px" width="42px" />
                    })}
                </div>
            </div>}
        </div>
    }