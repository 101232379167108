import React, { useState, useEffect } from "react";
import {useSelector } from "react-redux";
import { IStore } from "@Interfaces";
// import ProgressComponent from '@material-ui/core/CircularProgress';
import { importJitsiApi } from "@HelperFunctions";
import {
  SendAnalyticsToFirebase,
  AnalyticsEventsName,
} from "@Services/FirebaseEvents";
import { GenericPopupModal } from "@Components/Modals/GenericPopupModal";
import { useTranslation } from "react-i18next";


interface JitsiMeetComponentProps {
  matchId: any;
  callStarted: (a: boolean) => void,
  isMute: boolean
  setIsMute: (a: boolean) => void
}

export const JitsiMeetComponent: React.FC<JitsiMeetComponentProps> = (
  props
) => {
  const [loading, setLoading] = useState(true);
  const [jitsiApiRefrence, setJitsiApiRefrence] = useState(null);
  const [isMeetJoined, setIsMeetJoined] = useState(false);
  const [particpents, setParticepents] = useState([]);
  const [silentSpeaker, setSilentSpeaker] = useState(false);
  const [showMicPopup, setShowMicPopup] = useState(false);
  const persistState = useSelector((state: IStore) => state.persistState);
  const {session } =persistState;
  const userId=session._id;
  console.log()
  const { t } = useTranslation();

  const containerStyle = {
    width: "0px",
    height: "0px",
  };

  const jitsiContainerStyle = {
    display: loading ? "none" : "contents",
    width: "0%",
    height: "0%",
  };

  function startConference() {
    try {
      const domain = "voip.gago.games";
      const options = {
        // roomName: "test1",
        roomName: props.matchId,
        height: 0,
        parentNode: document.getElementById("jitsi-container"),
        interfaceConfigOverwrite: {
          filmStripOnly: false,
          SHOW_JITSI_WATERMARK: false,
          startAudioOnly: true,
        },
        configOverwrite: {
          disableSimulcast: false,
          startAudioOnly: true,
          startWithAudioMuted: false,
          disableDeepLinking: true,
          enableP2P: false
        },
      };

      const api = new window.JitsiMeetExternalAPI(domain, options);
      setJitsiApiRefrence(api);
      console.log(api);
      checkAvailableDevices(api);
      api.addEventListener("videoConferenceJoined", () => {
        console.log("Local User Joined");
        setLoading(false);
        props.callStarted(true);
        api.executeCommand("displayName", userId);
        setIsMeetJoined(true);
        setParticepents(api.getParticipantsInfo());
        SendAnalyticsToFirebase(AnalyticsEventsName.CALL_JOINED);
      });

      api.addEventListener("incomingMessage", incommingMessagesListner);
      api.addEventListener("outgoingMessage", outgoingMessagesListner);
      api.addEventListener(
        "endpointTextMessageReceived",
        endPointTextMessageReceivedListner
      );
    } catch (error) {
      console.error("Failed to load Jitsi API", error);
    }
  }
  const checkAvailableDevices = async (api: any) => {
    let devices = await api.getAvailableDevices();
    console.log("Devices:  ", devices);
  };
  //
  useEffect(() => {
    console.log("LOADERRRRR", isMeetJoined);
    
    // verify the JitsiMeetExternalAPI constructor is added to the global..
    importJitsiApi()
      .then((jitsiApi) => {
        console.log(jitsiApi);
        console.log("jitsi meet start call");
        startConference();
      })
      .catch((err) => {
        console.error("Jitsi Meet API library not loaded.", err);
      });
    // if (window.JitsiMeetExternalAPI) startConference();
    // else alert("Jitsi Meet API script not loaded");
  }, []);
  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((media) => {
        executeJitsiCommand("toggleAudio");
        if (props.isMute)
          SendAnalyticsToFirebase(AnalyticsEventsName.UNMUTE_AUDIO);
        else
          SendAnalyticsToFirebase(AnalyticsEventsName.MUTE_AUDIO);
        //props.setIsMute(!props.isMute);
      })
      .catch((err) => {
        // props.setIsMute(true);
        setShowMicPopup(true);
        console.log("User does not given the permission", err);
      });
    // return () => { console.log("hangup"); executeJitsiCommand("hangup"); }
  }, [props.isMute])

  const executeJitsiCommand = (command: string) => {
    if (jitsiApiRefrence !== null) {
      // @ts-ignore  adding this comment to ignore jitsi null error. As it is checked already in if statement
      jitsiApiRefrence.executeCommand(command);
    }
  };

  const sendTextMessage = () => {
    if (jitsiApiRefrence !== null) {
      // @ts-ignore
      console.log("MEET Particepants", jitsiApiRefrence.getParticipantsInfo());
      // @ts-ignore
      let otherUser = jitsiApiRefrence
        .getParticipantsInfo()
        .filter((data: any) => {
          return data.formattedDisplayName != "Portal (me)";
        });
      console.log("Other User is", otherUser);
      if (otherUser.length > 0) {
        // @ts-ignore
        jitsiApiRefrence.executeCommand("sendEndpointTextMessage", "", "text");
      }
    }
  };
  const incommingMessagesListner = (data: any) => {
    console.log("INCOMMING MESSAGES", data);
  };
  const outgoingMessagesListner = (data: any) => {
    console.log("OUTGOING MESSAGES", data);
  };
  const endPointTextMessageReceivedListner = (data: any) => {
    console.log("endpointTextMessage", data);
  };
  const test = (start: boolean) => {
    if (jitsiApiRefrence !== null) {
      setSilentSpeaker(start);
      // @ts-ignore  adding this comment to ignore jitsi null error. As it is checked already in if statement
      jitsiApiRefrence.executeCommand("overwriteConfig", {
        startSilent: start,
      });
    }
  };

  return (
    <div id="test" style={{ position: "absolute" }}>
      <div id="jitsi-container" style={jitsiContainerStyle} />
      {showMicPopup && (
        <GenericPopupModal
          title={t("PERMISSION_DENIED_HEADER")}
          description={t("PERMISSION_DENIED_DESCRIPTION")}
          showInfoIcon={true}
          firstButtonText={t("OK")}
          secondButtonText={t("CANCEL")}
          firstButtonCallback={() => {
            setShowMicPopup(false);
          }}
          secondButtonCallback={() => {
            setShowMicPopup(false);
          }}
        />
      )}
    </div>
  );
};

export default JitsiMeetComponent;
