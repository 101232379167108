import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from 'react-i18next';

import { AnimatePopup, ModalContainer, ParagraphText } from "@Styled";

export const WarningModal: React.FunctionComponent<{ onYesClick: () => void, onNoClick: () => void, warningTextElem: React.ReactNode, width?: string }>
    = ({ onYesClick, onNoClick, warningTextElem, width }) => {

        const viewPort = document.getElementById('view-port-container');
        const { t } = useTranslation();

        return ReactDOM.createPortal(<AnimatePopup animateIn={true} background="rgb(0,0,0,0.7)" zIndex={3}>
            <ModalContainer headerChild={<img src="/images/warning.png" />} width={width}>
                <div className="d-flex flex-column" style={{ marginTop: "15px" }}>
                    {warningTextElem}
                    <div className="d-flex align-items-center justify-content-center text-white">
                        <div style={{ borderRadius: "27px", width: "70px", height: "32px" }}
                            className="cursor-pointer bg-green d-flex align-items-center justify-content-center"
                            onClick={() => { onYesClick(); }}>
                            {t('YES')}
                        </div>
                        <div className="cursor-pointer d-flex align-items-center justify-content-center text-grey400"
                            style={{ borderRadius: "27px", width: "70px", height: "32px" }}
                            onClick={() => { onNoClick(); }}>
                            {t('NO')}
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </AnimatePopup>, viewPort ? viewPort : document.body)
    }