import React from 'react';
import { BackgroundImage } from "@Styled";
import { useTranslation } from 'react-i18next';

export const GameResultImage: React.FunctionComponent<{ gameResult: string }>
    = ({ gameResult }) => {
        const { t } = useTranslation();
        return <div className="d-flex py-3 px-3 w-100 h-100">
            {gameResult === 'win' && <BackgroundImage src={["/images/win_2.png", "/images/message_image_placeholder.png"]}
                paddingBottom="54.25%"
                backgroundPosition="center"
                backgroundSize="contain" />}
            {gameResult === 'draw' && <BackgroundImage src={["/images/draw_2.png", "/images/message_image_placeholder.png"]}
                paddingBottom="54.25%"
                backgroundPosition="center"
                backgroundSize="contain" />}
            {gameResult === 'lose' && <BackgroundImage src={["/images/lose_2.png", "/images/message_image_placeholder.png"]}
                paddingBottom="54.25%"
                backgroundPosition="center"
                backgroundSize="contain" />}
            <div className="d-flex justify-content-center align-items-center position-absolute" style={{ zIndex: -1, fontSize: "2em", color: "white", top: "0", bottom: "0", left: "0", right: "0" }}>
                {
                    gameResult === "win" ?
                        t("You_Win") :
                        gameResult === "lose" ?
                            t("You_Lose") :
                            gameResult === "draw" ?
                                t("Draw") : ""
                }
            </div>
        </div>
    }