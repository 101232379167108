// #region Local Imports
import { Http } from "@Services/API/Http";
// #endregion Local Imports

// #region Interface Imports
import { ConversationModel, UploadMediaFilesModel } from "@Interfaces";
import { getQueryParams } from "@Services/Cookie";
// #endregion Interface Imports

const apiBaseUrl = process.env.REACT_APP_IS_PRODUCTION_API === "true" ? process.env.REACT_APP_API_URL : process.env.REACT_APP_STAGING_API_URL;

export const ConversationService = {
    GetConversation: async (
        payload: ConversationModel.GetGETConversationPayload
    ): Promise<ConversationModel.GetGETConversationResponse> => {
        let response: ConversationModel.GetGETConversationResponse;

        const { userId, ...rest } = payload;
        try {
            response = await Http.Request<ConversationModel.GetGETConversationResponse>(
                "GET",
                "/api/conversation/user/" + userId + getQueryParams({ ...rest }),
                undefined
            );
        } catch (error) {
            response = {
                status: false,
                response: [{
                    text: "Hahhshs",
                    id: 0
                }, {
                    text: "Hahhshs Ohoooo",
                    id: 1
                }, {
                    text: "Hahhshs kskksk",
                    id: 2
                }]
            }
        }
        return response;
    },
    UploadMediaOnStorage: async (
        payload: UploadMediaFilesModel.GetUploadMediaFilesPayload
    ): Promise<UploadMediaFilesModel.GetUploadMediaFilesResponse> => {
        let response: UploadMediaFilesModel.GetUploadMediaFilesResponse;
        try {
            const { formData, apiPath } = payload;
            response = await Http.UploadFile<UploadMediaFilesModel.GetUploadMediaFilesResponse>(
                "POST",
                apiBaseUrl + (apiPath ? apiPath : "/chat/media"),
                undefined,
                formData
            );
        } catch (error) {
            response = {
                message: "Media Successfully uploaded..",
                messageId: "1603822243",
                response: "5f8976d9f3456267bfc2c221_bad.jpg",
                success: false
            };
        }
        return response;
    },
    FetchEmojis: async (): Promise<any> => {
        let response: any;
        try {
            response = await Http.RequestWithoutToken<any>(
                "GET",
                "https://api.giphy.com/v1/gifs/trending?api_key=0dK98w2yPTpmQZTsve0kofgTVptst9zP&limit=20",
                undefined
            );
            // response = await fetch("http://api.giphy.com/v1/gifs/trending?api_key=0dK98w2yPTpmQZTsve0kofgTVptst9zP&limit=20")
            // response = await response.json();
        } catch (error) {
            response = {
                message: "Media Successfully uploaded..",
                messageId: "1603822243",
                response: "5f8976d9f3456267bfc2c221_bad.jpg",
                success: false,
                data: []
            };
        }
        return response;
    },
    GetPlayerVSInfo: async (
        payload: ConversationModel.GetPOSTPlayerVSInfoPayload
    ): Promise<ConversationModel.GetPOSTPlayerVSInfoResponse> => {
        let response: ConversationModel.GetPOSTPlayerVSInfoResponse;
        try {
            response = await Http.Request<ConversationModel.GetPOSTPlayerVSInfoResponse>(
                "POST",
                apiBaseUrl + "/getplvsplinfo/",
                undefined,
                { ...payload }
            );
        } catch (error) {
            response = {
                message: "",
                success: false,
                response: {
                    createdAt: "",
                    roomId: "",
                    users: [],
                    _id: ""
                }
            };
        }
        return response;
    },
    GetOtherUser: async (
        payload: string
    ): Promise<any> => {
        let response: any;
        try {
            response = await Http.Request<any>(
                "GET",
                apiBaseUrl + "/user/" + payload,
                undefined
            );
        } catch (error) {
            response = {
                message: "",
                success: false,
                response: {
                    createdAt: "",
                    roomId: "",
                    users: [],
                    _id: ""
                }
            };
        }
        return response;
    },
    SendFirebaseOfflineMessage: async (
        payload: ConversationModel.GetPOSTSendFirebaseOfflineMessagePayload
    ): Promise<ConversationModel.GetPOSTSendFirebaseOfflineMessageResponse> => {
        let response: ConversationModel.GetPOSTSendFirebaseOfflineMessageResponse;
        try {
            response = await Http.Request<ConversationModel.GetPOSTSendFirebaseOfflineMessageResponse>(
                "POST",
                apiBaseUrl + "/notification/notification",
                undefined,
                { ...payload }
            );
        } catch (error) {
            response = {
                message: "",
                success: false
            };
        }
        return response;
    },
}