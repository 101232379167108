import React, {useState, useEffect} from 'react';

import {GAME_HISTORY, IStore} from '@Interfaces';
import {IGameItem} from './IGameItem';
import styles from '../../Containers/Home/style.module.scss';
import {LazyImage, ShimmerContainer, GradientLoadingRing, BackgroundImage} from '@Styled';
import {InviteStatusEnums, InviteActionsEnum} from '@Constants';
import {useTranslation} from 'react-i18next';
import {getServerTimeOffset} from "@Services/FirebaseEvents";
import {useSelector} from "react-redux";

export const GameItem: React.FunctionComponent<IGameItem.IProps>
  = ({
       timeStamp,
       isSearchItem,
       mesiboMessage,
       game,
       onGameClick,
       loading,
       isGameInvite,
       onGameAccept,
       conversationMessage,
       isMessageRecieved,
       selectedLanguage,
       showGameItemBackground,
     }) => {

  const [timeLeft, setTimeLeft] = useState(0);
  const [hasInviteClicked, setHasInviteClicked] = useState(false);
  const {t} = useTranslation();
  const [offset, setOffset] = useState<number>();
  const [numOfPlayers, setNumOfPlayers] = useState(Math.floor(Math.random() * 1500) + 500,);
  const {recentlyPlayedGames} = useSelector((state: IStore) => state.persistState);
  useEffect(() => {
    (async function () {
      if (!offset && isGameInvite)
        setOffset((await getServerTimeOffset()) as number);
    })()
  }, [])

  useEffect(() => {
    if (isGameInvite && timeStamp && offset) {
      // const timer = Math.floor((new Date().valueOf() - timeStamp) / 1000);
      const timer = Math.floor((timeStamp - (new Date().valueOf() + offset)) / 1000);
      if (timer <= 30) {
        setTimeLeft(timer);
      }
    }
  }, [offset]);

  useEffect(() => {
    //  if (gameData.isMultiplayer()) addBackgroundGameDownloadListner();
    const interval = setInterval(() => {
      setNumOfPlayers(Math.floor(Math.random() * 1500) + 500);
    }, Math.random() * 10000 + 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    if (isGameInvite && timeLeft > 0) {
      if (conversationMessage.inviteActions !== InviteActionsEnum.DEFAULT) {
        setTimeLeft(0);
        return;
      }
      const timer = setTimeout(() => {
        if (timeLeft > 0) setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [timeLeft, isGameInvite]);
    const getMedals = (gameId: number) => {
        let game = recentlyPlayedGames.filter((game: GAME_HISTORY) => game.gameId == gameId);
        if (game.length) {
            return game[0].win;
        }
        return 0;
    };
    const medals = getMedals(game.gameId);

  return <div className={"position-relative d-flex cursor-pointer " + styles.flex1by3}
              style={{marginTop: "2rem"}}
              onClick={() => {
                onGameClick(game);
              }}>

    <div className={isGameInvite ? styles.lightPurpleToPurpleGradient : styles.tiltedPurpleToWhiteGradient}
         style={{width: isSearchItem || isGameInvite ? "100%" : "90%"}}>

    </div>

    <div className={"position-absolute d-flex flex-column align-items-center text-center " + styles.containerTop}
         style={{width: isSearchItem || isGameInvite ? "100%" : "90%"}}>

      <div style={{width: "75%"}}>
        <div style={{
          borderRadius: "6px",
          width: "100%",
        }}>
          <div style={{opacity: isGameInvite ? "0.6" : "1"}}>
            {isGameInvite && timeLeft > 0 && timeLeft <= 30 && !hasInviteClicked
              &&
              (conversationMessage.inviteActions === InviteActionsEnum.DEFAULT
                //     || (!isMessageRecieved && conversationMessage.inviteActions === InviteActionsEnum.ACCEPTED)
              ) &&
              <div className="position-absolute"
                   style={{zIndex: 1, width: "80%", top: "10px"}}>

                <div className="d-flex align-items-center justify-content-center">
                  <GradientLoadingRing height="64px" width="64px">
                    <span className="text-white titillium-semibold">{timeLeft}</span>
                  </GradientLoadingRing>
                </div>
              </div>}
            {game.premiumTagAr && <img className="premium-game"
                                       src={`${selectedLanguage === "ar" ? game.premiumTagAr : game.premiumTagEn}`}/>}
            {/* {game.multiplayer?<img className="premium-game" src={`${selectedLanguage === 'ar' ? game.premiumTagAr : game.premiumTagEn}`} /> :
                            game.isPremium? <img className="premium-game"  src={`${selectedLanguage === 'ar' ? game.premiumTagAr : game.premiumTagEn}`} /> : ""} */}
            <LazyImage
              src={game.gifUrl && game.gifUrl !== "false" ? game.gifUrl : game.thumbnail}
              alt={game.title}
              height="100%"
              borderRadius="15px"
              width="100%"/>
          </div>
          {isGameInvite && timeLeft <= 0 && <div className="text-white mt-2 position-absolute font-weight-bold" style={{
            top: "80%",
            left: 0,
            right: 0
          }}>{game.title ? game.title.toUpperCase() : ""}</div>}
        </div>
      </div>
      {isGameInvite && conversationMessage && timeLeft <= 0 && conversationMessage.inviteActions === InviteActionsEnum.DEFAULT ?
        <div
          style={{position: "absolute", bottom: "40%"}}>
          <img src="/images/invite_expire.png" style={{width: "8rem"}}/>
        </div> :
        isGameInvite && conversationMessage && conversationMessage.inviteActions === InviteActionsEnum.DEFAULT ?
          <div className="mt-2 d-flex align-items-center justify-content-center cursor-pointer ttttttt">
            {isMessageRecieved && <div onClick={() => {
              onGameAccept && onGameAccept(mesiboMessage, conversationMessage, true, false);
              setHasInviteClicked(true);
            }} style={{borderRadius: "4px", minWidth: "55px"}}
                                       className="text-center text-primary bg-yellow200 px-3 py-2">{t("JOIN_CAMELCASE")}</div>}
            {isMessageRecieved && <div onClick={() => {
              onGameAccept && onGameAccept(mesiboMessage, conversationMessage, false, false);
              setHasInviteClicked(true);
            }} style={{background: "rgba(173, 114, 239, 0.9)", borderRadius: "4px", minWidth: "55px"}}
                                       className="text-center ml-2 text-white px-3 py-2">{t("DECLINE")}</div>}

            {!isMessageRecieved &&
              <div className="mt-2 d-flex align-items-center justify-content-center cursor-pointer">
                {/* <div style={{ borderRadius: "4px", minWidth: "55px" }}
                                    className="text-center text-primary bg-yellow200 px-3 py-2">
                                    Sent
                        </div> */}
                <div onClick={() => {
                  onGameAccept && onGameAccept(mesiboMessage, conversationMessage, false, true);
                  setHasInviteClicked(true);
                }}
                     style={{background: "rgba(173, 114, 239, 0.9)", borderRadius: "4px", minWidth: "55px"}}
                     className="text-center ml-2 text-white px-3 py-2">
                  {t("CANCEL")}
                </div>
              </div>}
          </div> :
          isGameInvite && conversationMessage.inviteActions === InviteActionsEnum.ACCEPTED ?
            <div style={{position: "absolute", bottom: "40%"}}>
              <img src="/images/invite_accepted.png" style={{width: "6rem"}}/>
            </div>
            : isGameInvite && conversationMessage.inviteActions === InviteActionsEnum.REJECTED ? <div
                style={{position: "absolute", bottom: "40%"}}>
                <img src="/images/invite_rejected.png" style={{width: "8rem"}}/>
              </div>
              :
              isGameInvite && conversationMessage.inviteActions === InviteActionsEnum.CANCELED ?
                <div style={{position: "absolute", bottom: "40%"}}>
                  <img src="/images/invite_cancel.png" style={{width: "6rem"}}/>
                </div>
                :
                <div>
                  <div className="text-white mt-2 font-weight-bold" style={{}}>{game.title}</div>
                  <div className="text-white mt-2 d-flex" style={{}}>
                    <div className={`pr-2 font-12px ${styles.playersStyle}`}>{numOfPlayers} {t("PLAYERS")}</div>
                    <div style={{width: '80px', position: 'relative'}}><img src="/images/medal-with-bg.png" alt="" width="60"/>
                      <span className={`font-11px ${styles.medalStyle}`}>{medals}</span>
                    </div>
                  </div>
                </div>

      }
    </div>
  </div>
}

export const GameItemPlaceholder: React.FunctionComponent = () => {
  return <div className={"position-relative d-flex cursor-pointer " + styles.flex1by3}
              style={{marginTop: "2rem"}}>

    <div className={styles.tiltedPurpleToWhiteGradient}
         style={{width: "90%"}}></div>

    <div className={"position-absolute d-flex flex-column align-items-center text-center " + styles.containerTop}
         style={{width: "90%"}}>

      <div style={{
        borderRadius: "6px",
        height: "100%",
        width: "80%"
      }}>

        <LazyImage
          src={""}
          alt={""}
          height="100%"
          borderRadius="15px"
          width="100%"/>
      </div>
      <div className="text-white mt-2" style={{minHeight: "20px", minWidth: "50px"}}>
        <ShimmerContainer/>
      </div>
      <div className="text-white font-8px"></div>
    </div>
  </div>
}