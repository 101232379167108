// #region Local Imports
import { Http } from "@Services/API/Http";
// #endregion Local Imports

// #region Interface Imports
import {
    AuthenticationModel, FacebookGraphLoginPayload,
    FacebookGraphLoginResponse, GETGeoLocationFromIpResponse, HttpModel
} from "@Interfaces";
import { userErrorDummyResponse } from "@Constants";
import { VerificationScreen } from "../VerificationScreens/VerificationScreen";
import i18n from '../../../Languages';
// #endregion Interface Imports

const apiBaseUrl = process.env.REACT_APP_IS_PRODUCTION_API === "true" ? process.env.REACT_APP_API_URL : process.env.REACT_APP_STAGING_API_URL;
const apiBaseUrlV4 = process.env.REACT_APP_IS_PRODUCTION_API === "true" ? process.env.REACT_APP_API_URL_V4 : process.env.REACT_APP_STAGING_API_URL_V4;
const apiBaseUrlPort = process.env.REACT_APP_IS_PRODUCTION_API === "true" ? process.env.REACT_APP_SUBSCRIPTION_API_URL : process.env.REACT_APP_SUBSCRIPTION_API_URL_STAGING;
const mapSubscriptionResponse: any = (apiResponse: AuthenticationModel.GetGETSendPinResponse) => {
    const subscriptionServerResponse = [{
        code: 103,
        message: i18n.t("INVALID_OTP"),
    }, {
        code: 104,
        message: i18n.t("PLEASE_RESEND_A_NEW_PIN_CODE")
    }, {
        code: 108,
        message: i18n.t("PLEASE_WAIT_FOR_2_MINUTES_BEFORE_RESENDING_PIN_CODE")
    }, {
        code: 110,
        message: i18n.t("PLEASE_RECHARGE_YOUR_CREDIT_BEFORE_TRYING_AGAIN")
    }, {
        code: 112,
        message: i18n.t("YOUR_NUMBER_HAVE_NO_VALID_SUBSCRIPTION")
    }, {
        code: 107,
        message: i18n.t("PLEASE_ENTER_CORRECT_MOBILE_NUMBER")
    }, {
        code: 105,
        message: i18n.t("PLEASE_TRY_AGAIN")
    }, {
        code: 101,
        message: i18n.t("TROUBLE_CONNECTING_WITH_SERVER_PLEASE_TRY_AGAIN")
    }, {
        code: 100,
        message: i18n.t("CANNOT_AUTHORIZE_YOUR_ACCOUNT_PLEASE_TRY_AGAIN")
    }, {
        code: 106,
        message: i18n.t("PLEASE_SELECT_A_CARRIER")
    }];

    const a = subscriptionServerResponse.filter((cR, i) => {
        return cR.code === apiResponse.response_code
    });

    return {
        success: apiResponse.success,
        message: a.length ? a[0].message : apiResponse.message,
        response_code: apiResponse.response_code,
        header: apiResponse.header
    };
}

export const AuthenticationService = {
    Login: async (
        payload: AuthenticationModel.GetGETLoginPayload
    ): Promise<AuthenticationModel.GetGETLoginResponse> => {
        let response: AuthenticationModel.GetGETLoginResponse;

        try {
            response = await Http.Request<AuthenticationModel.GetGETLoginResponse>(
                "POST",
                apiBaseUrl + "/user",
                undefined,
                { ...payload, appVersion: process.env.REACT_APP_MOBILE_VERSION } as any
            );
        } catch (error) {
            response = {
                status: false,
                data: userErrorDummyResponse,
                error: 'Error occured logging in.'
            }
        }
        return response;
    },
    SendPin: async (
        payload: AuthenticationModel.GetGETSendPinPayload, selectedLanguage: string
    ): Promise<AuthenticationModel.GetGETSendPinResponse> => {
        let response: AuthenticationModel.GetGETSendPinResponse;
        // payload.serviceId = 2;
        let params: any = Object.assign({}, payload)
        try {
            response = await Http.RequestWithToken<AuthenticationModel.GetGETSendPinResponse>(
                "POST",
                (process.env.REACT_APP_IS_PRODUCTION_API === "true" ? process.env.REACT_APP_SUBSCRIPTION_API_URL :
                    process.env.REACT_APP_SUBSCRIPTION_API_URL_STAGING
                ) + "/v2/genAuthCode",
                { language: selectedLanguage?.toUpperCase() },
                { ...params }
            );
        } catch (error) {
            response = {
                message: "",
                response_code: 900,
                success: false
            }
        }
        return response;
    },
    ConfirmPin: async (
        payload: AuthenticationModel.GetGETConfirmPinPayload, selectedLanguage: string
    ): Promise<AuthenticationModel.GetGETConfirmPinResponse> => {
        let response: AuthenticationModel.GetGETConfirmPinResponse;
        // payload.serviceId = 2;
        try {
            response = await Http.RequestWithToken<AuthenticationModel.GetGETConfirmPinResponse>(
                "POST",
                (process.env.REACT_APP_IS_PRODUCTION_API === "true" ? process.env.REACT_APP_SUBSCRIPTION_API_URL :
                    process.env.REACT_APP_SUBSCRIPTION_API_URL_STAGING
                ) + "/v1/validateAuthCode?language=" + selectedLanguage?.toUpperCase(),
                undefined,
                { ...payload }
            );
        } catch (error) {
            response = {
                message: "Something went wrong",
                response_code: 900,
                success: false
            }
        }
        return response;
    },
    getInwiRedirectionUrl: async (
        payload: {serviceId:number}
    ): Promise<AuthenticationModel.GetGETInviRedirectionResponse> => {
        let response: AuthenticationModel.GetGETInviRedirectionResponse;
        console.log("payload***",payload)
        let params: any = Object.assign({}, payload)
        try {
            response = await Http.RequestWithToken<AuthenticationModel.GetGETInviRedirectionResponse>(
                "GET",
                (process.env.REACT_APP_IS_PRODUCTION_API === "true" ? process.env.REACT_APP_SUBSCRIPTION_API_URL :
                    process.env.REACT_APP_SUBSCRIPTION_API_URL_STAGING
                ) + "/v1/getInwiRedirectionUrl",
                { ...params }
            );
        } catch (error) {
            response = {
                message: "",
                response_code: 900,
                success: false
            }
        }
        return response;
    },
    UtmApi: async (
        payload: AuthenticationModel.GetGETUTMPayload
    ): Promise<AuthenticationModel.GetGETUTMResponse> => {
        let response: AuthenticationModel.GetGETUTMResponse;
        // payload.serviceId = 2;
        // {
        //     event_name: eventType,
        //     compaign_id: compaign_id,
        //     tracking_code: tracking_code,
        //     compaign_name: compaign_name,
        //     medium: medium,
        //   }
        try {
            let url = "https://k8s.cricwick.net/khaleef-attribution/api/v1/attributions/mark_event"
            console.log("UTM URL", url)
            let params = {
                "event_name" : payload.event_name,
                "compaign_id" : payload.compaign_id,
                "tracking_code" : payload.tracking_code,
                "compaign_name" : payload.compaign_name,
                "medium" : payload.medium
            }
            response = await Http.Request<AuthenticationModel.GetGETUTMResponse>(
                "GET",
                url,
                params,
            );
        } catch (error) {
            response = {
                message: "error message in utm hitting"
            }
        }
        console.log("UTM RESPONSE", response)
        return response;
    },
    FacebookGraphLogin: async (
        payload: FacebookGraphLoginPayload
    ): Promise<FacebookGraphLoginResponse> => {
        let response: FacebookGraphLoginResponse;

        try {
            response = await Http.Request<FacebookGraphLoginResponse>(
                "GET",
                "https://graph.facebook.com/v5.0/" + payload.userId + "?fields=email,name,friends&access_token=" + payload.access_token,
                // "https://graph.facebook.com/v5.0/me/?fields=email,name,friends&access_token=" + payload,
                undefined
            );
        } catch (error) {
            response = {
                email: "",
                friends: {
                    data: [],
                    summary: { total_count: 0 }
                },
                id: "",
                name: ""
            }
        }
        return response;
    },
    PatchUser: async (
        payload: AuthenticationModel.GetPATCHUserPayload
    ): Promise<AuthenticationModel.GetPATCHUserResponse> => {
        let response: AuthenticationModel.GetPATCHUserResponse;
        try {
            response = await Http.Request<AuthenticationModel.GetPATCHUserResponse>(
                "POST",
                apiBaseUrlV4 + "/user",
                undefined,
                { ...payload, appVersion: process.env.REACT_APP_MOBILE_VERSION } as any
            );
        } catch (error) {
            console.log("Patch user error: ", error)
            response = {
                message: "",
                response: userErrorDummyResponse,
                success: false
            }
        }
        return response;
    },
    GetFirebaseConfiguration: async (
        payload: AuthenticationModel.GetGETFirebaseAppConfigurationPayload
    ): Promise<AuthenticationModel.GetGETFirebaseAppConfigurationResponse> => {
        let response: AuthenticationModel.GetGETFirebaseAppConfigurationResponse;

        try {
            response = await Http.Request<AuthenticationModel.GetGETFirebaseAppConfigurationResponse>(
                "POST",
                (process.env.REACT_APP_IS_PRODUCTION_API === "true" ?
                    process.env.REACT_APP_FIREBASE_CONFIG_PRODUCTION_API :
                    (window.location.hostname === "localhost" ? "https://us-central1-gagi-stagging.cloudfunctions.net/configTest" : process.env.REACT_APP_FIREBASE_CONFIG_STAGING_API)) + '',
                undefined,
                { ...payload }
            );
        } catch (error) {
            response = {
                response: {},
                success: false,
                message: ''
            }
        }
        return response;
    },
    GetSubscriptionStatus: async (
        payload: AuthenticationModel.GetGETSubscriptionStatusPayload
    ): Promise<AuthenticationModel.GetGETSubscriptionStatusResponse> => {
        let response: AuthenticationModel.GetGETSubscriptionStatusResponse;
        // payload.serviceId = 2;
        try {
            response = await Http.RequestWithToken<AuthenticationModel.GetGETSubscriptionStatusResponse>(
                "GET",
                apiBaseUrlPort + "/v1/checkSubscriptionStatus",
                payload as HttpModel.IRequestQueryPayload,
                //+ '&serviceId=' + payload.serviceId,
                // process.env.REACT_APP_SUBSCRIPTION_API_URL + "/v1/checkSubscriptionStatus?msisdn=" + payload.msisdn + '&serviceId=' + 2,
            );
        } catch (error) {
            response = {
                message: "Something went wrong",
                response_code: -1,
                success: false
            }
        }
        return response;
    },
    GetGeoLocationFromIp: async (): Promise<GETGeoLocationFromIpResponse> => {
        let response: GETGeoLocationFromIpResponse

        try {
            response = await Http.Request<GETGeoLocationFromIpResponse>(
                "GET",
                "https://webapi.cricingif.com/api/users/country",
                undefined
            );
        } catch (error) {
            response = {
                "country_code": "PK",
                "country_name": "Pakistan",
                "city": "Karachi",
                "postal": "12311",
                "latitude": 24.9056,
                "longitude": 67.0822,
                "IPv4": "111.119.187.19",
                "state": "Sindh"
            }
        }
        return response;
    },
    UnSubscribe: async (
        payload: AuthenticationModel.GetPOSTUnSubPayload
    ): Promise<AuthenticationModel.GetPOSTUnSubResponse> => {
        let response: AuthenticationModel.GetPOSTUnSubResponse
        // payload.serviceId = 2;
        try {
            response = await Http.RequestWithToken<AuthenticationModel.GetGETSendPinResponse>(
                "POST",
                apiBaseUrlPort + "/v1/unsubSubscription",
                undefined,
                { ...payload }
            );
        } catch (error) {
            response = {
                message: "",
                response_code: 900,
                success: false
            }
        }
        return mapSubscriptionResponse(response);
    },
    UnBlockUser: async (
        payload: AuthenticationModel.GetPATCHUnblockUserPayload
    ): Promise<AuthenticationModel.GetPATCHUnblockUserResponse> => {
        let response: AuthenticationModel.GetPATCHUnblockUserResponse
        // payload.serviceId = 2;
        try {
            response = await Http.Request<AuthenticationModel.GetPATCHUnblockUserResponse>(
                "PATCH",
                apiBaseUrl + "/user/" + payload.userId + '/unblock/' + payload.otherUserId,
                undefined
            );
        } catch (error) {
            response = {
                message: '',
                response: [],
                success: false
            }
        }
        return response;
    },
    GetUser: async (
        id: string,
        configVersion: string
    ): Promise<AuthenticationModel.GetPATCHUserResponse> => {
        let response: AuthenticationModel.GetPATCHUserResponse;

        try {
            response = await Http.Request<AuthenticationModel.GetPATCHUserResponse>(
                "GET",
                apiBaseUrl + "/user/" + id + "/",
                {configVersion: configVersion}
            );
        } catch (error) {
            response = {
                message: "",
                response: userErrorDummyResponse,
                success: false
            }
        }
        return response;
    },
    GetUserInterests: async (
    ): Promise<any> => {
        let response: any;

        try {
            response = await Http.Request<any>(
                "GET",
                apiBaseUrl + "/getInterests",
                undefined
            );
        } catch (error) {
            response = {
                message: "",
                response: {

                },
                success: false
            }
        }
        return response;
    },
    postURLParams: async (
        payload: any
    ): Promise<any> => {
        let response: any
        // payload.serviceId = 2;
        try {
            response = await Http.Request<any>(
                "POST",
                apiBaseUrl + "/trafficUrls/add",
                undefined,
                { ...payload }
            );
        } catch (error) {
            response = {
                message: "Something went wrong",
                response_code: -100,
                success: false
            }
        }
        return response;
    },
    GetPaywallPackages: async (
        payload: string
    ): Promise<any> => {
        let response: any;
        // payload.serviceId = 2;
        console.log(apiBaseUrlPort);
        try {
            response = await Http.Request<any>(
                "GET",
                apiBaseUrlPort+"/v3" + "/getServicesByRegion",
                payload ? {"region": payload} : undefined,

            );
        } catch (error) {
            response = {
                message: "",
                response_code: 900,
                success: false
            }
        }
        return response;
    },
    addPaymentMethodCall: async (
        payload: any
    ):  Promise<AuthenticationModel.GetPATCHUserResponse> => {
        let response: AuthenticationModel.GetPATCHUserResponse;

        try {
            response = await Http.Request<AuthenticationModel.GetPATCHUserResponse>(
                "POST",
                apiBaseUrlV4 + "/userPaymentMethod/add",
                undefined,
                {...payload} as any

            );
        } catch (error) {
            response = {
                message: "",
                response: userErrorDummyResponse,
                success: false
            }
        }
        return response;
       
    },
    getServiceByIdCall: async (
        payload: number
    ): Promise<any> => {
        let response: any;
        // payload.serviceId = 2;
        // console.log(apiBaseUrlPort);
        try {
            response = await Http.Request<any>(
                "GET",
                apiBaseUrlPort+"/v2" + "/getServicesById",
                {serviceId: payload},

            );
        } catch (error) {
            response = {
                message: "",
                response_code: 900,
                success: false
            }
        }
        return response;
    },
    getVerificationScreenConfigurationCall: async (
        url: string
    ): Promise<VerificationScreen> => {
        let response: VerificationScreen;
        // payload.serviceId = 2;
        // console.log(apiBaseUrlPort);
        try {
            response = await Http.Request<VerificationScreen>(
                "GET",
                url,

            );
        } catch (error) {
            response = {
              
            }
        }
        return response;
    },
}


