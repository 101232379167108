import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export const LoadingGIF: React.FunctionComponent<{ size?: any, color?: string, showLoading?: boolean, children?: any }>
    = ({ size, color, showLoading, children }) => {

        if (!children) {
            // return <FontAwesomeIcon icon={faSpinner} color={color} 
            //     className="fa-spin" size={size} />
            return <img src="/loading_1.gif" style={{ width: "50px" }}
            />
        } else {
            if (showLoading)
                return <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                    {/* <FontAwesomeIcon icon={faSpinner} color={color}
                        className="fa-spin" size={size} /> */}
                    <img src="/loading.gif" style={{ width: (size === "2x" || size === '1x') ? "50px" : "120px" }}
                    />
                </div>
            else return children;
        }
    }