export const MesiboListener = function(onMessageCallBack: any) {
	this.onMessageRecieved = onMessageCallBack;
};

MesiboListener.prototype.Mesibo_OnConnectionStatus = function(status: any, value: any) {
	console.log("ms : ",status,value)
}

MesiboListener.prototype.Mesibo_OnMessageStatus = function(m: any) {
}

MesiboListener.prototype.Mesibo_OnMessage = function(m: any, data: any) {
	this.onMessageRecieved(m, data);
}