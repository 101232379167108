import React, { useEffect } from "react";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { USER_SESSION } from "@Interfaces";
import { AuthenticationActions } from '@Actions/Authentication';


export const Authenticated: React.FunctionComponent<{ session: USER_SESSION, userGeoLocation: any, onScroll?: () => void }>
    = ({ children, session, onScroll, userGeoLocation }) => {

        const dispatch = useDispatch();
        const location = useLocation();
        const history = useHistory();

        useEffect(() => {
            if (!session || !('_id' in session)) {
                //dispatch(AuthenticationActions.SetRedirectToAfterLogin(location.pathname));
                history.push("/login");
                // if (!userGeoLocation || userGeoLocation === null)
                //     window.location.reload();
            }
        }, [session]);

        return <div className="hide-y-scroller px-3 w-100 bg-main-image"
            style={{ overflowY: "scroll", height: "100%" }}
            onScroll={(e: any) => {
                onScroll && onScroll();
            }}>
            {children}
        </div>
    }
