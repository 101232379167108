import React, { useState, useEffect } from "react";

import { GAME_ITEM } from "@Interfaces";
import { IGameItem } from "./IGameItem";
import styles from "../../Containers/Home/style.module.scss";
import { LazyImage, ShimmerContainer, GradientLoadingRing } from "@Styled";
import { InviteStatusEnums, InviteActionsEnum } from "@Constants";
import { useTranslation } from "react-i18next";
import { getServerTimeOffset } from "@Services/FirebaseEvents";
export const GameItemWithoutBackground: React.FunctionComponent<
    IGameItem.IProps
> = ({
  game,
  onGameClick,
  selectedLanguage,
  gameCategoryDirectionVertical,
}) => {
  const [numOfPlayers, setNumOfPlayers] = useState(Math.floor(Math.random() * 1500) + 500,);
  const {t} = useTranslation();


  useEffect(() => {
    const interval = setInterval(() => {
      setNumOfPlayers(Math.floor(Math.random() * 1500) + 500);
    }, Math.random() * 10000 + 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div
      style={{ position: "relative" }}
      onClick={() => {
        onGameClick(game);
      }}
      className={
        "cursor-pointer align-items-stretch " +
        (gameCategoryDirectionVertical
          ? styles.singlePlayerGamesDisplay
          : styles.flexGameItems)
      }
    >
      {game.premiumTagAr && (
        <img
        style={{left:'62%'}}
          className="premium-game"
          src={`${
            selectedLanguage === "ar" ? game.premiumTagAr : game.premiumTagEn
          }`}
        />
      )}
      <LazyImage
        src={
          game.gifUrl && game.gifUrl !== "false" ? game.gifUrl : game.thumbnail
        }
        alt={game.title}
        height="100%"
        borderRadius="15px"
        width="100%"
      />
      <div className="text-white text-center">
        <div
          className="mt-2 font-weight-bold"
          style={{top: "80%", left: 0, right: 0}}
        >
          {game.title}
        </div>
        <div className="mt-2 font-12px">{numOfPlayers} {t("PLAYERS")}</div>
      </div>
    </div>
  );
};
