// #region Local Imports
import { ActionConsts } from "@Definitions";
import { MessageType } from "@Constants";
// #endregion Local Imports

// #region Interface Imports
import { IAction, IConversationPage, CONVERSATION_MESSAGE, PLAYER_VS_INFO, USER_SESSION } from "@Interfaces";
// #endregion Interface Imports

const INITIAL_STATE: IConversationPage.IStateProps = {
    chatMessages: {
        values: new Array<CONVERSATION_MESSAGE>(),
        paginationNo: 0,
        emptyPaginationNo: 999
    },
    playerVSInfo: Array<PLAYER_VS_INFO>(),
    userFirebaseConnectivityStatus: false,
    errors: [],
    conversationOtherUser: <USER_SESSION>{}
};

export const ConversationReducer = (
    state = INITIAL_STATE,
    action: IAction<IConversationPage.Actions.IMapConversationMessages &
        IConversationPage.Actions.IMapPlayerVSInfo & any[]>
) => {
    switch (action.type) {
        case ActionConsts.Conversation.UserFirebaseConnectivityStatus: {
            return Object.assign({}, state, {
                userFirebaseConnectivityStatus: action.payload
            });
        }
        case ActionConsts.Conversation.GetPlayerVsInfoSuccess: {
            const { playerVSInfo } = action.payload!;

            return Object.assign({}, state, {
                playerVSInfo: playerVSInfo
            });
        }
        case ActionConsts.Conversation.GetPlayerVsInfoError: {
            return Object.assign({}, state, {
                playerVSInfo: []
            });
        }
        case ActionConsts.Conversation.ClearConversation: {
            return Object.assign({}, state, {
                chatMessages: {
                    values: [],
                    paginationNo: 0,
                    emptyPaginationNo: 999
                }
            });
        }
        case ActionConsts.Conversation.SetConversationSuccess: {
            if (action.payload!.length === 1) {
                let isInvite = false;
                const inviteMessage = JSON.parse((action.payload as any)[0].message);
                try {
                    isInvite = inviteMessage.type === MessageType.INVITE;
                } catch (e) {
                    isInvite = false
                }
                if (isInvite && inviteMessage.previousMid && state.chatMessages.values.length) {
                    const currentChatMessages = [...state.chatMessages.values];
                    const updatedChateMessages: any[] = [];
                    currentChatMessages.map((mesiboMessage: any) => {
                        const message = JSON.parse(mesiboMessage.message);
                        if (message._id === inviteMessage.previousMid)
                            updatedChateMessages.push(action.payload![0]);
                        else
                            updatedChateMessages.push(mesiboMessage);
                    });
                    return Object.assign({}, state, {
                        chatMessages: {
                            values: updatedChateMessages,
                            paginationNo: state.chatMessages.paginationNo,
                            emptyPaginationNo: state.chatMessages.emptyPaginationNo
                        }
                    });
                }
                if (!isInvite || (isInvite && !inviteMessage.previousMid)) {
                    return Object.assign({}, state, {
                        chatMessages: {
                            values: [...state.chatMessages.values, ...action.payload!],
                            paginationNo: state.chatMessages.paginationNo,
                            emptyPaginationNo: state.chatMessages.emptyPaginationNo
                        }
                    });
                }
                if (isInvite && inviteMessage.previousMid && state.chatMessages.values.length === 0) {
                    return Object.assign({}, state, {
                        chatMessages: {
                            values: [...action.payload!],
                            paginationNo: state.chatMessages.paginationNo,
                            emptyPaginationNo: state.chatMessages.emptyPaginationNo
                        }
                    });
                }
            } else {
                const allMessages = [...action.payload!];
                const updatedChatMessages: any[] = [];
                allMessages.map((mesiboMessage: any) => {
                    const message = JSON.parse(mesiboMessage.message);
                    if (message.type !== MessageType.INVITE) {                        
                      if ( updatedChatMessages.findIndex(chatMessage=>{
                        const chatParsedMessage = JSON.parse(chatMessage?.message);
                        return chatParsedMessage?._id==message._id})<0)
                            updatedChatMessages.push(mesiboMessage);
                        else
                            console.log("Dublicate Message Found",message)
                    } else {
                        if (!message.previousMid) {
                            const newMessage = allMessages.filter((m: any) => {
                                return JSON.parse(m.message).previousMid === message._id;
                            });
                            if (newMessage.length) updatedChatMessages.push(newMessage[0]);
                            else updatedChatMessages.push(mesiboMessage);
                        }
                    }
                });
                return Object.assign({}, state, {
                    chatMessages: {
                        values: updatedChatMessages,
                        paginationNo: state.chatMessages.paginationNo,
                        emptyPaginationNo: state.chatMessages.emptyPaginationNo
                    }
                });
            }
            break;
        }
        case ActionConsts.Conversation.GetConversationSuccess: {
            const { chatMessages, page } = action.payload!;

            if (!page) {
                return Object.assign({}, state, {
                    chatMessages: {
                        values: chatMessages,
                        paginationNo: page + 1,
                        emptyPaginationNo: chatMessages.length ? state.chatMessages.emptyPaginationNo : page
                    }
                });
            }

            if (chatMessages.length) {
                return Object.assign({}, state, {
                    chatMessages: {
                        values: [...state.chatMessages.values, ...chatMessages],
                        paginationNo: page + 1,
                        emptyPaginationNo: state.chatMessages.emptyPaginationNo
                    }
                });
            } else {
                return Object.assign({}, state, {
                    chatMessages: {
                        emptyPaginationNo: page,
                        values: state.chatMessages.values,
                        paginationNo: page
                    }
                });
            }
        }
        case ActionConsts.Conversation.GetConversationError: {
            return Object.assign({}, state, {
                errors: ['Error getting friends list from server']
            });
        }
        case "inviteReceived": {
            return Object.assign({}, state, {
                inviteReceived: action.payload
            });
        }
        case "offlineChatMmessage": {
            return Object.assign({}, state, {
                offlineChatMmessage: action.payload
            });
        }
        case "offlineGameInviteAccepted": {
            return Object.assign({}, state, {
                offlineGameInviteAccepted: action.payload
            });
        }
        case ActionConsts.Conversation.SetConversationOtherUser: {
            let user: any = action.payload!;
            let otherUser = user.otherUser;
            return Object.assign({}, state, {
                conversationOtherUser: otherUser,
            });
        }
        case ActionConsts.Conversation.UpdateConversationSuccess: {
            let currentChatMessages = [...state.chatMessages.values];
            currentChatMessages.pop();
            // currentChatMessages.push(action.payload  );
            return Object.assign({}, state, {
                chatMessages: {
                    values: [...currentChatMessages, ...action.payload!],
                    paginationNo: state.chatMessages.paginationNo,
                    emptyPaginationNo: state.chatMessages.emptyPaginationNo
                }
            });
        }
        default:
            return state;
    }
}
