// #region Global Imports
import { Dispatch } from "redux";
// #endregion Global Imports

// #region Local Imports
import { ActionConsts } from "@Definitions";
import { MessagesService } from "@Services/API/Messages";
// #endregion Local Imports

// #region Interface Imports
import { IMessagesPage } from "@Interfaces";
// #endregion Interface Imports


export const MessagesActions = {
    GetFriends: (payload: IMessagesPage.Actions.IGetGETMessagesListPayload) => async (
        dispatch: Dispatch
    ) => {
        const { type, page } = payload;
        const result = await MessagesService.GetFriends(payload);

        if (type === 1) {
            dispatch({
                payload: { recipients: result.success && result.response ? result.response : [], page: page },
                type: result.success && result.response ? ActionConsts.Messages.GetStrangerListSuccess : ActionConsts.Messages.GetStrangerListError
            });
        }
        if (type === 0) {
            dispatch({
                payload: { recipients: result.success && result.response ? result.response : [], page: page },
                type: result.success && result.response ? ActionConsts.Messages.GetFriendsListSuccess : ActionConsts.Messages.GetFriendsListError
            });
        }
        return result;
    },
    BlockUser: (payload: IMessagesPage.Actions.IGetPATCHBlockUserPayload) => async (
        dispatch: Dispatch
    ) => {
        const result = await MessagesService.BlockUser(payload);

        return result;
    },
    ReportRoom: (payload: IMessagesPage.Actions.IGetPOSTReportRoomPayload) => async (
        dispatch: Dispatch
    ) => {
        const result = await MessagesService.ReportRoom(payload);

        return result;
    },
    UpdateUnseenMessageInFotter: (payload: number) => async (
        dispatch: Dispatch
    ) => {
        dispatch({
            payload: { currentCount: payload },
            type: ActionConsts.Messages.UpdateUnseenMessageInFotter
        });
    },
    UnfriendUser: (payload: any) => async (
        dispatch: Dispatch
    ) => {
        return await MessagesService.UnfriendUser(payload);
    }
}