import React from 'react';
import { Link } from "react-router-dom";

export const Header: React.FunctionComponent<{  }> 
    = ({ }) => {
        return <div className="d-flex justify-content-between py-3 px-3 w-100">
            <div></div>
            <img src="/images/LOGO2x.png" width="70px" height="20px" />
            <Link to="/search-games" className="cursor-pointer">
                <img height="20px" 
                    width="20px" src="/images/search.png" />
            </Link>
            {/* <img src="/images/Ico_Notification.png" width="20px" height="20px" /> */}
        </div>
    }