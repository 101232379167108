import socketIO from 'socket.io-client';
import { USER_SESSION } from '@Interfaces';

(window as any).gameSocket = null;
const SocketIo = (user: USER_SESSION) => {
    const apiBaseUrl = process.env.REACT_APP_IS_PRODUCTION_API === "true" ? process.env.REACT_APP_API_URL : process.env.REACT_APP_STAGING_API_URL;

    const init = () => {
        const apiUrl = apiBaseUrl ? apiBaseUrl.split('/api')[0] : '';
        if (apiUrl) {
            (window as any).gameSocket = socketIO(`${apiUrl}?userId=${user._id}&udid=${user.phone}`, {
                transports: ['websocket'], jsonp: false, forceNew: true, upgrade: false
            });
            // (window as any).gameSocket.on('connect', () => {});
            (window as any).gameSocket.on('error', (e: any) => {
                console.log("On socket error : ", e);
            });
            (window as any).gameSocket.on('disconnect', (e: any) => {
                console.log("On socket disconnect : ", e);
            });
            (window as any).gameSocket.on('connect', (e: any) => {
                console.log("ss : ", (window as any).gameSocket.connected);
            });
            return (window as any).gameSocket;
        }
        return null;
    };


    return {
        getInstance: function () {
            if (!(window as any).gameSocket) return init();

            return (window as any).gameSocket;
        }
    };
}

export { SocketIo };