// #region Global Imports
import { Dispatch } from "redux";
// #endregion Global Imports

// #region Local Imports
import { ActionConsts } from "@Definitions";
import { ConversationService } from "@Services/API/Conversation";
// #endregion Local Imports

// #region Interface Imports
import { IConversationPage, UploadMediaFilesModel, USER_SESSION } from "@Interfaces";
// #endregion Interface Imports


export const ConversationActions = {
    GetConversation: (payload: IConversationPage.Actions.IGetGETConversationPayload) => async (
        dispatch: Dispatch
    ) => {
        const result = await ConversationService.GetConversation(payload);

        dispatch({
            payload: { chatMessages: result.response && result.status ? result.response : [], page: payload.page },
            type: result.response && result.status ? ActionConsts.Conversation.GetConversationSuccess : ActionConsts.Conversation.GetConversationError
        });
    },
    SetConversation: (payload: any) => async (
        dispatch: Dispatch
    ) => {
        dispatch({
            payload: payload,
            type: ActionConsts.Conversation.SetConversationSuccess
        });
    },
    updateLastMessageConversation: (payload: any) => async (
        dispatch: Dispatch
    ) => {
        dispatch({
            payload: payload,
            type: ActionConsts.Conversation.UpdateConversationSuccess
        });
    },
    UploadMediaOnStorage: (payload: UploadMediaFilesModel.GetUploadMediaFilesPayload) => async (
        dispatch: Dispatch
    ) => {
        const result = await ConversationService.UploadMediaOnStorage(payload);
        if (result.success && result.response) {
            return result.response;
        } else {
            return "";
        }
    },
    FetchEmojis: () => async (
        dispatch: Dispatch
    ) => {
        const result = await ConversationService.FetchEmojis();
        return result;
    },
    ClearConversation: () => async (
        dispatch: Dispatch
    ) => {
        dispatch({
            payload: null,
            type: ActionConsts.Conversation.ClearConversation
        });
    },
    GetPlayerVsInfo: (payload: IConversationPage.Actions.IGetPOSTPlayerVSInfoPayload) => async (
        dispatch: Dispatch
    ) => {
        const result = await ConversationService.GetPlayerVSInfo(payload);

        dispatch({
            payload: { playerVSInfo: result.success && result.response.users.length > 0 ? result.response.users : [] },
            type: result.success && result.response.users.length > 0 ? ActionConsts.Conversation.GetPlayerVsInfoSuccess : ActionConsts.Conversation.GetPlayerVsInfoError
        });
        return result;
    },
    SetUserFirebaseConnectivityStatus: (payload: boolean) => async (
        dispatch: Dispatch
    ) => {
        dispatch({
            payload: payload,
            type: ActionConsts.Conversation.UserFirebaseConnectivityStatus
        });
    },
    SendFirebaseOfflineNotification: (payload: IConversationPage.Actions.IGetPOSTSendFirebaseOfflineMessagePayload) => async (
        dispatch: Dispatch
    ) => {
        ConversationService.SendFirebaseOfflineMessage(payload);
    },
    inviteReceived: (payload: any) => async (
        dispatch: Dispatch
    ) => {
        dispatch({
            payload: payload,
            type: "inviteReceived"
        });
    },
    // MessageReceived: (payload: any) => async (
    //     dispatch: Dispatch
    // ) => {
    //     dispatch({
    //         payload: payload,
    //         type: "messageReceived"
    //     });
    // },
    offlineChatMmessage: (payload: any) => async (
        dispatch: Dispatch
    ) => {
        dispatch({
            payload: payload,
            type: "offlineChatMmessage"
        });
    },
    offlineGameInviteAccepted: (payload: any) => async (
        dispatch: Dispatch
    ) => {
        dispatch({
            payload: payload,
            type: "offlineGameInviteAccepted"
        });
    },
    SetConversationUser: (payload: { otherUser: USER_SESSION }) => async (
        dispatch: Dispatch
    ) => {
        // await dispatch(InviteActions.CreateRoom({ userId1: user._id, userId2: suggestion._id }));

        dispatch({
            payload: { otherUser: payload.otherUser },
            type: ActionConsts.Conversation.SetConversationOtherUser
        })
    },
    GetOtherUser: (payload: string) => async (
        dispatch: Dispatch
    ) => {
        // await dispatch(InviteActions.CreateRoom({ userId1: user._id, userId2: suggestion._id }));
        const result = await ConversationService.GetOtherUser(payload);
        let usr = result.success ? result.response : {};
        let user = {
            bio: usr.bio,
            fbImage: usr.fbImage,
            fb_image: usr.fb_image,
            isBot: usr.isBot,
            is_bot: usr.is_bot,
            lastActiveTime: usr.lastActiveTime,
            location: usr.location,
            name: usr.name,
            profileImage: usr.profileImage,
            userImageUrl: usr.userImageUrl,
            sessionId: usr.seesionId,
            __v: usr.__v,
            _id: usr._id,
        }
        dispatch({
            payload: { otherUser: user },
            type: ActionConsts.Conversation.SetConversationOtherUser
        })
    },
}