// #region Local Imports
import { ActionConsts } from "@Definitions";
// #endregion Local Imports

// #region Interface Imports
import { IAction, IMessagesPage, MESSAGING_ROOM } from "@Interfaces";
// #endregion Interface Imports

const INITIAL_STATE: IMessagesPage.IStateProps = {
    friendRecipients: {
        values: new Array<MESSAGING_ROOM>(),
        paginationNo: 0,
        emptyPaginationNo: 999
    },
    strangerRecipients: {
        values: new Array<MESSAGING_ROOM>(),
        paginationNo: 0,
        emptyPaginationNo: 999
    },
    unseenMessagesCount: 0,
    errors: []
};

export const MessagesReducer = (
    state = INITIAL_STATE,
    action: IAction<IMessagesPage.Actions.IMapMessageRecipients & { currentCount: number}>
) => {
    switch (action.type) {
        case ActionConsts.Messages.UpdateUnseenMessageInFotter: {
            const { currentCount } = action.payload!;

            return Object.assign({}, state, {
                unseenMessagesCount: currentCount ? state.unseenMessagesCount + 1 : 0
            });
        }
        case ActionConsts.Messages.GetFriendsListSuccess: {
            const { recipients, page } = action.payload!;

            if (!page) {
                return Object.assign({}, state, {
                    friendRecipients: {
                        values: recipients,
                        paginationNo: page + 1,
                        emptyPaginationNo: recipients.length ? state.friendRecipients.emptyPaginationNo : page
                    }
                });
            }
            
            if (recipients.length) {
                return Object.assign({}, state, {
                    friendRecipients: {
                        values: [...state.friendRecipients.values, ...recipients],
                        paginationNo: page + 1,
                        emptyPaginationNo: state.friendRecipients.emptyPaginationNo
                    }
                });
            } else {
                return Object.assign({}, state, {
                    friendRecipients: {
                        emptyPaginationNo: page,
                        values: state.friendRecipients.values,
                        paginationNo: page
                    }
                });
            }
        }
        case ActionConsts.Messages.GetFriendsListError: {
            return Object.assign({}, state, {
                errors: ['Error getting friends list from server']
            });
        }
        case ActionConsts.Messages.GetStrangerListSuccess: {
            const { recipients, page } = action.payload!;

            if (!page) {
                return Object.assign({}, state, {
                    strangerRecipients: {
                        values: recipients,
                        paginationNo: page + 1,
                        emptyPaginationNo: recipients.length ? state.strangerRecipients.emptyPaginationNo : page
                    }
                });
            }
            
            if (recipients.length) {
                return Object.assign({}, state, {
                    strangerRecipients: {
                        values: [...state.strangerRecipients.values, ...recipients],
                        paginationNo: page + 1,
                        emptyPaginationNo: state.strangerRecipients.emptyPaginationNo
                    }
                });
            } else {
                return Object.assign({}, state, {
                    strangerRecipients: {
                        emptyPaginationNo: page,
                        values: state.strangerRecipients.values,
                        paginationNo: page
                    }
                });
            }
        }
        case ActionConsts.Messages.GetStrangerListError: {
            return Object.assign({}, state, {
                errors: ['Error getting stranger list from server']
            });
        }
        default:
            return state;
    }
}
