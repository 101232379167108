// #region Global Imports
import { Dispatch } from "redux";
// #endregion Global Imports

// #region Local Imports
import { ActionConsts } from "@Definitions";
import { GameService } from "@Services/API/Games";
// #endregion Local Imports

// #region Interface Imports
import { USER_SESSION, GETGameHistoryPayload } from "@Interfaces";
// #endregion Interface Imports


export const PersistActions = {
    GetGameHistory: (payload: GETGameHistoryPayload) => async (
        dispatch: Dispatch
    ) => {
        const result = await GameService.GetGamesHistory(payload);
        dispatch({
            payload: result.success && result.response ? result.response : [],
            type: result.success && result.response ? ActionConsts.Settings.UpdateGameHistorySuccess : ActionConsts.Settings.UpdateGameHistoryError
        });
    },
    SetConversationUser: (payload: { otherUser: USER_SESSION }) => async (
        dispatch: Dispatch
    ) => {
        // await dispatch(InviteActions.CreateRoom({ userId1: user._id, userId2: suggestion._id }));

        dispatch({
            payload: { otherUser: payload.otherUser },
            type: ActionConsts.Conversation.SetConversationOtherUser
        })
    },
    SetLanguage: (payload: { language: string, userSelectedLanguage?: boolean }) => async (
        dispatch: Dispatch
    ) => {
        dispatch({
            payload: { language: payload.language, userSelectedLanguage: payload.userSelectedLanguage },
            type: ActionConsts.Settings.SetSelectedLanguage
        })
    },
    UpdateRecentlyPlayedGame: (payload: { gameId: number }) => async (
        dispatch: Dispatch
    ) => {
        dispatch({
            payload: payload,
            type: ActionConsts.Settings.UpdateRecentlyPlayedGame
        });
    },
    SetFacebookSyncedFriends: (payload: { facebookFriends: USER_SESSION[] }) => async (
        dispatch: Dispatch
    ) => {
        dispatch({
            payload: payload,
            type: ActionConsts.Invite.SetFacebookSyncedFriends
        });
    },
    SetTermsAndConditionModal: (payload: boolean) => async (
        dispatch: Dispatch

    ) => {
        dispatch({
            payload: payload,
            type: ActionConsts.Settings.SetTermsAndConditionModal
        });
    },
    SetCallFunctionalityShowed: (payload: boolean) => async (
        dispatch: Dispatch
    ) => {
        dispatch({
            payload: payload,
            type: ActionConsts.Settings.SetCallFunctionalityShowed
        });
    },
    SetInstallPWAModal: (payload: boolean) => async (
        dispatch: Dispatch
    ) => {
        dispatch({
            payload: payload,
            type: ActionConsts.Settings.SetInstallPWAModal
        });
    },
    SetUserHasNoName: (payload: boolean) => async (
        dispatch: Dispatch
    ) => {
        dispatch({
            payload: payload,
            type: ActionConsts.Home.SetUserHasNoName
        });
    },
    saveLoginParams: (payload: string) => async (
        dispatch: Dispatch
    ) => {
        dispatch({
            payload: payload,
            type: ActionConsts.Settings.LoginParams
        });
    },
}