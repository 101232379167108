import {
  AccessResponseStatus,
  PaymentMethodType,
  SubscriptionResponseCode,
} from "@Constants";
import { USER_SESSION } from "@Interfaces";
import { SUB_BUNDLES } from "@Services/API/PayWall/SubscriptionBundles";
import { VerificationScreen } from "@Services/API/VerificationScreens/VerificationScreen";
import { removeCookie } from "@Services/Cookie";
import { ExclusiveFeaturesActions } from "@Actions/ExclusiveFeatures";
import { useDispatch} from "react-redux";
// const dispatch = useDispatch();
var CryptoJS = require("crypto-js");

export const lockOppener = "my-secret-key@122";

export const encryptData = (data: string) => {
  let encryptString = "";
  try {
    encryptString = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      lockOppener
    ).toString();
  } catch (error) {
    encryptString = data;
  }

  return encryptString;
};

export const decryptData = (data: string) => {
  let decryptedData = "";
  try {
    var bytes = CryptoJS.AES.decrypt(data, lockOppener);
    decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    decryptedData = data;
  }

  return decryptedData;
};

export const clearAppCache = () => {
  if ("caches" in window) {
    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach((name) => {
        caches.delete(name);
      });
    });

    removeCookie("api-token");
    removeCookie("accpted-terms-conditions");
    // Makes sure the page reloads. Changes are only visible after you refresh.
    window.location.reload();
  }
};

//FOR SAFARI   To record Voice Messages, Gago needs access to your computer's microphone. Open your browser's Preferences -> websites and change microphone setting for gago.games.com to "Allow".
//FOR Chrome   To record Voice Messages, Gago needs access to your computer's microphone. Click blocked Image in the URL bar and choose "Allways allow gago.games.com to access your microphone".
//FOR Firefox   To record Voice Messages, Gago needs access to your computer's microphone. Click Lock Image in the URL bar and set "Use the Microphone" to "Allow".

export const getMircophonePermissionDescription = () => {
  if (navigator.userAgent.indexOf("Chrome") != -1) {
    return 'To record Voice Messages, Gago needs access to your computer\'s microphone. Click blocked Image in the URL bar and choose "Allways allow gago.games.com to access your microphone".';
  } else if (navigator.userAgent.indexOf("Safari") != -1) {
    return "To record Voice Messages, Gago needs access to your computer's microphone. Open your browser's Preferences -> websites and change microphone setting for gago.games.com to \"Allow\".";
  } else if (navigator.userAgent.indexOf("Firefox") != -1) {
    return 'To record Voice Messages, Gago needs access to your computer\'s microphone. Click Lock Image in the URL bar and set "Use the Microphone" to "Allow".';
  }
  return 'To record Voice Messages, Gago needs access to your computer\'s microphone. Click blocked Image in the URL bar and choose "Allways allow gago.games.com to access your microphone".';
};

export const praseImageNameToS3Url = (imageName : string) => {
  return  process.env.REACT_APP_GAGO_S3_URL +
            "/profileImages/" +
            parseImageUrl(imageName)
        
}

export const getProfileImage = (user: any) => {
  if (user.userImageUrl) return user.userImageUrl;

  if (user.isBot) {
    return parseImageUrl(user.profileImage);
  } else {
    if (user.fb_image) {
      return (
        "https://graph.facebook.com/" +
        user.fb_image +
        "/picture?height=240&width=240"
      );
    } else
      return user.profileImage
        ? process.env.REACT_APP_GAGO_S3_URL +
            "/profileImages/" +
            parseImageUrl(user.profileImage)
        : "/images/dummay.png";
  }
};
const parseImageUrl = (url: string) => {
  if (url) {
    return url.split("?")[0];
  } else return "";
};

export const getFormattedDate = (date: any) => {
  if (date) {
    let d = new Date(date);
    return (
      ("0" + d.getDate()).slice(-2) +
      "/" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "/" +
      d.getFullYear()
    );
  } else return "";
};
export const addMilliSecondsToCurrentDate = (miliseconds: number) => {
  let date = new Date();
  let newDate;
  if (miliseconds) {
    newDate = new Date(date.getTime() + miliseconds);
    return newDate.getTime();
  }
  return date.getTime();
};
declare global {
  interface Window {
    JitsiMeetExternalAPI: any;
  }
}
export const importJitsiApi = (): Promise<void> =>
  new Promise(async (resolve) => {
    if (window.JitsiMeetExternalAPI) {
      resolve(window.JitsiMeetExternalAPI);
    } else {
      const head = document.getElementsByTagName("head")[0];
      const script = document.createElement("script");

      script.setAttribute("type", "text/javascript");
      script.setAttribute(
        "src",
        "https://voip.gago.games/libs/external_api.min.js"
      );

      head.addEventListener(
        "load",
        function (event: any) {
          if (event.target.nodeName === "SCRIPT") {
            resolve(window.JitsiMeetExternalAPI);
          }
        },
        true
      );

      head.appendChild(script);
    }
  });

export const getName = (user: any): string => {
  if (user) {
    if (!user.name) {
      try {
        let phoneTypeArray = user.loginType.filter(
          (data: any) => data.type.toLowerCase() === "phone"
        );
        let phone =
          phoneTypeArray && phoneTypeArray.length > 0
            ? phoneTypeArray[0].id
            : "1234567891011";
        let dummyName = phone.slice(4) + phone.slice(phone.length - 4);

        let sum = dummyName
          .split("")
          .map(Number)
          .reduce(function (a: number, b: number) {
            return a + b;
          });
        return "user " + sum;
      } catch (err) {
        return "user 18634";
      }
    } else return user.name;
  } else return "user 1234";
};
export const isScriptLoaded = (scriptURL: string): boolean => {
  let doc = document.querySelector('script[src="' + scriptURL + '"]');
  if (doc) return true;
  return false;
};
export const getNumberInInternational = (userNummber: string): string => {
  if (userNummber?.length === 11 && userNummber.substring(0, 2) === "03") {
    userNummber = userNummber.substring(1);
    userNummber = "92" + userNummber;
  }
  return userNummber;
};
export const getServiceIdFromNumero = (
  interNationalFormatNumber: string,
  userRegion: any,
  packageType: string
) => {
  let serviceId = -1;
  try {
    if (userRegion) {
      let numeroInitial = interNationalFormatNumber.substring(
        userRegion.numeroSequence.start,
        userRegion.numeroSequence.end
      );

      userRegion.services.forEach((service: any) => {
        if (
          service.operatorNumeroValues.includes(numeroInitial) &&
          (packageType && packageType.length > 0
            ? service.package.toLowerCase() === packageType.toLowerCase()
            : true)
        ) {
          serviceId = service.serviceId;
        }
      });
    }
  } catch (error) {}
  console.log("Numero Service Id IS ", serviceId);
  return serviceId;
};
export const getServiceIdByCompaign=(paymentMethod:string, userRegion: any,)=>{
  let serviceId = -1;
  if(paymentMethod==="esp"){
    userRegion.services.forEach((service: any) => {
      if(service.operator==="easypaisa"){
        serviceId = service.serviceId;
      }
    })
  }
  return serviceId;
}
export const getMessageAccordingToSubscriptionResponseCode = (
  response_code: number
): string => {
  // if (response_code == SubscriptionResponseCode.AUTHORIZATION_ERROR)
  //   return AppSrings.AUTHORIZATION_ERROR;
  // else if (response_code == SubscriptionResponseCode.INTERNAL_SERVER_ERROR)
  //   return AppSrings.INTERNAL_SERVER_ERROR;
  // else if (response_code == SubscriptionResponseCode.INVALID_OTP)
  //   return AppSrings.INVALID_OTP;
  // else if (response_code == SubscriptionResponseCode.OTP_EXPIRED)
  //   return AppSrings.OTP_EXPIRED;
  // else if (response_code == SubscriptionResponseCode.OPERATOR_UNAVILABLE)
  //   return AppSrings.OPERATOR_UNAVAILABLE;
  // else if (response_code == SubscriptionResponseCode.INVALID_PARAMETERS)
  //   return AppSrings.INVALID_PARAMERTERS;
  // else if (response_code == SubscriptionResponseCode.LAST_OTP_SENT_WAIT)
  //   return AppSrings.LAST_OTP_WAIT;
  if (response_code === SubscriptionResponseCode.INVALID_OTP)
    return "INVALID_OTP";
  // else if (response_code == SubscriptionResponseCode.MSISDN_BLACKLISTED)
  //   return AppSrings.MSISDN_BLACKLISTED;
  return "";
};

export const getHeaderAccordingToSubscriptionResponseCode = (
  response_code: number
): string => {
  // if (response_code == SubscriptionResponseCode.AUTHORIZATION_ERROR)
  //   return AppSrings.AUTHORIZATION_ERROR_HEADER;
  // else if (response_code == SubscriptionResponseCode.INTERNAL_SERVER_ERROR)
  //   return AppSrings.INTERNAL_SERVER_ERROR_HEADER;
  // else if (response_code == SubscriptionResponseCode.INVALID_OTP)
  //   return AppSrings.INVALID_OTP_HEADER;
  // else if (response_code == SubscriptionResponseCode.OTP_EXPIRED)
  //   return AppSrings.OTP_EXPIRED_HEADER;
  // else if (response_code == SubscriptionResponseCode.OPERATOR_UNAVILABLE)
  //   return AppSrings.OPERATOR_UNAVAILABLE_HEADER;
  // else if (response_code == SubscriptionResponseCode.INVALID_PARAMETERS)
  //   return AppSrings.INVALID_PARAMERTERS_HEADER;
  // else if (response_code == SubscriptionResponseCode.LAST_OTP_SENT_WAIT)
  //   return AppSrings.LAST_OTP_WAIT_HEADER;
  if (response_code === SubscriptionResponseCode.INVALID_OTP)
    return "INVALID_OTP_HEADER";
  // else if (response_code == SubscriptionResponseCode.MSISDN_BLACKLISTED)
  //   return AppSrings.MSISDN_BLACKLISTED_HEADER;
  return "";
};

export const isPaidContentAllowed = (user: USER_SESSION) => {
  // accessBlockReason 0 = "no reason needed", 1 = "low balance", 2 = "No active subscription"

  if (!user._id) {
    return {
      accessAllowed: false,
      accessBlockReason: AccessResponseStatus.USER_NOT_FOUND,
    };
  }

  // if (!user.purchaseStatus && (getUserActivePaymentMethod(user).length>0)) {
  //   return {accessAllowed: true, accessBlockReason: AccessResponseStatus.NO_STATUS}
  // }

  if (
    (user.purchaseStatus &&
      user.purchaseStatus.response_code ===
        SubscriptionResponseCode.ACTIVE_SUB) ||
    (user.purchaseStatus &&
      user.purchaseStatus.response_code ===
        SubscriptionResponseCode.LOW_BALANCE &&
      user.onTrial)
  ) {
    return {
      accessAllowed: true,
      accessBlockReason: AccessResponseStatus.NO_STATUS,
    };
  } else if (
    user.purchaseStatus &&
    user.purchaseStatus.response_code === SubscriptionResponseCode.LOW_BALANCE
  ) {
    return {
      accessAllowed: false,
      accessBlockReason: AccessResponseStatus.LOW_BALANCE,
    };
  } else {
    return {
      accessAllowed: false,
      accessBlockReason: AccessResponseStatus.NO_SUBSCRIPTION,
    };
  }
};

export const getCheckStatusCallParams = (
  session: USER_SESSION,
  subscriptionNo: string
) => {
  const data = getUserActivePaymentMethod(session);
  const isGPlayToken =
    data && data.type.toLowerCase() === PaymentMethodType.GPLAY.toLowerCase();
  const params = data
    ? isGPlayToken
      ? { gPlayPurchaseToken: data.data }
      : { msisdn: data.data }
    : getNumberInInternational(subscriptionNo)
    ? { msisdn: getNumberInInternational(subscriptionNo) }
    : null;
  console.log("getCheckStatusCallParams", params);
  return params;
};

export const getUserActivePaymentMethod = (session: USER_SESSION) => {
  const data = session.paymentMethods?.filter((e) => e.active === true);
  return data && data.length > 0 ? data[0] : null;
};

export const getRecommendedPaymentMethod = (
  bundles: Array<Array<SUB_BUNDLES>>
): SUB_BUNDLES | null => {
  let result: SUB_BUNDLES | null = null;
  bundles.forEach((bundleArray) => {
    const bundle = bundleArray.find(
      (element) => element.isRecommended === true
    );
    if (bundle) {
      result = bundle;
      return result;
    }
  });

  return result;
};

export const isServiceIdExists = (
  bundles: Array<Array<SUB_BUNDLES>>,
  serviceId: number
): Boolean => {
  let result: Boolean = false;
  bundles.forEach((bundleArray) => {
    const bundle = bundleArray.find(
      (element) => element.serviceId === serviceId
    );
    if (bundle) {
      result = true;
    }
  });

  return result;
};

export const haveSpecialUIConfig = (data?: VerificationScreen) => {
  return data && data.isSpecialView === true;
};

/**
 * setViewPortSize
 *
 * @param {string} toFull Change viewport to full screen if true
 * @param {string} orientaion current orientation of device
 * @param {string} width Change width of viewport to specifed width if toFull is false
 * @param {string} height Change width of viewport to specifed height if toFull is false
 * @param {string} maxWidth Change maxWidth of viewport to specifed width if toFull is false
 */
export const setViewPortSize = (toFull: boolean, orientaion: string = "", width: number = 600, height: number = 600, maxWidth: number = 600) => {
  console.log("viewport set is called: ", toFull, width, height, maxWidth)
  let vpc = document.getElementById("view-port-container")
  let root = document.getElementById("root")
  if (root) {
    // console.log("root : ", root)
    // console.log("root style: ", root.style)
    console.log("root width: ", document.body.clientWidth)
    console.log("root height: ", document.body.clientHeight)
  }
  if (vpc) {
    console.log("outer: ", window.outerWidth)
    console.log("inner: ", window.innerWidth)
    let w = toFull ?  document.body.clientWidth : width ;
    let h = toFull ?  document.body.clientHeight  : height;
    let mW = toFull ?  document.body.clientWidth : maxWidth;
    
    // let w = toFull ?  window.outerWidth : width ;
    // let h = toFull ?  window.outerHeight  : height;
    // let mW = toFull ?  window.outerWidth  : maxWidth;

    let max = Math.max(w, h)

    // max.toString() + "px"
    vpc.style.width = orientaion === "landscape" ? "100%" : w.toString() + "px"
    vpc.style.maxWidth = orientaion === "landscape" ? "100%" : mW.toString() + "px"
  }
}
export const getServiceofRegionIfAny=(countryIso:string,regions:any)=>{
let specifiedRegion:any=null;
if(regions.length >0){
  regions.map((region:any)=>{
    if(region.countryIso == countryIso){
      specifiedRegion=region;
    }
  })
}
if(specifiedRegion){
  if(specifiedRegion.services.length >0){
    return specifiedRegion.services[0]
  }
}
}
// export const showExclusiveFeatureModal=(flag:boolean)=>{
//   const dispatch = useDispatch();
//   dispatch(
//     ExclusiveFeaturesActions.SetExclusiveFeature(flag)
//   )
// }
