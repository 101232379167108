import React, { useState, useEffect, useRef, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Router,
  Switch,
  Route,
  withRouter,
  useLocation,
  useHistory,
  RouteComponentProps,
  useParams,
} from "react-router-dom";
import styled from "styled-components";

import { HomeContainer } from "../Containers/Home";
import { HomeContainerNewDesign } from "../Containers/Home/HomeContainerNewDesign";
import { UnSubContainer } from "../Containers/UnSub";

import { HomeActions } from "@Actions/Home";
import { PersistActions } from "@Actions/PersistActions";
import { ConversationActions } from "@Actions/Conversation";
import { MessagesActions } from "@Actions/Messages";
import { AuthenticationActions } from "@Actions/Authentication";
import { ExclusiveFeaturesActions } from "@Actions/ExclusiveFeatures";
import { SubscriptionNotActive } from "@Components/Modals/SubscriptionNotActive";
import { Header } from "@Components/Header";
import { FooterContainer } from "@Components/Footer";
import { MultiPlayerGameBoard } from "@Components/MultiPlayerGameBoard";
import { Authenticated } from "@Components/Authenticated";
import { InviteRecievedModal } from "@Components/Modals/InviteRecievedModal";
import { LowBalanceModal } from "@Components/Modals/LowBalanceModal";
// import { LoadingSpinner } from "@Components/LoadingSpinner";
import { LoadingGIF } from "@Components/LoadingSpinner/LoadingGIF";
import { RouteTransition } from "@Components/RouteTransition";
import { OnlineStatus } from "@Components/OnlineStatus";

import { SocketIo } from "@Services/SocketIO";
import { IStore } from "@Redux/IStore";
import { store } from "@Redux";
import {
  SendAnalyticsToFirebase,
  RemoveEventListnerOnUserNode,
  addInviteListeners,
  updateInviteOnFirebase,
  addInviteOnFirebase,
  onMessageListener,
  SetOnlineStatus,
  RemoveSessionIdListner,
} from "@Services/FirebaseEvents";
import { AnalyticsEventsName } from "@Services/FirebaseEvents";
import { getCookie } from "@Services/Cookie";
import { EventEmitterSingleton } from "@Services/UI";
import history from "@Services/History";

import { MesiboApi } from "../Containers/Conversation/MesiboApi";
import { MesiboListener } from "../Containers/Conversation/MesiboListener";
import {
  MESIBO_CHAT_MESSAGE,
  GAME_INVITE,
  GAME_ITEM,
  USER_SESSION,
} from "@Interfaces";
import {
  MessageStatus,
  MessageType,
  InviteActionsEnum,
  InviteStatusEnums,
} from "@Constants";
import { useModal } from "@Hooks";
import { BroadcastService } from "@Services/API/Broadcast";
import {
  getProfileImage,
  getUserActivePaymentMethod,
  isServiceIdExists,
} from "@HelperFunctions";
import { EnterProfile } from "@Components/EnterProfile";
import { SetNameModal } from "@Components/Modals/SetNameModal";
import { GenericPopupModal } from "@Components/Modals/GenericPopupModal";
import { useTranslation } from "react-i18next";
import { GameReconnectingModal } from "@Components/Modals/GameReconnectingModal";
import MultiLogin from "@Components/MultiLogin/MultiLogin";
import Paywall from "@Components/Paywall/Paywall";
import Terms from "@Components/TermsAndConditions/Terms";
import { PremiumFeatureModal } from "@Components/Modals/PremiumFeatureModal";

const MessagesContainer: any = lazy(
  () => import("../Containers/Messages" /* webpackChunkName: "Messages" */)
);

const LoginContainer: any = lazy(
  () => import("../Containers/Login" /* webpackChunkName: "Login" */)
);

const SearchGames: any = lazy(
  () =>
    import("../Containers/SearchGames" /* webpackChunkName: "SearchGames" */)
);

const DiscoverPeople: any = lazy(
  () =>
    import(
      "../Containers/DiscoverPeople" /* webpackChunkName: "DiscoverPeople" */
    )
);

const PlayHistoryContainer: any = lazy(
  () =>
    import(
      "../Containers/PlayHistory" /* webpackChunkName: "PlayHistoryContainer" */
    )
);

const ConversationContainer: any = lazy(
  () =>
    import("../Containers/Conversation" /* webpackChunkName: "Conversation" */)
);

const InviteContainer: any = lazy(
  () => import("../Containers/Invite" /* webpackChunkName: "Invite" */)
);

const ProfileContainer: any = lazy(
  () => import("../Containers/Profile" /* webpackChunkName: "Profile" */)
);

const SettingsContainer: any = lazy(
  () => import("../Containers/Settings" /* webpackChunkName: "Settings" */)
);

const FeedbackContainer = lazy(
  () => import("../Containers/Feedback" /* webpackChunkName: "Feedback" */)
);

const BlockedUsersContainer = lazy(
  () =>
    import("../Containers/BlockedUsers" /* webpackChunkName: "BlockedUsers" */)
);

const AboutUsContainer = lazy(
  () =>
    import("../Containers/AboutUs" /* webpackChunkName: "AboutUs" */)
);

const EditProfileContainer = lazy(
  () =>
    import("../Containers/EditProfile" /* webpackChunkName: "EditProfile" */)
);
const LeaderBoardContainer = lazy(
  () =>
    import("../Containers/LeaderBoard" /* webpackChunkName: "LeaderBoard" */)
);
const PendingRequestContainer = lazy(
  () =>
    import(
      "../Containers/PendingRequest" /* webpackChunkName: "PendingRequest" */
    )
);
let invite_list: any = [];
const Container: React.FunctionComponent<
  {
    setShowServiceWorketPrompt: (a: boolean) => void;
    windowLoaded: boolean;
    isFirebaseInitilized: boolean;
    fireBaseMessagingToken: string;
    fireBaseMessagingStatus: boolean;
    setShowTermsConditions: (a: boolean) => void;
  } & RouteComponentProps
> = ({
  setShowServiceWorketPrompt,
  windowLoaded,
  isFirebaseInitilized,
  fireBaseMessagingToken,
  fireBaseMessagingStatus,
  setShowTermsConditions,
}) => {
  const persistState = useSelector((state: IStore) => state.persistState);
  const {
    session,
    multiplayerGamesList,
    selectedLanguage,
    userHasNoName,
    sessionID,
    InstallPWA,
    persistFirebaseConfiguration,
    showPaywall,
    userHasSkippedPaywall,
    paymentBundles
  } = persistState;
  const invite: any = useSelector((state: IStore) => state.conversation);
  const inviteReceived = invite.inviteReceived;
  const conversationOtherUser = invite.conversationOtherUser;
  const [loginBackground,setLoginBackground]=useState("bg-login-image");
  const [selectedGameId, setSelectedGameId] = useState(-1);
  const [showGameBoard, setShowGameBoard] = useState(false);
  const [showLowBalanceModal, setShowLowBalanceModal] = useState(false);
  const [showSubscriptionNotActive, setShowSubscriptionNotActive] =
    useState(false);
  const [acceptInviteMatchData, setAcceptInviteMatchData] = useState<any>(
    Object.create({})
  );
  const [registerMatchMaking, setRegisterMatchMaking] = useState(false);
  const [onlineStatus, setOnlineStatus] = useState(navigator.onLine);
  const [hasConnectedSocket, setHasConnectedSocket] = useState(false);
  const [initMesiboSuccess, setInitMesiboSuccess] = useState(false);
  const [inviteRecievedData, setInviteRecievedData] = useState<any[]>([]);
  const [inviteDelete, setInviteDelete] = useState<any[]>([]);
  const [playAgainStatus, setPlayAgainStatus] = useState(false);
  const [showNoNameModal, setShowNoNameModal] = useState(false);
  // console.log(showPaywall);
  const [showUserPaywall, setShowUserPaywall] = useState(false);
  const[premiumFeature,setPremiumFeature]=useState(false);
  // const[exclusiveFeatureTitle,setExclusiveFeatureTitle]=useState("EXCLUSIVE_FEATURE")
  const [isServiceByIdFetched, setIsServiceByIdFetched] = useState(false);
  const [showSubscriptionSuccessfull, setShowSubscriptionSuccessfull] =
    useState(false);
    const [loginWithMobile, setLoginWithMobile] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const { isShowing, toggle } = useModal(modalRef);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const loginState = useSelector((state: IStore) => state.login);
  const {exclusiveFeature}=useSelector((state: any) => state.exclusiveFeature);
  // const { paymentBundles } = loginState;
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();

  const getIdFromParams = () => {
    let path = window.location.pathname.split("/");
    if (path.length >= 3) return path[2];
    return undefined;
  };
  window.addEventListener("offline", (event) => {
    const isOnline = navigator.onLine ? true : false;
    session._id && RemoveEventListnerOnUserNode(session._id);
    setOnlineStatus(isOnline);
  });

  window.addEventListener("online", (event) => {
    const isOnline = navigator.onLine ? true : false;
    setOnlineStatus(isOnline);
  });
  window.addEventListener("unload", (e) => {
    const socket = (window as any).gameSocket;
    socket.close();
  });
  EventEmitterSingleton().on("NO_NETWORK", () => {
    const isOnline = navigator.onLine ? true : false;
    setOnlineStatus(isOnline);
  });
  useEffect(() => {
    if (session._id) getRooms();
  }, [session._id]);

  useEffect(() => {
    history.listen(() => {
      const isOnline = navigator.onLine ? true : false;
      setOnlineStatus(isOnline);
    });
    let broadcast = BroadcastService().initInstance();
    broadcast.onmessage = broadcastChannelMessage;
    // let socket = BroadcastService().initInstance("socket");
    // socket.onmessage = broadcastSocket;
    // fetch rooms for firebase invite
  }, []);
  const getRooms = async () => {
    return await dispatch(
      MessagesActions.GetFriends({ userId: session._id, type: 0 })
    );
  };
  useEffect(() => {
    if (!(window as any).gameSocket && session._id === undefined) {
      setHasConnectedSocket(false);
    }
    if (
      windowLoaded &&
      session._id &&
      !hasConnectedSocket &&
      !(window as any).gameSocket
    ) {
      const socket = SocketIo(session).getInstance();
      socket.on("match_id", (data: { status: string; response: any }) => {
        if (data.status === "success") {
          // let state = store.getState();
          // let otherUser = state.conversation ? state.conversation.conversationOtherUser : {};
          // if (data.response.other_user._id === getIdFromParams() || data.response.other_user._id === otherUser._id || data.response.other_user._id)
          //if (data.response.other_user._id === getIdFromParams() || data.response.other_user._id)
          onMatchMakingSuccess(data.response);
          //else {
          //  BroadcastService().initInstance().postMessage({ ...data.response, socket: true });
          //}
        }
      });
      session && session._id && initMesibo();
      setHasConnectedSocket(true);
    }

    if (showGameBoard && !session._id) {
      setShowGameBoard(false);
      history.push("/login");
    }
  }, [session._id, windowLoaded]);

  useEffect(() => {
    if (initMesiboSuccess) {
      (async () => {
        let mesibo: any = await MesiboApi().getInstance();
        mesibo.setListener(new (MesiboListener as any)(onMessageCallBack));
      })();
    }
  }, [conversationOtherUser, initMesiboSuccess, showGameBoard]);

  useEffect(() => {
    showGameBoard && history.push("/");
  }, [showGameBoard]);
  useEffect(() => {
    session &&
      session._id &&
      isFirebaseInitilized &&
      addInviteListeners(session._id, receiveGameInviteCallBack);
  }, [isFirebaseInitilized, session._id]);
  useEffect(() => {
    let invites = [...inviteRecievedData];
    inviteDelete.map((invite: any) => {
      let index = invites.findIndex((inv: any) => inv._id === invite);
      if (index > -1) {
        invites.splice(index, 1);
        invite_list.splice(index, 1);
      }
    });
    setInviteRecievedData(invites);
  }, [inviteDelete]);
  useEffect(() => {
    if (fireBaseMessagingStatus) {
      firebaseMessageListerers();
    }
  }, [fireBaseMessagingStatus]);
  useEffect(() => {
    if (session.mesiboToken) initMesibo();
  }, [session.mesiboToken]);

  useEffect(() => {
    if (
      persistFirebaseConfiguration &&
      persistFirebaseConfiguration.country &&
      persistFirebaseConfiguration.country.country_code
    ) {
      (async () => {
        dispatch(
          AuthenticationActions.GetPaywallPackages(
            persistFirebaseConfiguration.country.country_code.toLowerCase()
          )
        );
      })();
    }
  }, [persistFirebaseConfiguration]);

  useEffect(() => {
    if (session && session._id && paymentBundles&&paymentBundles.length>0) {
      let activePaymentMethod = getUserActivePaymentMethod(session);
      if (
        activePaymentMethod &&
        !isServiceIdExists(paymentBundles, activePaymentMethod.serviceId) &&
        !isServiceByIdFetched
      ) {
        setIsServiceByIdFetched(true);
        dispatch(
          AuthenticationActions.GetServiceById(activePaymentMethod.serviceId)
        );
        console.log("Service Id Not exists");
      }
      console.log("Sesssionnnnnn", session, paymentBundles);
    }
  }, [paymentBundles, session._id]);

  useEffect(() => {
    console.log("looking in to it!")
    if (!userHasSkippedPaywall){
      setShowUserPaywall(showPaywall);
      console.log("In the if statement")
    } 

  }, [showPaywall]);
  const firebaseMessageListerers = () => {
    onMessageListener()
      .then((payload: any) => {})
      .catch((err: any) => console.log("failed: ", err));
  };
  const onMessageCallBack = async (message: any, data: any) => {
    const parsedMessage: MESIBO_CHAT_MESSAGE = JSON.parse(data);

    if (!showGameBoard && window.location.href.indexOf("messages/") < 0) {
      dispatch(MessagesActions.UpdateUnseenMessageInFotter(1));
    }
    if (conversationOtherUser._id === message.peer) {
      dispatch(ConversationActions.SetConversation([message]));
    }
    ////if (getIdFromParams() !== message.peer)
    BroadcastService().initInstance().postMessage(parsedMessage);
    dispatch(ConversationActions.inviteReceived(parsedMessage));
  };
  const broadcastChannelMessage = async (message: any) => {
    let path = "/messages";
    let id = getIdFromParams();
    //if (id)
    path = path + "/" + id;
    if (message.socket) {
      broadcastSocket(message);
    } else {
      if (window.location.pathname === path) {
        // message received for this user
        if (id === message.sender) {
          dispatch(
            ConversationActions.SetConversation([composeMesiboMsg(message)])
          );
        } else {
          //if mesibo not able sent message from offline tab then send from here
          if (message.sentViaMesibo === false) {
            sendBroadcastedMessage(message);
          }
          //message is sent from online tab ans send back to broadcast to append in conversation
          if (message.sentViaMesibo === true && id === message.receiver) {
            dispatch(
              ConversationActions.SetConversation([composeMesiboMsg(message)])
            );
          }
        }
      } else {
        //conversation list to change conversation color
        dispatch(ConversationActions.inviteReceived(message));
      }
    }
  };
  const composeMesiboMsg = (message: any) => {
    return {
      channel: 0,
      date: addDateInMessage(message),
      expiry: 2592000,
      flag: "MESIBO_FLAG_DEFAULT",
      group: null,
      groupid: 0,
      id: "1619602423",
      message: JSON.stringify(message),
      origin: 1,
      peer: getIdFromParams(),
      refid: 0,
      sender: message.sender,
      status: 0,
      ts: 1619602423273,
      type: 0,
      user: session,
    };
  };
  const addDateInMessage = (msg: any) => {
    let date;
    if (msg.timeStamp) date = new Date(msg.timeStamp);
    else date = new Date();
    return {
      date: "Today",
      hour: date.getHours(),
      min: date.getMinutes(),
      sec: date.getSeconds(),
      time: date.getHours() + ":" + date.getMinutes(),
    };
  };
  const sendBroadcastedMessage = async (message: any) => {
    let mesibo = await MesiboApi().getInstance();
    let sent = mesibo.sendMessage(
      {
        peer: message.receiver,
        flag: "MESIBO_FLAG_DEFAULT",
        sender: session._id,
      },
      Date.now().toString().substring(0, 10),
      JSON.stringify(message)
    );
    if (sent) {
      repostToSender(message);
    }
  };
  const repostToSender = (message: any) => {
    BroadcastService()
      .initInstance()
      .postMessage({ ...message, sentViaMesibo: true });
  };
  const broadcastSocket = (socketData: any) => {
    if (getIdFromParams() === socketData.other_user._id) {
      onMatchMakingSuccess(socketData);
    }
  };
  const initMesibo = async () => {
    if (session.mesiboToken) {
      let mesibo: any = await MesiboApi().getInstance();
      /* App ID used to create a user token. */
      const demo_app_id = "com.gago.multiplayergames";
      mesibo.setListener(new (MesiboListener as any)(onMessageCallBack));
      mesibo.setAppName(demo_app_id);
      mesibo.setCredentials(session.mesiboToken);
      mesibo.setDatabase("mesibo");
      const a = mesibo.start();
      setInitMesiboSuccess(true);
      // console.log("Mesibo Initilized with token-- ",session.mesiboToken)
    }
  };

  const onRequestMatchMaking = (
    gameId: number,
    shouldRequestMatchMaking: boolean,
    matchMakingData?: any
  ) => {
    setSelectedGameId(gameId);
    setShowGameBoard(true);
    dispatch(HomeActions.SetShowGameBoard(true));
    setRegisterMatchMaking(shouldRequestMatchMaking);
    //debugger;
    if (matchMakingData) setAcceptInviteMatchData(matchMakingData);
  };

  const onMatchMakingSuccess = (data: any) => {
    const gameId = data.match.gameId;
    if (selectedGameId < 0 && gameId >= 0 && !showGameBoard) {
      setRegisterMatchMaking(false);
      setSelectedGameId(gameId);
      setShowGameBoard(true);
      dispatch(HomeActions.SetShowGameBoard(true));
    }
    //debugger;
    setAcceptInviteMatchData(data);
  };

  const cancelMatchMaking = () => {
    if (selectedGameId >= 0)
      dispatch(
        HomeActions.CancelMatchMaking({
          userId: session._id,
          gameId: selectedGameId,
        })
      );
  };

  const onGameAccept = async (
    mesiboMessage: any,
    conversationMessage: any,
    accepted: boolean
  ) => {
    const acceptedGame = multiplayerGamesList.filter((game) => {
      return game.gameId === conversationMessage._gameId;
    });

    if (acceptedGame.length) {
      let playAgain: any = null;
      if (accepted) {
        updateInviteOnFirebase(
          session._id,
          mesiboMessage._id,
          conversationMessage._pid2,
          InviteActionsEnum.ACCEPTED,
          receiveGameInviteCallBack
        );
        playAgain = await dispatch(
          HomeActions.PlayAgain({
            pid1: session._id,
            pid2: conversationMessage._pid1,
            gameId: acceptedGame[0].gameId,
            acceptInvite: accepted ? true : false,
          })
        );
      } else {
        updateInviteOnFirebase(
          session._id,
          mesiboMessage._id,
          conversationMessage._pid2,
          InviteActionsEnum.REJECTED,
          receiveGameInviteCallBack
        );
        playAgain = await dispatch(
          HomeActions.CancelPlayAgain({
            pid1: session._id,
            pid2: conversationMessage._pid1,
            gameId: acceptedGame[0].gameId,
          })
        );
      }
      if (playAgain.success) {
        if (playAgain.success && accepted) {
          onRequestMatchMaking(
            acceptedGame[0].gameId,
            false,
            playAgain.response
          );
        }
        // let newInviteObj: GAME_INVITE = {
        //     _gameId: conversationMessage._gameId,
        //     _pid1: conversationMessage._pid1,
        //     _pid2: conversationMessage._pid2,
        //     created_at: conversationMessage.created_at,
        //     expire_at: conversationMessage.expire_at,
        //     inviteStatus: conversationMessage.inviteStatus,
        //     inviteActions: accepted ? InviteActionsEnum.ACCEPTED : InviteActionsEnum.REJECTED
        // }

        // let ts = Date.now();
        // let newMid = ts.toString().substring(0, 10);

        // const updatedMesiboMessage: MESIBO_CHAT_MESSAGE = {
        //     previousMid: mesiboMessage._id,
        //     _id: newMid,
        //     type: MessageType.INVITE,
        //     message: JSON.stringify(newInviteObj),
        //     // sender: JSON.parse(mesiboMessage.message).sender,
        //     sender: mesiboMessage.receiver,
        //     // receiver: JSON.parse(mesiboMessage.message).receiver,
        //     receiver: mesiboMessage.sender,
        //     messageStatus: MessageStatus.PENDING,
        //     // timeStamp: JSON.parse(mesiboMessage.message).timeStamp,
        //     timeStamp: mesiboMessage.timeStamp,
        //     // roomId: JSON.parse(mesiboMessage.message).roomId
        //     roomId: mesiboMessage.roomId,
        //     inviteAction: accepted ? InviteActionsEnum.ACCEPTED : InviteActionsEnum.REJECTED
        // }

        // let mesibo: any = await MesiboApi().getInstance();
        // const messageSent = mesibo.sendMessage({
        //     peer: conversationMessage._pid1,
        //     flag: 'MESIBO_FLAG_DEFAULT', sender: session._id
        // }, newMid, JSON.stringify(updatedMesiboMessage));
        // if (messageSent) {
        //     //BroadcastService().initInstance().postMessage({ closeInvitePopup: true });
        //     if (playAgain.success && accepted) {
        //         onRequestMatchMaking(acceptedGame[0].gameId, false, playAgain.response);
        //     }
        // }
        // else {
        //     let m = (updatedMesiboMessage as any);
        //     if (accepted)
        //         m.AcceptedOfflineGameInvite = true;
        //     else
        //         m.AcceptedOfflineGameInvite = false;

        //     m.gameId = acceptedGame[0].gameId;
        //     m.playAgainResponse = playAgain.response;
        //     BroadcastService().initInstance().postMessage({ ...m, closeInvitePopup: true });
        // }
      }
    } else {
      console.log("Invalid game");
    }
  };
  const acceptGameInviteAgain = (isLandScape: boolean) => {
    if (isLandScape) {
      let vpc = document.getElementById("view-port-container");
      if (vpc) {
        vpc.style.width = window.innerWidth.toString() + "px";
        vpc.style.maxWidth = window.innerWidth.toString() + "px";
      }
    }
    onGameAccept(inviteReceived, JSON.parse(inviteReceived.message), true);
  };
  const showInvitePopup = (parsedMessage: any) => {
    if (parsedMessage.type === MessageType.INVITE) {
      setInviteRecievedData([...inviteRecievedData, parsedMessage]);
      toggle();
    }
    // if (parsedMessage.type === MessageType.INVITE && !parsedMessage.inviteAction) {
    //     setInviteRecievedData(parsedMessage);
    //     toggle();
    // }
    // else {
    //     if (parsedMessage.inviteAction === "canceled" || parsedMessage.inviteAction === "rejected") {
    //         setInviteRecievedData(Object.create({}));
    //         setAcceptInviteMatchData({});
    //         if (isShowing)
    //             toggle();
    //     }
    //     if (parsedMessage.inviteAction === "accepted") {
    //         if (isShowing)
    //             toggle();
    //     }
    // }
  };
  const sendGameInvite = async (game: GAME_ITEM, other_user: USER_SESSION) => {
    const ts = Date.now();
    const messageId = ts.toString().substring(0, 10);
    dispatch(
      ConversationActions.SetConversationUser({ otherUser: other_user })
    );
    //invite_other_user = other_user;
    const playAgain: any = await dispatch(
      HomeActions.PlayAgain({
        pid1: session._id,
        pid2: other_user._id,
        gameId: game.gameId,
        acceptInvite: false,
      })
    );

    if (playAgain.response && playAgain.success) {
      const inviteMsg: GAME_INVITE = {
        _gameId: game.gameId,
        _pid1: session._id,
        _pid2: other_user._id,
        created_at: ts.toString(),
        expire_at: (ts + 30 * 1000).toString(),
        inviteStatus: InviteStatusEnums.PENDING,
        inviteActions: other_user.isBot
          ? InviteActionsEnum.ACCEPTED
          : InviteActionsEnum.DEFAULT,
        // inviteActions: InviteActionsEnum.DEFAULT
      };

      const mesiboMessage: MESIBO_CHAT_MESSAGE = {
        _id: messageId,
        type: MessageType.INVITE,
        message: JSON.stringify(inviteMsg),
        sender: session._id,
        receiver: other_user._id,
        messageStatus: MessageStatus.PENDING,
        timeStamp: ts,
        roomId: other_user._id,
        previousMid: "",
        ownerId: session._id,
      };
      let key = addInviteOnFirebase(
        session._id,
        other_user._id,
        {
          createdTime: Date.now(),
          gameId: game.gameId,
          roomId: playAgain.response.roomDetails.roomId,
          sentBy: session._id,
          status: "invited",
        },
        false
      );
      mesiboMessage._id = key;
      dispatch(
        ConversationActions.SetConversation([
          { message: JSON.stringify(mesiboMessage) },
        ])
      );
      // let mesibo: any = await MesiboApi().getInstance();
      // const messageSent = mesibo.sendMessage({ peer: other_user._id, flag: 'MESIBO_FLAG_DEFAULT', sender: session._id },
      //     messageId,
      //     JSON.stringify(mesiboMessage)
      // );
      // if (messageSent) {
      //     // readMesiboMessages(mesibo, true);
      //     // setMessageSent(true);

      //     // Analytics
      //     SendAnalyticsToFirebase(AnalyticsEventsName.ON_INVITE_SENT);
      // }
      // else {
      //     let m = (mesiboMessage as any);
      //     // m.AcceptedOfflineGameInvite = true;
      //     BroadcastService().initInstance().postMessage(m);
      // }
    } else {
      console.log("Couldn't send match making request at this time.");
    }
  };

  const receiveGameInviteCallBack = async (
    inviteObj: any,
    inviteItemKey: string,
    userId: string
  ) => {
    if (userId === session._id) {
      if (inviteObj) {
        let state = store.getState();
        if (
          inviteObj.status === InviteActionsEnum.INVITED &&
          inviteObj.sentBy !== session._id
        ) {
          let roomsList: any = state.messages;
          let roomDetails: any;
          let profile: any;
          if (roomsList.friendRecipients.values.length) {
            roomDetails = roomsList.friendRecipients.values.find(
              (room: any) => room._id === inviteObj.roomId
            );
            if (roomDetails) profile = roomDetails.profile;
            else profile = {};
          } else {
            roomsList = await getRooms();
            if (roomsList.success) roomsList = roomsList.response;
            else return;
            roomDetails = roomsList.find(
              (room: any) => room._id === inviteObj.roomId
            );
            if (roomDetails) profile = roomDetails.profile;
            else profile = {};
          }

          let customMessage = {
            _gameId: inviteObj.gameId,
            _pid1: profile._id,
            _pid2: inviteObj.sentBy,
            _pid2_name: profile.name,
            _pid2_profileImage: getProfileImage(profile),
            created_at: invite.createdTime + "",
            expire_at: (invite.createdTime + 20 * 1000).toString(),
            inviteStatus: InviteStatusEnums.PENDING,
            inviteActions: conversationOtherUser.isBot
              ? InviteActionsEnum.ACCEPTED
              : InviteActionsEnum.DEFAULT,
          };
          const mesiboMessage: MESIBO_CHAT_MESSAGE = {
            _id: inviteItemKey,
            type: MessageType.INVITE,
            message: JSON.stringify(customMessage),
            sender: inviteObj.sentBy,
            receiver: session._id,
            messageStatus: MessageStatus.PENDING,
            timeStamp: inviteObj.createdTime + 30000,
            roomId: inviteObj.roomId,
            previousMid: "",
          };
          dispatch(
            ConversationActions.SetConversation([
              composeMesiboMsg(mesiboMessage),
            ])
          );
          dispatch(ConversationActions.inviteReceived(mesiboMessage));
          if (
            !state.home.showGameBoard &&
            window.location.href.indexOf("messages/") < 0
          ) {
            invite_list.unshift(mesiboMessage);
            setInviteRecievedData([...invite_list]);
          }
        } else if (
          inviteObj.status === InviteActionsEnum.ACCEPTED ||
          inviteObj.status === InviteActionsEnum.REJECTED
        ) {
          let conversations: any = state.conversation;
          let message: any;
          if (
            conversations.chatMessages.values &&
            conversations.chatMessages.values.length
          ) {
            for (let i = 0; i < conversations.chatMessages.values.length; i++) {
              let chatMsg = JSON.parse(
                conversations.chatMessages.values[i].message
              );
              if (chatMsg._id === inviteItemKey) {
                message = chatMsg;
                break;
              }
            }
            message.previousMid = inviteItemKey;
            let inviteMessage = JSON.parse(message.message);
            inviteMessage.inviteActions = inviteObj.status;
            message.message = JSON.stringify(inviteMessage);
            dispatch(
              ConversationActions.SetConversation([composeMesiboMsg(message)])
            );
          }
        } else if (inviteObj.status === InviteActionsEnum.CANCELED) {
          setInviteDelete([...inviteDelete, inviteItemKey]);
          setPlayAgainStatus(!playAgainStatus);
          dispatch(ConversationActions.inviteReceived(null));

          let conversations: any = state.conversation;
          let message: any;
          if (
            conversations.chatMessages.values &&
            conversations.chatMessages.values.length
          ) {
            for (let i = 0; i < conversations.chatMessages.values.length; i++) {
              let chatMsg = JSON.parse(
                conversations.chatMessages.values[i].message
              );
              if (chatMsg._id === inviteItemKey) {
                message = chatMsg;
                break;
              }
            }
            if (message) {
              message.previousMid = inviteItemKey;
              let inviteMessage = JSON.parse(message.message);
              inviteMessage.inviteActions = inviteObj.status;
              message.message = JSON.stringify(inviteMessage);
              dispatch(
                ConversationActions.SetConversation([composeMesiboMsg(message)])
              );
            }
          }
        }
      } else {
        setInviteDelete([...inviteDelete, inviteItemKey]);
        setPlayAgainStatus(!playAgainStatus);
        dispatch(ConversationActions.inviteReceived(null));
      }
    }
  };
  return (
    <Wrapper id="view-port-container">
      {showGameBoard && hasConnectedSocket && (
        <MultiPlayerGameBoard
          gamesList={multiplayerGamesList}
          selectedGameId={selectedGameId}
          show={showGameBoard}
          user={session}
          registerMatchMaking={registerMatchMaking}
          acceptInviteMatchData={acceptInviteMatchData}
          setAcceptInviteMatchData={setAcceptInviteMatchData}
          exitGame={(shouldCancelMatchMaking: boolean, playAgain?: string) => {
            dispatch(
              PersistActions.UpdateRecentlyPlayedGame({
                gameId: selectedGameId,
              })
            );
            shouldCancelMatchMaking && cancelMatchMaking();
            setSelectedGameId(-1);
            setShowGameBoard(false);
            dispatch(HomeActions.SetShowGameBoard(false));
            acceptInviteMatchData.other_user &&
              dispatch(
                ConversationActions.SetConversationUser({
                  otherUser: acceptInviteMatchData.other_user,
                })
              );
            SendAnalyticsToFirebase(
              AnalyticsEventsName.ON_MP_GAME_ITEM_CROSSED
            );
            acceptInviteMatchData.other_user &&
              history.push(
                "/messages/" +
                  acceptInviteMatchData.other_user._id +
                  (playAgain ? playAgain : "")
              );
            setAcceptInviteMatchData({});
          }}
          acceptGameInviteAgain={acceptGameInviteAgain}
          showInvitePopup={showInvitePopup}
          sendGameInvite={sendGameInvite}
          playAgainText={playAgainStatus}
          selectedLanguage={selectedLanguage}
        />
      )}
      {!onlineStatus && <OnlineStatus />}

      {inviteRecievedData && inviteRecievedData.length ? (
        <div
          className="d-flex align-items-center hide-scroller position-absolute w-100"
          style={{ overflowX: "auto", height: "30%" }}
        >
          {inviteRecievedData.map((inviteData: any, index: number) => {
            return (
              <InviteRecievedModal
                key={index}
                // isShowing={isShowing}
                onOptionClick={(option: boolean) => {
                  if (option) setInviteRecievedData([]);
                  else setInviteDelete([...inviteDelete, inviteData._id]);
                  onGameAccept(
                    inviteData,
                    JSON.parse(inviteData.message),
                    option
                  );
                  toggle();
                }}
                user={session}
                multiplayerGamesList={multiplayerGamesList}
                inviteRecievedData={inviteData}
                onClose={() => {
                  toggle();
                }}
                index={index}
              />
            );
          })}
        </div>
      ) : null}

      {showLowBalanceModal && (
        <LowBalanceModal
          onYesClick={() => {
            setShowLowBalanceModal(false);
          }}
        />
      )}
      {showNoNameModal && (
        <SetNameModal
          user={session}
          showDummyName={showNoNameModal}
          setShowNoNameModal={setShowNoNameModal}
        />
      )}
      {showSubscriptionNotActive && (
        <SubscriptionNotActive
          onYesClick={() => {
            setShowSubscriptionNotActive(false);
          }}
        />
      )}

      {showUserPaywall && (
        <Paywall
          setShowPaywall={setShowUserPaywall}
          setShowSubscriptionSuccessfull={setShowSubscriptionSuccessfull}
        />
      )}
       {
              exclusiveFeature &&
             <PremiumFeatureModal
             setPremiumFeature={setPremiumFeature}
            setShowPaywall={setShowUserPaywall}
             title={t("EXCLUSIVE_FEATURE")}
             description={t("EXCLUSIVE_FEATURE_MESSAGE")}
             detail={t("EXCLUSIVE_FEATURE_MESSAGE_DETAIL")}
             firstButtonText={t("SUBSCRIBE_NOW")}
             secondButtonText={t("SKIP_FOR_NOW")}
                firstButtonCallback={() => {
                  dispatch(
                    ExclusiveFeaturesActions.SetExclusiveFeature(false)
                  )
                    // setPremiumFeature(false);

                }}
                secondButtonCallback={() => {
                  setShowUserPaywall(false);
                  dispatch(
                    ExclusiveFeaturesActions.SetExclusiveFeature(false)
                  )
                  // setPremiumFeature(false);

              }}
             width={"80%"}
             />
        
          }
      {showSubscriptionSuccessfull && (
        
        <GenericPopupModal
          showInfoIcon={false}
          showSingleButton={true}
          title={t("SUCCESSFULLY_SUBSCRIBED")}
          description={t("SUCCESSFULL_SUBSCRIPTION_MESSAGE")}
          firstButtonText={t("START_USING_GAGO")}
          firstButtonCallback={() => {
            setShowSubscriptionSuccessfull(false);
          }}
          firstButtonWidth={"auto"}
          width={"80%"}
        />
      )}

      <Router history={history}>
        <Suspense
          fallback={
            <RouteTransition>
              <LoadingGIF size="4x" color="white" />
            </RouteTransition>
          }
        >
          <Switch>
            <Route exact path="/">
              <Header />
              <div
                className="hide-y-scroller px-3 w-100 bg-main-image"
                style={{ overflowY: "scroll", height: "100%" }}
                onScroll={(e) => {
                  if (windowLoaded && InstallPWA === undefined) {
                    setShowServiceWorketPrompt(true);
                  }
                }}
                id="mainScroll"
              >
                {/* <HomeContainer
                                onRequestMatchMaking={onRequestMatchMaking}
                                setShowLowBalanceModal={setShowLowBalanceModal} /> */}
                <HomeContainerNewDesign
                  onRequestMatchMaking={onRequestMatchMaking}
                  setShowLowBalanceModal={setShowLowBalanceModal}
                  setShowNoNameModal={setShowNoNameModal}
                  setShowSubscriptionSuccessfull={
                    setShowSubscriptionSuccessfull
                  }
                  showSubscriptionSuccessfull={showSubscriptionSuccessfull}
                  setShowPaywall={setShowUserPaywall}
                  setPremiumFeature={setPremiumFeature}
                  // setExclusiveFeatureTitle={setExclusiveFeatureTitle}
                />
              </div>
              <FooterContainer
                selected="Home"
                user={persistState.session}
                setShowLowBalanceModal={setShowLowBalanceModal}
                setShowNoNameModal={setShowNoNameModal}
                userHasNoName={userHasNoName}
                setShowPaywall={setShowUserPaywall}
              />
            </Route>
            <Route exact path="/login">
              <div
                className={`hide-y-scroller px-3 w-100 ${loginBackground}`}
                style={{ overflowY: "scroll", height: "100%" }}
              >
                {/* <LoginContainer fcmToken={fireBaseMessagingToken} setShowSubscriptionSuccessfull={setShowSubscriptionSuccessfull} isFirebaseInitilized={isFirebaseInitilized} /> */}
                <MultiLogin
                  setLoginBackground={setLoginBackground}
                  loginWithMobile={loginWithMobile}
                  setLoginWithMobile={setLoginWithMobile}
                  fcmToken={fireBaseMessagingToken}
                  setShowSubscriptionSuccessfull={
                    setShowSubscriptionSuccessfull
                  }
                  isFirebaseInitilized={isFirebaseInitilized}

                />
              </div>
            </Route>
            <Route   path="/terms">
              <Terms  setLoginWithMobile={setLoginWithMobile}/>
            </Route>
            <Route exact path="/tp-unsub">
              <div
                className="hide-y-scroller px-3 w-100 bg-main-image"
                style={{ overflowY: "scroll", height: "100%" }}
              >
                <UnSubContainer />
              </div>
            </Route>
            <Route exact path="/messages">
              <div className="lazy-container-wraper">
                <Authenticated
                  session={persistState.session}
                  userGeoLocation={loginState.userGeoLocation}
                >
                  <MessagesContainer
                    user={persistState.session}
                    initMesiboSuccess={initMesiboSuccess}
                    setShowLowBalanceModal={setShowLowBalanceModal}
                    setShowNoNameModal={setShowNoNameModal}
                    userHasNoName={userHasNoName}
                    setShowTermsConditions={setShowTermsConditions}
                    setShowPaywall={setShowUserPaywall}
                    setPremiumFeature={setPremiumFeature}
                    // setExclusiveFeatureTitle={setExclusiveFeatureTitle}
                  />
                </Authenticated>
                <FooterContainer
                  selected="Messages"
                  user={persistState.session}
                  setShowLowBalanceModal={setShowLowBalanceModal}
                  setShowNoNameModal={setShowNoNameModal}
                  userHasNoName={userHasNoName}
                  setShowPaywall={setShowUserPaywall}
                />
              </div>
              {/* <Authenticated session={persistState.session}>
                            <MessagesContainer user={persistState.session}
                                setShowLowBalanceModal={setShowLowBalanceModal} />    
                        </Authenticated>
                        <FooterContainer 
                            selected="Messages" 
                            user={persistState.session} 
                            setShowLowBalanceModal={setShowLowBalanceModal} /> */}
            </Route>
            <Route exact path="/messages/:id/:playAgain?">
              <Authenticated
                session={persistState.session}
                userGeoLocation={loginState.userGeoLocation}
              >
                <ConversationContainer
                  user={persistState.session}
                  onRequestMatchMaking={onRequestMatchMaking}
                  initMesiboSuccess={initMesiboSuccess}
                  setShowLowBalanceModal={setShowLowBalanceModal}
                  showInvitePopup={showInvitePopup}
                  setShowNoNameModal={setShowNoNameModal}
                  userHasNoName={userHasNoName}
                  setShowTermsConditions={setShowTermsConditions}
                  setShowPaywall={setShowUserPaywall}
                  setPremiumFeature={setPremiumFeature}
                  // setExclusiveFeatureTitle={setExclusiveFeatureTitle}
                />
              </Authenticated>
            </Route>
            <Route exact path="/profile/:id?">
              <div className="lazy-container-wraper">
                <Authenticated
                  session={persistState.session}
                  userGeoLocation={loginState.userGeoLocation}
                >
                  <ProfileContainer setLoginWithMobile={setLoginWithMobile} setShowPaywall={setShowUserPaywall} setLoginBackground={setLoginBackground} />
                </Authenticated>
                <FooterContainer
                  selected="Profile"
                  user={persistState.session}
                  setShowLowBalanceModal={setShowLowBalanceModal}
                  setShowNoNameModal={setShowNoNameModal}
                  userHasNoName={userHasNoName}
                  setShowPaywall={setShowUserPaywall}
                />
              </div>
            </Route>
            <Route exact path="/settings">
              <Authenticated
                session={persistState.session}
                userGeoLocation={loginState.userGeoLocation}
              >
                <SettingsContainer language={selectedLanguage} />
              </Authenticated>
            </Route>
            <Route exact path="/invite-friends">
              <Authenticated
                session={persistState.session}
                userGeoLocation={loginState.userGeoLocation}
              >
                <InviteContainer user={persistState.session} />
              </Authenticated>
            </Route>
            <Route exact path="/feedback">
              <Authenticated
                session={persistState.session}
                userGeoLocation={loginState.userGeoLocation}
              >
                <FeedbackContainer />
              </Authenticated>
            </Route>
            <Route exact path="/blocked-users">
              <Authenticated
                session={persistState.session}
                userGeoLocation={loginState.userGeoLocation}
              >
                <BlockedUsersContainer />
              </Authenticated>
            </Route>
            <Route exact path="/about-us">
              <Authenticated
                session={persistState.session}
                userGeoLocation={loginState.userGeoLocation}
              >
                <AboutUsContainer aboutUs={persistFirebaseConfiguration?.about_us} />
              </Authenticated>
            </Route>
            <Route exact path="/edit-profile">
              <Authenticated
                session={persistState.session}
                userGeoLocation={loginState.userGeoLocation}
              >
                <EditProfileContainer />
              </Authenticated>
            </Route>
            <Route exact path="/play-history">
              <Authenticated
                session={persistState.session}
                userGeoLocation={loginState.userGeoLocation}
              >
                <PlayHistoryContainer />
              </Authenticated>
            </Route>
            <Route exact path="/search-games">
              <SearchGames
                onRequestMatchMaking={onRequestMatchMaking}
                setShowLowBalanceModal={setShowLowBalanceModal}
                setShowNoNameModal={setShowNoNameModal}
                userHasNoName={userHasNoName}
                setShowPaywall={setShowUserPaywall}
              />
            </Route>
            <Route exact path="/discover-people">
              <Authenticated
                session={persistState.session}
                userGeoLocation={loginState.userGeoLocation}
              >
                <DiscoverPeople
                  user={persistState.session}
                  selectedLanguage={selectedLanguage}
                />
              </Authenticated>
            </Route>
            <Route exact path="/leaderboard">
              <Authenticated
                session={persistState.session}
                userGeoLocation={loginState.userGeoLocation}
              >
                <LeaderBoardContainer />
              </Authenticated>
              <FooterContainer
                selected="LeaderBoard"
                user={persistState.session}
                setShowLowBalanceModal={setShowLowBalanceModal}
                setShowNoNameModal={setShowNoNameModal}
                userHasNoName={userHasNoName}
                setShowPaywall={setShowUserPaywall}
              />
            </Route>
            <Route exact path="/pending-requests">
              <Authenticated
                session={persistState.session}
                userGeoLocation={loginState.userGeoLocation}
              >
                <PendingRequestContainer
                  setShowLowBalanceModal={setShowLowBalanceModal}
                  setShowNoNameModal={setShowNoNameModal}
                  user={persistState.session}
                  userHasNoName={userHasNoName}
                  setShowPaywall={setShowUserPaywall}
                />
              </Authenticated>
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 600px;
  width: 100%;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .fade-enter {
    opacity: 0.01;
  }
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
  }
  .fade-exit {
    opacity: 0;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 200ms ease-in;
  }
`;
export default withRouter(Container);
