import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import { AnimatePopup, ParagraphText } from "@Styled";
import { ISinglePlayerGameBoard } from "./ISinglePlayerGameBoard";
import { WarningModal } from "@Components/Modals/WarningModal";

export const SinglePlayerGameBoard: React.FunctionComponent<ISinglePlayerGameBoard.IProps> =
  ({ show, selectedGame, exitGame }) => {
    const [showCancelWarning, setShowCancelWarning] = useState(false);
    const isMobileDevice =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
      useEffect(()=>{
        if(document.getElementById('smartbanner')){
            (window as any).bannerHide();
        }
    },[])
    useEffect(() => {
      if (selectedGame.orientation === "landscape") {
        let vpc = document.getElementById("view-port-container");
        if (vpc) {
          vpc.style.width = window.innerWidth.toString() + "px";
          vpc.style.maxWidth = window.innerWidth.toString() + "px";
        }
      }
      return () => {
        if (selectedGame.orientation === "landscape") {
          let vpc = document.getElementById("view-port-container");
          if (vpc) {
            vpc.style.width = "600px";
            vpc.style.maxWidth = "600px";
          }
        }
      };
    }, []);

    return show
      ? ReactDOM.createPortal(
          <AnimatePopup animateIn={true}>
            {showCancelWarning && (
              <WarningModal
                onYesClick={() => {
                  exitGame();
                }}
                onNoClick={() => {
                  setShowCancelWarning(false);
                }}
                warningTextElem={
                  <ParagraphText className="text-black text-center font-16px">
                    Are you sure you want to exit ? All your progress will be
                    lost
                  </ParagraphText>
                }
                width={
                  selectedGame.orientation === "landscape" && !isMobileDevice
                    ? "40%"
                    : ""
                }
              />
            )}

            <div
              className="position-fixed"
              style={{ top: "5px", left: "5px" }}
              onClick={() => {
                setShowCancelWarning(true);
              }}
            >
              <div
                className="d-flex align-items-center justify-content-center"
                style={{
                  background: "rgb(0,0,0,0.7)",
                  borderRadius: "50%",
                  width: "45px",
                  height: "45px",
                }}
              >
                <img
                  className="mr-1"
                  height="24px"
                  width="12px"
                  src="/images/back.png"
                />
              </div>
            </div>
            <iframe
              src={selectedGame.url}
              allow=""
              height="100%"
              width="100%"
              loading="eager"
              name={selectedGame.title}
              style={{ border: "none" }}
            ></iframe>
          </AnimatePopup>,
          document.getElementById("view-port-container")!
        )
      : null;
  };
