import React from "react";
const SvgRadialMenu: React.FC<{
  data: any;
}> = (data) => {
  const data0 = data.data;
  var subMenuItems: any = "";
  if (data0.menuItems[1].active)
    subMenuItems = (
      <>
        <path
          style={{ cursor: "pointer" }}
          onClick={() => data0.menuItems[1].subItems[3].action()}
          d="M 198 256 L 200 305 A 148 148 0 0 1 128 292 L 148.5 246 A 128 128 1 0 0 198 256 "
          fill="white"
          strokeWidth="1"
          stroke="#E6E6E6"
        />
        <path
          style={{ cursor: "pointer" }}
          onClick={data0.menuItems[1].subItems[2].action}
          d="M 148.5 246 L 128 292 A 148 148 0 0 1 71 253 L 107 218 A 128 128 1 0 0 148.5 246  "
          fill="white"
          strokeWidth="1"
          stroke="#E6E6E6"
        />
        <path
          style={{ cursor: "pointer" }}
          onClick={data0.menuItems[1].subItems[1].action}
          d="M 107 218 L 71 253 A 148 148 0 0 1 33 198 L 80 178 A 128 128 1 0 0 107 218  "
          fill="white"
          strokeWidth="1"
          stroke="#E6E6E6"
        />
        <path
          style={{ cursor: "pointer" }}
          onClick={data0.menuItems[1].subItems[0].action}
          d="M 80 178 L 33 198 A 148 148 0 0 1 18 128 L 70 128 A 128 128 1 0 0 80 178  "
          fill="white"
          strokeWidth="1"
          stroke="#E6E6E6"
        />
        <image
          onClick={() => data0.menuItems[1].subItems[3].action()}
          id="messageImage"
          style={{ cursor: "pointer" }}
          href={data0.menuItems[1].subItems[3].imageUrl}
          width="20px"
          height="20px"
          x={157}
          y={264}
        />
        <image
          onClick={data0.menuItems[1].subItems[2].action}
          id="laughImage"
          style={{ cursor: "pointer" }}
          href={data0.menuItems[1].subItems[2].imageUrl}
          width="20px"
          height="20px"
          x={102}
          y={244}
        />
        <image
          onClick={data0.menuItems[1].subItems[1].action}
          id="loveImage"
          style={{ cursor: "pointer" }}
          href={data0.menuItems[1].subItems[1].imageUrl}
          width="20px"
          height="20px"
          x={61}
          y={203}
        />
        <image
          onClick={data0.menuItems[1].subItems[0].action}
          id="shockedImage"
          style={{ cursor: "pointer" }}
          href={data0.menuItems[1].subItems[0].imageUrl}
          width="20px"
          height="20px"
          x={40}
          y={148}
        />
      </>
    );
  if (data0.menuItems[0].active) {
    subMenuItems = (
      <>
        <path
          style={{ cursor: "pointer" }}
          onClick={data0.menuItems[0].subItems[2].action}
          d="M 198 256 L 198 307 A 200 200 0 0 1 98 277 L 128.5 236 A 128 128 1 0 0 198 256 "
          fill="white"
          strokeWidth="1"
          stroke="#E6E6E6"
        />
        <path
          style={{ cursor: "pointer" }}
          onClick={data0.menuItems[0].subItems[1].action}
          d="M 128.5 236 L 98 277 A 200 200 0 0 1 43 219 L 85 190 A 128 128 1 0 0 128.5 236  "
          fill="white"
          strokeWidth="1"
          stroke="#E6E6E6"
        />
        <path
          style={{ cursor: "pointer" }}
          onClick={data0.menuItems[0].subItems[0].action}
          d="M 85 190 L 43 219 A 200 200 0 0 1 18 128 L 70 128 A 128 128 1 0 0 85 190  "
          fill="white"
          strokeWidth="1"
          stroke="#E6E6E6"
        />
        <image
          onClick={data0.menuItems[0].subItems[2].action}
          id="callImage"
          style={{ cursor: "pointer" }}
          href={data0.menuItems[0].subItems[2].imageUrl}
          width={data0.menuItems[0].subItems[2].imageWidth}
          height={data0.menuItems[0].subItems[2].imageHeight}
          x={data0.menuItems[0].subItems[2].x}
          y={data0.menuItems[0].subItems[2].y}
        />
        <image
          onClick={data0.menuItems[0].subItems[1].action}
          id="musicImage"
          style={{ cursor: "pointer" }}
          href={data0.menuItems[0].subItems[1].imageUrl}
          width="20px"
          height="20px"
          x={75}
          y={225}
        />
        <image
          onClick={data0.menuItems[0].subItems[0].action}
          id="micImage"
          style={{ cursor: "pointer" }}
          href={data0.menuItems[0].subItems[0].imageUrl}
          width="20px"
          height="20px"
          x={40}
          y={157}
        />
      </>
    );
  }
  return (
    <div style={{...data0.MenuContainerStyling}}>
      <svg   viewBox="17 127 180 180" style={{ width: "100%", height: "100%"}}>
        <path
          id="menuHave4items"
          style={{ cursor: "pointer" }}
          onClick={data0.menuItems[1].action}
          d="M 197 128  L 107 218  A 128 128 0 0 1 70 128 L 70 128 L197 128"
          fill={data0.menuItems[1].fillColor}
          strokeWidth="1"
          stroke="#E6E6E6"
        />
        <path
          id="menuHave3items"
          style={{ cursor: "pointer" }}
          onClick={data0.menuItems[0].action}
          d="M 197 128 L 198 256 A 128 128 0 0 1 107 218 L 107 218 L 197 128"
          fill={data0.menuItems[0].fillColor}
          strokeWidth="1"
          stroke="#E6E6E6"
        />

        <image
          id="menuImgHave3items"
          style={{ cursor: "pointer" }}
          href={data0.menuItems[0].imageUrl}
          width="30px"
          height="30px"
          x={152}
          y={185}
          onClick={data0.menuItems[0].action}
        />
        <image
          id="menuImgHave4items"
          style={{ cursor: "pointer" }}
          href={data0.menuItems[1].imageUrl}
          width="30px"
          height="30px"
          x={110}
          y={143}
          onClick={data0.menuItems[1].action}
        />
        {subMenuItems}
      </svg>
    </div>
  );
};

export default SvgRadialMenu;
