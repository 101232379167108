// #region Local Imports
import { Http } from "@Services/API/Http";
// #endregion Local Imports

// #region Interface Imports
import { MessagesModel } from "@Interfaces";
import { userErrorDummyResponse } from "@Constants";
// #endregion Interface Imports

const apiBaseUrl = process.env.REACT_APP_IS_PRODUCTION_API === "true" ? process.env.REACT_APP_API_URL : process.env.REACT_APP_STAGING_API_URL;

export const MessagesService = {
    GetFriends: async (
        payload: MessagesModel.GetGETMessagesListPayload
    ): Promise<MessagesModel.GetGETMessagesListResponse> => {
        let response: MessagesModel.GetGETMessagesListResponse;

        const { userId, ...rest } = payload;
        try {
            response = await Http.Request<MessagesModel.GetGETMessagesListResponse>(
                "GET",
                apiBaseUrl + "/user/rooms/" + userId,
                undefined
            );
        } catch (error) {
            response = {
                message: "",
                success: false,
                response: [{
                    _id: "5f8976d9f3456267bfc2c221",
                    profile: userErrorDummyResponse,
                    users: ""
                }]
            }
        }
        return response;
    },
    BlockUser: async (
        payload: MessagesModel.GetPATCHBlockUserPayload
    ): Promise<MessagesModel.GetPATCHBlockUserResponse> => {
        let response: MessagesModel.GetPATCHBlockUserResponse;

        const { userId1, userId2 } = payload;
        try {
            response = await Http.Request<MessagesModel.GetPATCHBlockUserResponse>(
                "PATCH",
                apiBaseUrl + "/user/" + userId1 + "/block/" + userId2,
                undefined
            );
        } catch (error) {
            response = {
                message: '',
                success: false,
                response: {}
            }
        }
        return response;
    },
    ReportRoom: async (
        payload: MessagesModel.GetPOSTReportRoomPayload
    ): Promise<MessagesModel.GetPOSTReportRoomResponse> => {
        let response: MessagesModel.GetPOSTReportRoomResponse;

        try {
            response = await Http.Request<MessagesModel.GetPOSTReportRoomResponse>(
                "POST",
                apiBaseUrl + "/report/room",
                undefined,
                payload as any
            );
        } catch (error) {
            response = {
                response: {
                    createdAt: "",
                    reportedBy: "",
                    roomId: "",
                    selectedOptions: [],
                    updatedAt: "",
                    __v: 0,
                    _id: ""
                },
                success: false,
                message: ''
            }
        }
        return response;
    },
    UnfriendUser: async (
        payload: any
    ): Promise<any> => {
        let response: any;

        try {
            response = await Http.Request<any>(
                "GET",
                apiBaseUrl + "/UnFriend/" + payload.userId + "/" + payload.otherUserId,
                undefined
            );
        } catch (error) {
            response = {
                response: {
                },
                success: false,
                message: ''
            }
        }
        return response;
    }
}