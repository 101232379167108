// #region Global Imports
import React from "react";
import styled from "styled-components";
// #endregion Global Imports

// #region Local Imports
// import { IMessageEditorInput } from "./MessageEditorInput";
// #endregion Local Imports

const _ModalContainer = styled.div<{width?:string,backgroundColor?: string}>`
    width: ${({ width }) => { return width ? width : "100%" }};
    border-radius: 28px;
    background: ${({ backgroundColor }) => { return backgroundColor ? backgroundColor : "white" }};
    padding: 20px 25px;
    margin: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const HeaderCircle = styled.div<{top?: string, width?: string, height?: string,backgroundColor?: string}>`
    position: absolute;
    border-radius: 50%;
    padding: 5px;
    background: ${({ backgroundColor }) => { return backgroundColor ? backgroundColor : "white" }};
    top: ${({ top }) => { return top ? top : "-36px" }};
    width: ${({ width }) => { return width ? width : "75px" }};
    height: ${({ height }) => { return height ? height : "75px" }};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ModalContainer: React.FunctionComponent<{headerChild?: any, headerProps?: any,width?:string,backgroundColor?:string}> = ({children, headerChild, headerProps,width,backgroundColor}) => {
    return <_ModalContainer width={width} backgroundColor={backgroundColor}>
        {headerChild && <HeaderCircle {...headerProps}>
            { headerChild }
        </HeaderCircle>}
        <br/>
        { children }
    </_ModalContainer>;
};
